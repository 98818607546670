import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal, IconButton, Grid } from "@mui/material";
import { useMemo, useState, useCallback, useEffect } from "react";
import FormSummerySidebar from "./FormSummerySidebar";
import {
  getAllRecipient,
  sendEmail,
} from "../../../lib/service/PaymentDetailApi/PaymentDetailApi";
import StepperUI from "../../Common/StepperUI/StepperUI";
import SendMoneyPaymentDetails from "../../Forms/SendMoneyPaymentDetailsForm";
import AddAndEditManualRecipientModal from "../AddAndEditManualRecipientModal";
import CryptoToFiat from "./CryptoToFiatFlow/CryptoToFiat";
import endlMainLogo from "../../../Images/brandings/endlMainLogo.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  maxHeight: "100vh"
};


const logoStyle = {
  position: 'absolute',
  top: '20px',
  left: '20px',
  width: '100px',
  height: 'auto',
};

const SendMoneyModal = ({ open, onClose, addNewTransaction }) => {
  const [step, setStep] = useState(0);
  const steps = useMemo(
    () => [
      "Payment details",
      "Payment method",
      "Transfer amount",
      "Review & pay",
    ],
    []
  );

  const [selectedPaymentType, setSelectedSelectedPaymentType] =
    useState("cryptoToFiat");
  const [selectedRecipient, setSelectedRecipient] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [addRecipientModal, setAddRecipientModal] = useState(false);
  const [isRecipientLoading, setIsRecipientLoading ] = useState(false);
  const [errorTxn, setErrorTxn] = useState(false);
  const [successTxn, setSuccessTxn] = useState(false);
  const handleEdit = useCallback(() => {
    setIsEditing(true);
    setAddRecipientModal(true);
  }, []);

  // For now editing is removed for the UX we will discuss
  // const handleInputChange = useCallback((event) => {
  //   setAccountDetail(event.target.value);
  // }, []);

  // const handleInputBlur = useCallback(() => {
  //   setIsEditing(false);
  // }, []);

  const handlePaymentTypeOptionChange = useCallback((event) => {
    setSelectedSelectedPaymentType(event.target.value);
  }, []);

  const handleRecipientOptionChange = useCallback(
    (event) => {
      const recipientId = event.target.value;
      const recipient = recipients.find(
        (recipient) => recipient.id === recipientId
      );
      if (recipient) {
        setSelectedRecipient(recipient);
      } else {
        setSelectedRecipient(undefined);
      }
    },
    [recipients]
  );

  const handleNext = useCallback(() => {
    // if (step !== 4) {
      setStep((prev) => prev + 1);
    // }
  }, [step]);

  const handleGoBack = useCallback(() => {
    if (step !== 0) {
      setStep((prev) => prev - 1);
    }
  }, [step]);

  const fetchAllRecipient = useCallback(async () => {
    setIsRecipientLoading(true);
    try {
      const response = await getAllRecipient({
        page: 0,
        size: 15,
        sortBy: "recipientName,asc",
        recipientType: ["BUSINESS","INDIVIDUAL"],
        receiveMode: ["BANK_ACCOUNT"],
      });

      if (response.data.recipients) {
        const processedRecipients = response.data.recipients.map(
          (recipient) => {
            const { firstName, lastName, ...rest } = recipient;
            return {
              id: recipient.id,
              name: lastName ? `${firstName} ${lastName}` : firstName,
              ...rest,
            };
          }
        );
        setRecipients(processedRecipients);
        setIsRecipientLoading(false)
      }
    } catch (error) {
      setIsRecipientLoading(false)
      console.error("Error fetching recipients:", error);
    }finally{
      setIsRecipientLoading(false)
    }
  }, []);
  useEffect(() => {
    if (!selectedPaymentType) return;
    fetchAllRecipient();
  }, [selectedPaymentType]);

  const addRecipientModalHandler = () => {
    setAddRecipientModal(true);
  };
  const addNewRecipient = (recipient) => {
    const { firstName, lastName, ...rest } = recipient;
    const processedRecipient = {
      id: recipient.id,
      name: lastName ? `${firstName} ${lastName}` : firstName,
      ...rest,
    };
    setRecipients((prev) => [processedRecipient, ...prev]);
    fetchAllRecipient();
  };

  const handleUpdate = (updatedRecipient) => {
    setSelectedRecipient(updatedRecipient);
    fetchAllRecipient();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style }}>
        {addRecipientModal && (
          <AddAndEditManualRecipientModal
            open={addRecipientModal}
            onClose={() => setAddRecipientModal(false)}
            addNewRecipient={addNewRecipient}
            provideReceiveMode={
              selectedPaymentType === "cryptoToFiat" ? "BANK_ACCOUNT" : ""
            }
            mode={isEditing ? "edit" : "create"}
            editRecipientData={selectedRecipient}
            onUpdateRecipient={handleUpdate}
          />
        )}
        <Box
          padding={2}
          borderBottom={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          borderColor={"rgba(0,0,0,0.3)"}
          position={"relative"}
          width="100%"
        >

          <img src={endlMainLogo} alt="Logo" className="endlLogo" style={logoStyle}/>

          <Box sx={{ width: "40%" }}>
            <StepperUI step={step} steps={steps} successTxn={successTxn} errorTxn={errorTxn}/>
          </Box>

          <IconButton
            onClick={onClose}
            aria-label="close-open-account-modal"
            sx={{ position: "absolute", right: "12px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {step === 0 && (
          <Grid container py={5}>
            <Grid item xs={12} sm={3} md={4}>
              <FormSummerySidebar title={"Payment details"} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SendMoneyPaymentDetails
                selectedPaymentType={selectedPaymentType}
                isEditing={isEditing}
                recipients={recipients}
                handleEdit={handleEdit}
                // handleInputChange={handleInputChange}
                // handleInputBlur={handleInputBlur}
                handlePaymentTypeOptionChange={handlePaymentTypeOptionChange}
                handleRecipientOptionChange={handleRecipientOptionChange}
                handleNext={handleNext}
                addRecipientHandler={addRecipientModalHandler}
                selectedRecipient={selectedRecipient}
                isRecipientLoading={isRecipientLoading}
              />
            </Grid>
          </Grid>
        )}
        {step > 0 && selectedPaymentType === "cryptoToFiat" && (
          <CryptoToFiat
            step={step}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            selectedPaymentType={selectedPaymentType}
            selectedRecipient={selectedRecipient}
            addNewTransaction={addNewTransaction}
            onClose={onClose}
            errorTxn={errorTxn}
            successTxn={successTxn}
            setErrorTxn={setErrorTxn}
            setSuccessTxn={setSuccessTxn}
          />
        )}
        {/* Similarly we can add other transaction flow - helps to keep the logic for each flow separated and in future anything changes in any flow we can easily change in frontend */}
      </Box>
    </Modal>
  );
};

export default SendMoneyModal;
