import sever_ENV from "../../../env"
import directService from "../AppService"

// const userLogin = (data) => {
//     return directService({
//         url: `/api/v1/user/login`,
//         method: 'POST',
//         data: data,
//       }, sever_ENV[process.env.REACT_APP_ENVIRONMENT].OAUTH_PORT)
// }

const login = (data) => {
  return directService({
    url: `/api/v1/user/login`,
    method: 'POST',
    data: data,
  })
}

const register = (data) => {
  return directService({
    url: `/api/v1/user/register`,
    method: 'POST',
    data: data,
  })
}

const forgetPasswordOTPEmail = (data) => {
  return directService({
    url: `/api/v1/otp/send`,
    method: "POST",
    data: data
  })
}

const resetPassword = (data) => {
  return directService({
    url: `/api/v1/user/reset-password`,
    method: "PUT",
    data: data
  })
}

const saveCompanyInfo = (data) => {

  return directService({
    url: `/api/v1/user/save-details`,
    method: "POST",
    data: data
  })
}

export { login, register, resetPassword, forgetPasswordOTPEmail, saveCompanyInfo };