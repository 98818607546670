import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Button,
  Grid,
  Input,
  IconButton
} from "@mui/material";
import { KeyboardArrowDown, Add, Delete } from "@mui/icons-material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddIcon from "@mui/icons-material/Add";
import LoadingDetails from "../LoaderUI/TransferAmountLoader";
import { notify } from "../../components/Notification/Notification";
import { invoiceUpload } from "../../lib/service/DashboardApi/dashboardApi";
const SendMoneyTransferAmountForm = ({
  sourceCurrency,
  destinationCurrencies,
  receiveCurrency,
  exchangeRate,
  receiveAmount,
  sendAmount,
  chargesFee,
  chargeTotal,
  handleReceiveCurrencyChange,
  handleSendAmountChange,
  isFetching,
  total,
  handleGoBack,
  handleNext,
  showReason,
  handleSelectChange,
  transactionPurpose,
  setinvoiceFileIdSave,
  showAmountError,
  setSelectedFile,
  selectedFile
}) => {
  const fileInputRef = useRef(null);

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };

  const images = importAll(
    require.context("../../Images/flags", false, /\.(png)$/)
  );

  const handleSendAmountInputChange = (event) => {
    const amount = event.target.value;
    const numericRegex = /^[0-9]*$/;
    if (!numericRegex.test(amount)) {
      return;
    }
    handleSendAmountChange(amount);
  };
  

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file.type === "application/pdf") {
      const response = await invoiceUpload(file);
      setSelectedFile(file);
      setinvoiceFileIdSave(response.data.invoiceFileId)
    } else {
      notify.error({
        message: "Only PDF file accepted",
        description: "",
      })
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };
console.log(showAmountError, !sendAmount, isFetching ,!Object.keys(showReason).length, 'trrrr')
  return (
    <Box display={"flex"} flexDirection={"column"} gap={5}>
      <TableContainer
        sx={{
          overflowX: "auto",
        }}
      >
        <Table
          sx={{
            border: "1px solid rgba(0,0,0,0.3)",
            borderRadius: "10px",
            minWidth: "800px",
          }}
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "rgba(0,0,0,0.20)" }}>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                <Typography
                  variant="h6"
                  fontSize="12px"
                  fontFamily="PPMori-Semi-Bold"
                >
                  You send
                </Typography>
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                <Typography
                  variant="h6"
                  fontSize="12px"
                  fontFamily="PPMori-Semi-Bold"
                >
                  Recipient gets
                </Typography>
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                <Typography
                  variant="h6"
                  fontFamily="PPMori-Semi-Bold"
                  fontSize="12px"
                >
                  Transfer Purpose
                </Typography>
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                <Typography
                  variant="h6"
                  fontFamily="PPMori-Semi-Bold"
                  fontSize="12px"
                >
                  Fees
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{ whiteSpace: "nowrap", flex: 1, padding: "10px" }}
              >
                <Box display="flex" flexDirection="column" minHeight="75px">
                  <OutlinedInput
                    sx={{
                      width: "100%",
                      height: "45px",
                      "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px 14px",
                          borderRight: "1px solid rgba(0,0,0,0.20)",
                        },
                    }}
                    onChange={handleSendAmountInputChange}
                    inputProps={{
                      style: {
                        py: 1,
                      },
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <img
                          src={images[`${sourceCurrency.toLowerCase()}.png`]}
                          alt={sourceCurrency.toLowerCase()}
                          style={{
                            width: "15px",
                            fontFamily: "PPMori-Regular",
                          }}
                        />
                        <Typography
                          variant="body6"
                          fontSize={15}
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {sourceCurrency}
                        </Typography>
                      </InputAdornment>
                    }
                    value={sendAmount}
                  />
                  {showAmountError && (
                    <Typography
                      variant="caption"
                      color="error"
                      display="block"
                      mt={1}
                      sx={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {showAmountError}
                    </Typography>
                  )}
                </Box>
              </TableCell>
              <TableCell
                sx={{ whiteSpace: "nowrap", flex: 1, padding: "10px" }}
              >
                <Box display="flex" flexDirection="column" minHeight="75px">
                  <OutlinedInput
                    value={receiveAmount}
                    sx={{
                      width: "100%",
                      height: "45px",
                      "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px 14px",
                          borderRight: "1px solid rgba(0,0,0,0.20)",
                        },
                    }}
                    inputProps={{
                      style: {
                        py: 1,
                      },
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <img
                          src={images[`${"india".toLowerCase()}.png`]}
                          alt={receiveCurrency.toLowerCase()}
                          style={{
                            width: "15px",
                            fontFamily: "PPMori-Regular",
                          }}
                        />
                        <Typography
                          variant="body6"
                          fontSize={15}
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {receiveCurrency}
                        </Typography>
                      </InputAdornment>
                    }
                  />
                </Box>
              </TableCell>

              {/* Reason Select */}
              <TableCell
                sx={{ whiteSpace: "nowrap", flex: 1, padding: "10px" }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  minHeight="75px"
                  minWidth="230px"
                  maxWidth="230px"
                >
                  <Select
                    labelId="reason-select-label"
                    id="reason-select"
                    value={showReason}
                    onChange={handleSelectChange}
                    sx={{
                      width: "100%",
                      height: "45px",
                      fontSize: "12px",
                      fontFamily: "PPMori-Regular",
                      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px 14px",
                        },
                    }}
                    IconComponent={KeyboardArrowDown}
                  >
                    {transactionPurpose.codes.map((item) => (
                      <MenuItem
                        value={item.code}
                        key={item.id}
                        name={item.category}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.code} - {item.category}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "10px",
                              color: "gray",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  flex: 0,
                  width: "auto",
                  padding: "10px",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  minHeight="75px"
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "PPMori-Semi-Bold",
                      whiteSpace: "nowrap",
                      height: "45px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {chargeTotal} {"USD"}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5}>
                <Button
                  sx={{
                    border: "1px dotted rgba(0,0,0,0.30)",
                    width: "200px",
                    color: "rgba(0,0,0,0.70)",
                    textTransform: "none",
                  }}
                  startIcon={<FileUploadOutlinedIcon />}
                  onClick={handleButtonClick}
                >
                  Upload file
                </Button>
                <Input
                  type="file"
                  onChange={handleFileUpload}
                  sx={{ display: "none" }}
                  inputRef={fileInputRef}
                />
                {selectedFile && (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography
                      variant="body2"
                      fontSize="12px"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {selectedFile.name}
                    </Typography>
                    <IconButton onClick={handleFileRemove}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box display={"flex"}>
        <Box width={"100%"}></Box>
        <Grid container spacing={2}>
          {isFetching ? (
            <Grid item xs={12}>
              <LoadingDetails />
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                {chargesFee.length && sendAmount?.length > 2
                  ? chargesFee.map((charge, index) => (
                      <>
                        {index === 0 && (
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "14px",
                              fontFamily: "PPMori-Regular",
                            }}
                          >
                            Exchange rate
                          </Typography>
                        )}
                        <Typography
                          variant="body1"
                          fontSize="12px"
                          lineHeight={2}
                          fontFamily="PPMori-Regular"
                        >
                          {charge.chargeName}
                        </Typography>
                        {index === chargesFee.length - 1 && (
                          <Typography
                            variant="body1"
                            fontSize="12px"
                            lineHeight={2}
                            fontFamily="PPMori-Regular"
                          >
                            Total fee amount
                          </Typography>
                        )}
                      </>
                    ))
                  : null}
              </Grid>
              <Grid item xs={6} textAlign="right">
                {chargesFee.length && sendAmount?.length > 2
                  ? chargesFee.map((charge, index) => (
                      <>
                        {index === 0 && (
                          <Typography
                            variant="body2"
                            fontSize="12px"
                            fontFamily="PPMori-Regular"
                            marginRight="20px"
                            lineHeight={2}
                          >
                            1 USD = {`${exchangeRate} ${receiveCurrency}`}
                          </Typography>
                        )}
                        <Typography
                          variant="body2"
                          fontSize="12px"
                          fontFamily="PPMori-Regular"
                          marginRight="20px"
                          lineHeight={2}
                        >
                          {charge.chargeAmount} {charge.chargeCurrency}
                        </Typography>
                        {index === chargesFee.length - 1 && (
                          <Typography
                            variant="body2"
                            fontSize="12px"
                            fontFamily="PPMori-Regular"
                            marginRight="20px"
                            lineHeight={2}
                          >
                            {chargeTotal} USD
                          </Typography>
                        )}
                      </>
                    ))
                  : null}
              </Grid>
            </>
          )}
          <Box display={"flex"} gap={2} width={"100%"}>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "none",
                borderColor: "#462A9C",
                color: "#462A9C",
                fontSize: "12px",
                fontFamily: "PPMori-Regular",
                width: "100%",
                py: 1,
              }}
              fullWidth
              onClick={handleGoBack}
            >
              Go back
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
                backgroundColor: "#462A9C",
                color: "#ffffff",
                fontSize: "12px",
                fontFamily: "PPMori-Regular",
                width: "100%",
                py: 1,
              }}
              onClick={handleNext}
              disabled={
                showAmountError ||
                !sendAmount ||
                isFetching ||
                !Object.keys(showReason).length
              }
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default SendMoneyTransferAmountForm;
