import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
import { ReactComponent as AlertTriangle } from "../../Images/AlertTriangle.svg";

const TransactionFailure = ({
  retryAction,
  goBackAction,
  goBackActionText,
  retryActionText,
}) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        height={"100%"}
        gap={2}
      >
        <AlertTriangle height={46} width={46} />
        <Typography variant="h5">Transaction Failed</Typography>
        <Typography variant="body1" color="#86837f" fontSize={12}>
          It seems there was an issue processing your transaction. This may have
          occurred due to one of the following reasons:
        </Typography>
        <ul style={{ color: "#86837f", fontSize: "12px", paddingLeft: "20px", margin: 0 }}>
          <li style={{ listStyleType: "disc", listStylePosition: "inside", color: "red" }}> {/* Red bullet */}
            <span style={{ color: "#000" }}>Insufficient funds in your wallet</span>
          </li>
          <li style={{ listStyleType: "disc", listStylePosition: "inside", color: "red" }}> {/* Red bullet */}
            <span style={{ color: "#000" }}>Connectivity issues</span>
          </li>
          <li style={{ listStyleType: "disc", listStylePosition: "inside", color: "red" }}> {/* Red bullet */}
            <span style={{ color: "#000" }}>Incorrect recipient details</span>
          </li>
        </ul>
        <Typography variant="body1" color="#86837f" fontSize={12}>
          Please review the information and try again. If the issue persists,
          feel free to contact our support team for further assistance.
        </Typography>
        <Box display={"flex"} gap={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={goBackAction}
            sx={{
              marginRight: "20px",
              borderColor: "#462A9C",
              color: "#462A9C",
              fontSize: "12px",
            }}
          >
            {goBackActionText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={retryAction}
            sx={{
              backgroundColor: "#462A9C",
              color: "#FFFFF",
              fontSize: "12px",
            }}
          >
            {retryActionText}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TransactionFailure;
