import React, { createContext, useContext, useState, useEffect } from 'react';
import { getProfile, getProfilePicture } from '../lib/service/ProfileApi/profileApi'; // Ensure this path is correct
import dayjs from 'dayjs';

const ProfileContext = createContext(null);

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState({});
  const [formsData, setFormsData] = useState({});
  const [fullName, setFullName] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      setLoading(false);
      return;
    }

    const fetchProfile = async () => {
      try {
        const response = await getProfile(token);
        if (response.status === 'Success') {
          setProfile(response.data);
          setFormsData({
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            dateOfBirth: dayjs(response.data.dateOfBirth),
            phone: response.data.phone,
            email: response.data.email,
            addressLine1: response.data.addressLine1,
            addressLine2: response.data.addressLine2,
            city: response.data.city,
            state: response.data.state,
            country: response.data.country,
            postalCode: response.data.postalCode,
          });
          setFullName(`${response.data.firstName} ${response.data.lastName}`);
        }

        const profileResponse = await getProfilePicture(token);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(profileResponse);
      } catch (error) {
        console.error('Failed to fetch profile data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []); // Ensure this dependency array is empty

  const value = {
    profile,
    formsData,
    fullName,
    previewUrl,
    loading,
    setProfile,
    setFormsData,
    setFullName,
    setPreviewUrl,
    setLoading,
  };

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};

export default ProfileContext;
