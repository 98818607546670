import React, { useState } from 'react';
import { Alert, AlertTitle, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import { changeKYCStatus } from '../../lib/service/DashboardApi/dashboardApi';

const CustomAlert = styled(Alert)(({ theme }) => ({
  '& .MuiAlert-action': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .action-container': {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }
}));

const VerificationNotification = ({ statusLink, onOpenModal }) => {
  const [visible, setVisible] = useState(true);
  const { kycStatus } = statusLink;

  const handleClose = async () => {
    try {
      await changeKYCStatus({ show_notif: true });
      setVisible(false);
    } catch (error) {
      console.error('Failed to change KYC status:', error);
    }
  };

  if (!visible) {
    return null;
  }

  let alertTitle, alertMessage, buttonText, severity;

  switch (kycStatus) {
    case 'COMPLETED':
      alertTitle = "Verification completed";
      alertMessage = "Congratulations! Your verification process has been successfully completed. You can now enjoy the full benefit of Endl with verified status.";
      buttonText = "Got it";
      severity = "success";
      break;
    case 'NOT_INITIATED':
      alertTitle = "Initiate verification";
      alertMessage = "Complete your KYC to unlock all features and secure transactions.";
      buttonText = "Start KYC";
      severity = "info";
      break;
    case 'FAILED':
      alertTitle = "Verification failed";
      alertMessage = "We regret to inform you that the verification process has failed. This could be due to several reasons, including incorrect information provided or expired documents.";
      buttonText = "See status";
      severity = "failed";
      break;
    default:
      alertTitle = "Verification in progress";
      alertMessage = "We will notify you when the verification is completed and the status will be updated automatically.";
      buttonText = "See status";
      severity = "warning";
      break;
  }

  return (
    <CustomAlert
      severity={severity}
      action={
        <div className="action-container">
          <Button color="inherit" size="small" onClick={kycStatus !== 'COMPLETED' ? onOpenModal : handleClose }>
            {buttonText}
          </Button>
          {kycStatus === 'COMPLETED' && (
             <IconButton
             aria-label="close"
             color="inherit"
             size="small"
             onClick={handleClose}
            >
             <CloseIcon fontSize="inherit" />
           </IconButton>
          )}  
        </div>
      }
      style={{ marginBottom: '16px' }}
    >
      <AlertTitle>{alertTitle}</AlertTitle>
      {alertMessage}
    </CustomAlert>
  );
};

export default VerificationNotification;
