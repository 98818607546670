import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SvgIcon from "@mui/material/SvgIcon";
import {
  FilterAltOutlined,
} from '@mui/icons-material';
import TransciationTable from "../../components/TableComponent/FilledRowComponent"
import { ReactComponent as exportfile } from "../../Images/file-export.svg";
import {
  PastDays,
} from "../../components/BasicDateRangePicker/BasicDateRangePicker";
import { ReactComponent as calender } from "../../Images/calendar.svg";
import FilterPayment from "../../components/FilterPayment/FilterPayment";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Notification from '../../components/Notification/Notification';
import Papa from 'papaparse';
import './TranscationHistory.css'
import { formatDate } from '../../lib/service/AuthToken';

const sortByOptionsArray = [
  { value: "createdOn,desc", label: "Date created: New to Old" },
  { value: "createdOn,asc", label: "Date created: Old to New" },
  { value: "recipientName,asc", label: "Recipient name: A-Z" },
  { value: "recipientName,desc", label: "Recipient name: Z-A" },
  { value: "sourceAmount,desc", label: "Amount: High to Low" },
  { value: "sourceAmount,asc", label: "Amount: Low to High" },
];

const statusOptionsArray = [
  { value: 'INITIATED', label: 'Initiated' },
  { value: 'IN_REVIEW', label: 'In Review' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'COMPLETE', label: 'Complete' },
  { value: 'REJECTED', label: 'Rejected' },
];


const TranscationHistory = ({
  searchTerm,
  days,
  handleSearch,
  handleDays,
  handleTranscationChange,
  transactionsType,
  handleChangeDatecreated,
  dateCreated,
  rows,
  loading,
  page,
  rowsPerPage,
  totalCount,
  onChangePage,
  onChangeRowsPerPage,
  handleChangeStatus,
  status,
  handleApplyFilters,
  anchorEl,
  handleClick,
  handleDateFilterChange,
  clearAllFilters,
  resetFilters,
  showClearAllButton,
}) => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [csvData, setCSVData] = useState([]);
  const [rangeDate, setRangeDate] = useState(null);

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleExportCSV = () => {
    const csvData = rows.map(row => ({
      'Recipient Name': row.recipientData.firstName + " " + row.recipientData.lastName,
      'Transaction Id': row.txnId,
      'Type': row.sentOrReceived,
      'Currency': row.sourceCurrency,
      'Amount': row.sourceAmount,
      'Deposit Method': row.endlTransactionMode,
      'dateCreated': formatDate(row.updatedOn),
      'Status': row.status
    }));
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'transaction_history.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Exporting CSV is not supported in this browser.");
    }
    setNotificationOpen(true);
    setNotificationMessage(`File "transaction_history.csv" created successfully`);
  };

  const handleExportPDF = () => {
    const doc = new jsPDF();
    const columns = [
      { header: 'Recipient Name', dataKey: 'recipientName' },
      { header: 'Transaction ID', dataKey: 'txnId' },
      { header: 'Type', dataKey: 'type' },
      { header: 'Currency', dataKey: 'currency' },
      { header: 'Amount', dataKey: 'amount' },
      { header: 'Deposit Method', dataKey: 'paymentMethod' },
      { header: 'Date Created', dataKey: 'dateCreated' },
      { header: 'Status', dataKey: 'status' }
    ];
  
    const tableRows = rows.map(rowData => ({
      recipientName: rowData.recipientData.firstName + " " + rowData.recipientData.lastName,
      txnId: rowData.txnId,
      type: rowData.sentOrReceived,
      currency: rowData.sourceCurrency,
      amount: rowData.sourceAmount,
      paymentMethod: rowData.endlTransactionMode,
      dateCreated: formatDate(rowData.updatedOn),
      status: rowData.status
    }));
  
    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: tableRows.map(row => Object.values(row)),
      startY: 20,
      styles: {
        fontSize: 9,
        cellPadding: 1,
      },
      tableWidth: 'wrap',
      columnStyles: {
        0: { cellWidth: 23 },
        1: { cellWidth: 23 },
        2: { cellWidth: 23 },
        3: { cellWidth: 23 },
        4: { cellWidth: 23 },
        5: { cellWidth: 23 },
        6: { cellWidth: 23 },
        7: { cellWidth: 23 },
      },
      headStyles: {
        halign: 'center',
      },
      bodyStyles: {
        halign: 'center',
      },
    });
  
    const fileName = "transaction_history.pdf";
    doc.save(fileName);
    setNotificationOpen(true);
    setNotificationMessage(`File "${fileName}" created successfully`);
  };
  

  const updateCSVData = () => {
    const csvData = rows['txns'].map(row => ({
      'Recipient Name': row.recipientName,
      'Transaction Id': row.txnId,
      'Type': row.type,
      'Currency': row.sourceCurrency,
      'Amount': row.sourceAmount,
      'Payment Method': row.paymentMethod,
      'Date Created' : row.updateOn,
      'Status': row.status
    }));
    setCSVData(csvData);
  };

  const handleDateChange = (value) => {
    setRangeDate(value)
    if(handleDateFilterChange){
      handleDateFilterChange(value)
    }
  }

  return (
    <>
      <Box sx={{ mt: 0, mb: 4 }}> 
        <Box
          display={"flex"}
          flexDirection={"column"}
          padding={3}
          gap={2}
          sx={{ backgroundColor: "#F6F5F9" }}
        >
          <Box height={25}>
            <Typography
              variant="h6" fontSize="14px" color={'#87878A'}
            >
              Dashboard / <span style={{ color: '#0F0F0F', fontFamily: 'PPMori-Regular' }}>Transactions</span>
            </Typography>
          </Box>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              style={{ marginTop: '4vh' }} 
            >
              <Box height={50}>
                <Typography
                  variant="h3"
                  fontSize="26px"
                  fontFamily='PPMori-Regular'
                >
                  Transactions
                </Typography>
              </Box>
              <Grid item xs={9}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      style={{ borderColor: "#462A9C", color: "#462A9C", fontFamily: 'PPMori-Regular',textTransform: "none", }}
                      fullWidth
                      startIcon={<SvgIcon component={exportfile} />}
                      onClick={handleExportPDF}
                    >
                      Export PDF
                    </Button>
                  </Grid>
                  <Grid item style={{ marginRight: '35px' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ borderColor: "#462A9C", color: "#462A9C", fontFamily: 'PPMori-Regular',textTransform: "none", }}
                      fullWidth
                      startIcon={<SvgIcon component={exportfile} />}
                      onClick={handleExportCSV}
                    >
                      Export CSV
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ margin: '10px 25px' }}>
          <Grid
            container
            direction="row"
            mt="2"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              '& .MuiTextField-root': { width: '24ch' },
            }}>
            <Grid item xs={3}>
              <TextField
                placeholder='Search by keywords...'
                variant="outlined"
                size="small"
                onChange={handleSearch}
                value={searchTerm || ''}
                style={{ fontFamily: 'PPMori-Regular' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ display: 'flex', alignItems: 'center', fontSize: '8px', fontFamily: 'PPMori-Regular' }} position="start"><SearchIcon style={{ width: '20px', height: '20px' }} /></InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                sx={{
                  '& .MuiTextField-root': { paddingLeft: '1px', width: '24ch' },
                }}
                alignItems="center"
              >
                <Grid item style={{ paddingLeft: '3px' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <PastDays rangeDate={rangeDate} setRangeDate={handleDateChange} fromTransactionModule={true} />
                </FormControl>
                </Grid>
                {/* <Grid item style={{ paddingLeft: '3px' }}>
                  <FormControl sx={{ m: 1, minWidth: 135 }} size="small">
                    <Select
                      id="transcation-id"
                      value={transactionsType}
                      displayEmpty
                      style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}
                      onChange={handleTranscationChange}
                    >
                      <MenuItem value="All" style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>All</MenuItem>
                      <MenuItem value="Sent" style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>Sent</MenuItem>
                      <MenuItem value="Receive" style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>Received</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item style={{ paddingLeft: '3px' }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                      id="status-of-transcation"
                      value={status}
                      displayEmpty
                      onChange={handleChangeStatus}
                      renderValue={(selected) => {
                        if (!selected) {
                          return "Select status";
                        }
                        const selectedValueLabel = statusOptionsArray.find(fd => fd.value === selected)?.label;
                        return `Status: ${selectedValueLabel}`
                      }}
                    >
                      {
                         statusOptionsArray.map(item => (
                          <MenuItem value={item.value} style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>{item.label}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item style={{ paddingLeft: '3px' }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                      id="date-of-transcation"
                      value={dateCreated}
                      onChange={handleChangeDatecreated}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return "Sort by:";
                        }
                        const selectedValueLabel = sortByOptionsArray.find(fd => fd.value === selected)?.label;
                        return `Sort by: ${selectedValueLabel}`
                      }}
                    >
                      {
                        sortByOptionsArray.map(item => (
                          <MenuItem value={item.value} style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>{item.label}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sx={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '4px', marginLeft: '9px', cursor: 'pointer', width: '95px' }} onClick={handleClick}>
                  <IconButton>
                    <FilterAltOutlined />
                  </IconButton>
                  <Typography variant="body1" sx={{ fontSize: '14px', marginRight: '5px', fontFamily: 'PPMori-Regular' }}>
                    Filters
                  </Typography>
                </Grid>
                <FilterPayment anchorEl={anchorEl} handleClick={handleClick} handleApplyFilters={handleApplyFilters} resetFilters={resetFilters} />
                <Grid item>
                  {
                    showClearAllButton() ? (
                      <Typography
                        variant="outlined"
                        size="small"
                        sx={{
                          height: "40px",
                          m: 1,
                          cursor: 'pointer',
                          color: '#462A9C',
                          fontSize:'16px',
                          fontWeight: 'bold'
                        }}
                        onClick={() => {
                          setRangeDate(null);
                          clearAllFilters()
                        }}
                      >
                        Clear All
                      </Typography>
                    ) : null
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ margin: '0 25px' }}>
          <TransciationTable rows={rows} loading={loading} page={page} rowsPerPage={rowsPerPage} totalCount={totalCount} onChangePage={onChangePage} onChangeRowsPerPage={onChangeRowsPerPage} />
        </Box>
      <Notification open={notificationOpen} onClose={handleNotificationClose} message={notificationMessage} />
      </Box>
    </>
  );
};

export default TranscationHistory;
