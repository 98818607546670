import React from "react";
import { ListItemButton, Popover, List, ListItemText, ListItemIcon } from "@mui/material";
const PopoverList = ({
  open,
  handleClose,
  anchorEl,
  style,
  transformOrigin,
  anchorOrigin,
  options,
}) => {
  const id = open ? "popover" : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ ...anchorOrigin }}
      transformOrigin={{ ...transformOrigin }}
      sx={{
        ...style,
      }}
    >
      <List>
        {options.map((option, index) => (
          <ListItemButton
            key={`${index}/${option.label}`}
            onClick={option.onClick}
            sx={{gap:1}}
          >
            {option.icon}
            <ListItemText primary={option.label} sx={{...option.style}}/>
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
};

export default PopoverList;
