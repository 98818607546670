import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Tooltip,
  IconButton
} from "@mui/material";
import icon from "../../Images/Icon Buttons.png";
import { ReactComponent as Plus } from "../../Images/Plus.svg";
import {ReactComponent as Group} from "../../Images/Group.svg";

function EmptyTableComponent({ rowHeadingdata, tabledata, dashboard = false, onNoTransactionYetClick = () => {} }) {
  return (
    <>
      {dashboard ? (
        <Box>
          <Typography
            textAlign="center"
            marginTop="20px"
            fontFamily="PPMori-Regular"
          >
            <IconButton>
              <Group />
            </IconButton>
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            fontSize="12px"
            fontFamily="PPMori-Regular"
          >
            No transactions yet
          </Typography>
          <Typography
            variant="body1"
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              marginBottom: "20px",
            }}
          >
            Begin your journey by making a transaction to see your activity
            here.
          </Typography>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "100%",
            overflowX: "auto",
            borderRadius: 1,
            borderCollapse: "collapse",
            boxShadow: "none",
            height: "100%",
          }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {rowHeadingdata.map((item, index) => (
                  <TableCell
                    key={item}
                    align="left"
                    sx={{
                      borderRight:
                        index !== rowHeadingdata.length - 1 && "1px solid #ccc",
                      backgroundColor: "#F6F5F9",
                      fontWeight: "bold",
                      fontFamily: "PPMori-Regular",
                    }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
          <div className="tooltip-div">
            <Tooltip title={tabledata.title} placement="top">
            <IconButton>
                   <Group />
                </IconButton>
            </Tooltip>
            <span
              style={{
                margin: "15px 0",
                fontWeight: "600",
                fontFamily: "PPMori-Regular",
                fontSize: "18px",
              }}
            >
              {tabledata.recipient}
            </span>
            <p
              style={{
                fontFamily: "PPMori-Regular",
                color: "#4A4643",
                fontSize: "16px",
              }}
            >
              {tabledata.description}
            </p>
          </div>
        </TableContainer>
      )}
    </>
  );
}

export default EmptyTableComponent;
