import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAuthToken } from "../../lib/service/AuthToken";
import Login from "../../pages/LoginAndSignUp/Login";
import { login } from "../../lib/service/authApi/authApi";
import { notify } from "../../components/Notification/Notification";
import { getProfile, getProfilePicture } from "../../lib/service/ProfileApi/profileApi";
import ProfileContext from "../../context/ProfileContext";
import dayjs from "dayjs";

const LoginContainer = () => {
  const {setProfile, setFormsData, setFullName, setPreviewUrl, setLoading} = useContext(ProfileContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: null,
    password: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const isValidInput = () => {
    let isValid = true;
    const errors = { ...formErrors };
    if (!formData.email || formData.email === "") {
      errors.email = "Email is required";
      isValid = false;
    }
    if (!formData.password || !formData.password === "") {
      errors.password = "Password is required";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e?.preventDefault();
    if (!isValidInput()) return;
    setIsLoading(true);
    try {
      const response = await login(formData);
      if (response.otpRequiredForLogin) {
        navigate("/2fa-login");
        return;
      }
      const authToken = response.data ? response.data.token : "";
      const expiryDate = response.data ? response.data.expiryDate : "";
      if (authToken) {
        setAuthToken(authToken, expiryDate);
        notify.success({
          message: "You are successfully logged in.",
        });
        fetchProfile();
        navigate("/dashboard");

      } else {
        notify.error({
          message: "Failed to login",
          description:
            "Unable to login. May be you have provide wrong credential, or can contact us.",
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      if (error?.errors?.length > 0) {
        error.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to login",
          description:
            "Unable to login. May be you have provide wrong credentials, or you can contact us.",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleInputChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [e.target.name]: null,
    }));
  };

  const fetchProfile = async () => {
    try {
      const response = await getProfile();
      if (response.status === 'Success') {
        setProfile(response.data);
        setFormsData({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          dateOfBirth: dayjs(response.data.dateOfBirth),
          phone: response.data.phone,
          email: response.data.email,
          addressLine1: response.data.addressLine1,
          addressLine2: response.data.addressLine2,
          city: response.data.city,
          state: response.data.state,
          country: response.data.country,
          postalCode: response.data.postalCode,
        });
        setFullName(`${response.data.firstName} ${response.data.lastName}`);
      }

      const profileResponse = await getProfilePicture();
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(profileResponse);
    } catch (error) {
      console.error('Failed to fetch profile data', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Login
      formData={formData}
      handleInputChange={handleInputChange}
      formErrors={formErrors}
      onSubmit={handleLogin}
      isLoading={isLoading}
    />
  );
};

export default LoginContainer;
