import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  TextField,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { WhatsApp } from "@mui/icons-material";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { useNavigate } from "react-router-dom";

const faqs = [
  {
    question:
      "How many currencies are supported for crypto to fiat conversion?",
    answer:
      "We support 2 digital currencies and up to 20 popular fiat currencies.",
  },
  {
    question: "How quickly can I convert between crypto and fiat?",
    answer: "Conversion can be completed within 10 minutes to 1 hour.",
  },
  {
    question: "How many currencies are available for multi-currency accounts?",
    answer:
      "You can open multi-currency accounts in up to 5 globally popular fiat currencies.",
  },
  {
    question:
      "Is identity verification required to convert between crypto and fiat?",
    answer:
      "Yes, completing identity verification is mandatory for all crypto-to-fiat conversions.",
  },
  {
    question: "How long does identity verification take?",
    answer:
      "Identity verification is typically completed within 6 to 72 hours, depending on the jurisdiction.",
  },
  {
    question: "How fast are payments settled?",
    answer: "Payments are settled within 4 to 72 hours, depending on the jurisdiction.",
  },
  {
    question: "What currencies are supported for payments?",
    answer: "You can make payments to over 20 fiat currencies via local payment rails.",
  },
  {
    question: "What payment methods are supported?",
    answer: "Payments can be processed via ACH, wires, and international rails for both vendors and employees.",
  },
];

function HelpCenterContainer() {
  const [open, setOpen] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [cardDetails, setCardDetails] = useState([
    { title: "Opened tickets", amount: "0" },
    // { title: "Pending tickets", amount: "0" },
    // { title: "Closed tickets", amount: "0" },
  ]);

  const handleToggle = (index) => {
    setOpen(open === index ? null : index);
  };

  const filteredFAQs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const navigate = useNavigate();

  return (
    <Box>
      <Grid container>
        <Box
          display="flex"
          flexDirection="column"
          padding={3}
          gap={5}
          style={{
            backgroundColor: "#F6F5F9",
            width: "100%",
            margin: 0,
          }}
          pb={3}
        >
          {/* Breadcrumb and Page Title */}
          <Typography
            variant="h1"
            fontSize="14px"
            color="#8A8887"
            style={{ fontFamily: "PPMori-Regular" }}
          >
            Dashboard /{" "}
            <span style={{ color: "#000", fontFamily: "PPMori-Regular" }}>
              Help Center
            </span>
          </Typography>

          <Typography
            variant="h3"
            fontSize="26px"
            fontFamily="PPMori-Regular"
            color="#2A2A2A"
            mb={2}
          >
            Help Center
          </Typography>
          <Box
            container
            wrap="nowrap"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            style={{ fontSize: "12px", padding: "6px" }}
          >
            {/* Ticket Stats */}
            <Box display={"flex"} gap={2}>
              {cardDetails.map((card, index) => (
                <Box key={index} display={"flex"} gap={2} sx={{ minWidth: "170px" }}>
                  <Box sx={{ width: "100%" }}>
                    <Typography fontSize="14px" color={"#0F0F0F"}>
                      {card.title}
                    </Typography>
                    <Typography color={"#0F0F0F"} fontSize="18px">
                      {card.amount}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" />
                </Box>
              ))}
            </Box>

            {/* Contact Info and Button */}
            <Box display="flex" alignItems="center">
              {/* <EmailOutlinedIcon />
              <Typography marginLeft={1}>useremail@gmail.com</Typography> */}
              <WhatsApp style={{ fontSize: 25, color: "#25D366" }} />
              <Typography marginLeft={1}>+1 000 000 000</Typography>
              
              <Button
                style={{
                  marginLeft: 20,
                  backgroundColor: "#462A9C",
                  color: "#FFF",
                  borderRadius: "8px",
                  padding: "6px 12px",
                }}
                onClick={() => navigate("/contact-support")}
              >
                Contact support
              </Button>
              <Button
                style={{
                  marginLeft: 20,
                  backgroundColor: "#462A9C",
                  color: "#FFF",
                  borderRadius: "8px",
                  padding: "6px 12px",
                }}
                onClick={() => {
                  window.open('https://endl.freshdesk.com/support/tickets/new')
                }}
              >
                Raise a ticket
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Search Bar */}
        <Box padding={2} style={{ width: "100%" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by question..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ maxWidth: "50%" }}
          />
           <Typography
            variant="h6"
            fontFamily="PPMori-Regular"
            color="#2A2A2A"
            mt={2}
          >
            Frequently asked questions
          </Typography>
        </Box>

        {/* FAQ Section */}
        <Box display="flex" justifyContent="center">
         
          <Box sx={{ maxWidth: "85%" }}>
            <List>
              {filteredFAQs.map((faq, index) => (
                <React.Fragment key={index}>
                  {/* FAQ Question */}
                  <ListItem
                    button
                    onClick={() => handleToggle(index)}
                    aria-expanded={open === index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between", // Space out the question and icon
                      alignItems: "center",
                      padding: "10px 0",
                    }}
                  >
                    {/* Question Text */}
                    <ListItemText
                      primary={faq.question}
                      primaryTypographyProps={{
                        variant: "body1",
                        style: { textAlign: "left", fontWeight: 500 },
                      }}
                    />

                    {/* Expand/Collapse Icons */}
                    {open === index ? (
                      <ExpandLess style={{ marginLeft: 8 }} />
                    ) : (
                      <ExpandMore style={{ marginLeft: 8 }} />
                    )}
                  </ListItem>

                  {/* FAQ Answer (Collapse Section) */}
                  <Collapse in={open === index} timeout="auto" unmountOnExit>
                    <Box padding={2}>
                      <Typography
                        variant="body2"
                        color="#6C6C6C"
                        style={{ maxWidth: "80%" }}
                      >
                        {faq.answer}
                      </Typography>
                    </Box>
                  </Collapse>

                  {/* Divider between FAQs */}
                  <Divider sx={{ margin: "0 16px" }} />
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Box>

      </Grid>
    </Box>
  );
}

export default HelpCenterContainer;
