import React from "react";
import {
  Typography,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  styled,
} from "@mui/material";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
    color: "black",
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#462A9C",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#462A9C",
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const TopUpPaymentMethodForm = ({
  selectedPaymentMethod,
  handleSelectedPaymentMethodOptionChange,
  handleNext,
}) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      gap={2}
      flexDirection={"column"}
    >
      <Typography
        fontWeight={600}
        variant="h6"
        textAlign={"left"}
        width={"100%"}
      >
        Payment Type
      </Typography>
      <RadioGroup
        aria-label="payment-type"
        name="paymentType"
        value={selectedPaymentMethod}
        onChange={handleSelectedPaymentMethodOptionChange}
        style={{
          width: "100%",
        }}
      >
        {[
          { value: "credit/debit", label: "Credit/Debit Card" },
          { value: "endlAccount", label: "endl Account" },
          { value: "cryptoCurrency", label: "Crypto Currency" },
          { value: "localBank", label: "Local bank transfer" },
        ].map((option) => (
          <Box
            key={option.value}
            sx={{
              border: "1px solid rgba(0,0,0,0.2)",
              mb: 2,
              width: "100%",
              borderRadius: "5px",
              padding: 1,
              backgroundColor: "rgba(0,0,0,0.04)",
            }}
          >
            <FormControlLabel
              value={option.value}
              control={<BpRadio />}
              label={option.label}
              sx={{
                ".MuiFormControlLabel-label": {
                  fontSize: 14,
                  fontFamily: "PPMori-Semi-Bold",
                },
                ml: 1,
              }}
            />
            {selectedPaymentMethod === option.value && selectedPaymentMethod === "localBank" && (
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                padding={2}
              >
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Typography variant="body1" fontSize={12}>
                    Currency
                  </Typography>
                  <TextField
                    placeholder="EUR/CAD etc."
                    fullWidth
                    sx={{
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px 14px",
                        },
                      background: "white",
                    }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Typography variant="body1" fontSize={12}>
                    Enter Name of the Account Holder
                  </Typography>
                  <TextField
                    placeholder="Account holer name"
                    fullWidth
                    sx={{
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px 14px",
                        },
                      background: "white",
                    }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Typography variant="body1" fontSize={12}>
                    IBAN/Account number
                  </Typography>
                  <TextField
                    placeholder="Enter IBAN/Account number"
                    fullWidth
                    sx={{
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px 14px",
                        },
                      background: "white",
                    }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Typography variant="body1" fontSize={12}>
                    Swift/BIC Code
                  </Typography>
                  <TextField
                    placeholder="Enter Swift/BIC number"
                    fullWidth
                    sx={{
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px 14px",
                        },
                      background: "white",
                    }}
                  />
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Typography variant="body1" fontSize={12}>
                    Bank Name
                  </Typography>
                  <TextField
                    placeholder="Enter bank name number"
                    fullWidth
                    sx={{
                      "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          padding: "12px 14px",
                        },
                      background: "white",
                    }}
                  />
                </Box>
              </Box>
            )}
            {selectedPaymentMethod === option.value && selectedPaymentMethod === "credit/debit" && (
              <Box>For credit card forms/data</Box>
            )}
            {selectedPaymentMethod === option.value && selectedPaymentMethod === "endlAccount" && (
              <Box>For endl accounts forms/data</Box>
            )}
            {selectedPaymentMethod === option.value && selectedPaymentMethod === "cryptoCurrency" && (
              <Box>For cryptoCurrency accounts forms/data</Box>
            )}
          </Box>
        ))}
      </RadioGroup>
      <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Button
          onClick={handleNext}
          variant="contained"
          color="primary"
          sx={{
            fontFamily: "PPMori-Semi-Bold",
            width: "120px",
            backgroundColor: "#462A9C",
            color: "#ffffff",
            fontSize: "12px",
            textTransform: "none",
            py: 1,
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default TopUpPaymentMethodForm;
