import directService from "../AppService"
import { checkAuthToken } from "../AuthToken"

function getAuthToken() {
    return localStorage.getItem('authToken');
}

const getAllAccountsList = () => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/accounts/`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}


const getAccountDetails = (accountId) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `api/v1/accounts/details/${accountId}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}


const getTransactionDetails = (transactionId) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `/api/v1/accounts/get_transactions/${transactionId}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}


const openAccount = (data) => {
    const checkToken = checkAuthToken()
    if (checkToken) {
        const token = getAuthToken();
        if (!token) {
            console.error('No auth token found. Please login.');
            return;
        }
        return directService(
            {
                url: `/api/v1/accounts/open`,
                method: 'POST',
                data: data,
                headers: { Authorization: `Bearer ${token}` },
            },

        )
    }

}

const changeStatus = (data, accountNumber) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `/api/v1/accounts/change_status/${accountNumber}`,
                    method: 'PUT',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const getBalance = () => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `/api/v1/accounts/get_balance`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
    } catch (error) {
        console.log(error)
    }
}

const fetchCurrencyToOpenAccount = () => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            return directService(
                {
                    url: `/api/v1/currency/list/accounts`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
        }
    } catch (error) {
        console.log(`Error while fetching currency to open account ${error}`)
    }
}

const transferBetweenAccounts = (data) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            return directService(
                {
                    url: `api/v1/accounts/convert`,
                    method: 'POST',
                    body: data,
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
        }
    } catch (error) {
        console.log(`Error while fetching currency to open account ${error}`)
    }
}

const fetchAccountTransactionDetails = (transactionId) => {
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            return directService(
                {
                    url: `/api/v1/accounts/transactions/${transactionId}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
        }
    } catch (error) {
        console.log(`Error while fetching account transaction details ${error}`)
    }
}


export {
    getAllAccountsList,
    getAccountDetails,
    getBalance,
    getTransactionDetails,
    openAccount,
    changeStatus,
    fetchCurrencyToOpenAccount,
    transferBetweenAccounts,
    fetchAccountTransactionDetails,
}
