import React from 'react';
import { Box, Typography } from '@mui/material';

const TermsOfUseModal = ({ show, handleClose }) => {
  return (
    <Box show={show} onHide={handleClose} size="lg" centered>
        <h4>Restricted Use</h4>
        <p>
          Unless otherwise indicated, all copyright and other intellectual property rights in all content and other materials contained on the website or provided in connection with the Services, including, without limitation, logo and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof (collectively, “Materials”) are the proprietary property of Layer2 or our licensors or suppliers, are copyrighted under United States law and protected by worldwide copyright laws and treaty provisions. Materials on www.layer2financial.com may not be copied, distributed, or transmitted in any way without the prior written consent of Layer2 Financial Holdings Inc. or its affiliates (collectively, “Layer2”). Users of the Website may download one copy of any and all materials on the Website for personal, non-commercial use provided that they do not modify or alter the materials in any way, nor delete or change any copyright or trademark notice. All materials on this Website are provided for lawful purposes only. Layer2 reserves complete title and full intellectual property rights for materials made available on the Website. All Layer2 trademarks, logos, service marks, trade dress, slogans, copyrighted designs or other brand features, including hyperlinks to this Website, will be used only as explicitly licensed by Layer2, and only under the terms and conditions and for the purposes described in such license.
        </p>

        <h4>Third Party Links</h4>
        <p>
          Layer2 will consider link requests to non-Layer2 Websites upon request but reserves the right to refuse for any reason. You may request that a link be placed on www.layer2financial.com by contacting Layer2 at contact@layer2financial.com. Websites that wish to link to Layer2’s Website may include a link to “www.layer2financial.com”. However, deep linking to this domain is not allowed without Layer2’s written consent.
        </p>

        <h4>Intellectual Property</h4>
        <p>
          The website and the Content are the property of Layer2, its affiliates, or their third-party licensors, and are protected by copyright and other intellectual property laws. The re-publishing of content found on the Layer2 Website to a non-Layer2 Website must be approved in writing in advance by Layer2. Nothing contained on this website should be construed as granting any license or right to use any of the Marks displayed on the website without the express written permission of Layer2. You may request approval by contacting Layer2 at contact@layer2financial.com. Layer2 will typically review each request and respond within forty-eight (48) hours but is under no obligation to respond or approve any such request.
        </p>

        <h4>Anti-Hacking Provision</h4>
        <p>
          You expressly agree not to:
          <ul>
            <li>Use or attempt to use any “deep-link,” “scraper,” “robot,” “bot,” “spider,” “data-mining,” “computer code” or any other automated device, program, tool, algorithm, process, or methodology or manual process having similar processes or functionality, to access, acquire, copy, or monitor any portion of the website, or Content found on or accessed through the website or any Other Content without the prior express written consent of Layer2;</li>
            <li>Obtain or attempt to obtain through any means any materials or information on the website that have not been intentionally made publicly available either by their public display on the website or through their accessibility by a visible link on the website;</li>
            <li>Violate the restrictions in any robot inclusion headers on this website or in any way bypass or circumvent any other measure employed to limit or prevent access to the website or its Content;</li>
            <li>Violate the security of the website or attempt to gain unauthorized access to the website, its Content and Other Content, or the computer systems, networks, or servers of Layer2, through hacking, password mining, or any other means;</li>
            <li>Interfere or attempt to interfere with the proper working of the website or any activities conducted on or through the website, including accessing any data, content, or other information prior to the time that it is intended to be available to the public on the website; and/or</li>
            <li>Take or attempt any action that, in the sole discretion of Layer2, imposes or may impose an unreasonable or disproportionately large load or burden on the website or Layer2’s infrastructure.</li>
          </ul>
        </p>

        <h4>Notice</h4>
        <p>
          While Layer2 strives to provide accurate and timely information, there may be inadvertent inaccuracies, errors, and omissions, for which we apologize. We reserve the right to make changes and corrections at any time, without notice. The content is provided “AS IS,” “AS AVAILABLE.” Layer2 does not warrant the accuracy or completeness of the information, text, graphics, links, or other items contained on the Website, and Layer2 expressly disclaims liability for errors or omissions in these materials. Layer2 makes no commitment to update the information contained on the Website. Layer2 expressly disclaims all liability for the use or interpretation by others of information contained in www.layer2financial.com. Decisions based on information contained on www.layer2financial.com are the sole responsibility of the visitor, and in exchange for using www.layer2financial.com, the visitor agrees to hold Layer2 and its affiliates harmless against any claims for damages arising from any decisions that the visitor makes based on such information. Nothing contained on www.layer2financial.com constitutes investment advice, nor does it constitute the solicitation of the purchase or sale of any financial products.
        </p>

        <h4>Data</h4>
        <p>
          Notwithstanding any regulatory or legal limitations, Users are only allowed to use any publicly available trade data for their own non-commercial purposes. Users acknowledge that they have no ownership of, or license to, any data or contents or any of the relevant third-party index/information providers. Without limiting the prohibitions of paragraph 1, above, users may not use or exploit data for the purpose of: creating (whether for their own purposes or on behalf of any other third party) any financial product or service or index or any attempt to match the performance of, or whose capital and/or income value is related to, any of Layer2 data. The user acknowledges public data is not a replacement for being property licensed for use. The recipient may not use Layer2 data for any purpose other than as stated herein without the prior written approval of Layer2.
        </p>

        <h4>Liability Restrictions</h4>
        <p>
          Layer2 is not responsible for technical, hardware, or software failures of any kind; lost or unavailable network connections; incomplete, garbled, or delayed computer transmissions. Under no circumstances will Layer2 or its suppliers be liable for any damages or injury that results from the use of the materials on this site. Some jurisdictions prohibit the exclusion or limitation of liability for consequential or incidental damages, in which case the above limitation may not apply to you. The materials on this site are provided “as is” and without warranties of any kind to the fullest extent permissible pursuant to applicable laws. Related domains linking to other companies’ sites that are not maintained by Layer2 do not endorse those sites and are not responsible for the content of such other sites.
        </p>

        <h4>Enforcement of Terms and Conditions</h4>
        <p>
          These terms and conditions are governed and interpreted pursuant to the laws of Ontario, Canada, notwithstanding any principles of conflicts of law. If any part of these terms and conditions is unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of remaining provisions. Layer2 can revise these terms and conditions at any time by updating this site.
        </p>

        <h4>Copyright</h4>
        <p>
          Except as otherwise noted, the content of www.layer2financial.com, including but not limited to text, graphics, and icons, are copyrighted materials of Layer2 and contain trademarks, service marks, and trade names of Layer2 and/or its affiliates. ALL RIGHTS RESERVED. You may use the content to learn about, evaluate or acquire services or products of Layer2. You may not copy or display for redistribution to third parties for commercial purposes any portion of the content without the prior written permission of Layer2.
        </p>
    </Box>
  );
};

export default TermsOfUseModal;
