import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import AccountTransactionTable from "../../components/TableComponent/AccountTransactionTable";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import PopoverList from "../../components/Popover/PopoverList";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import InternalTransferModal from "../../components/ModalComponent/InternalTransfarModal/InternalTransferModal";
import TopUpModal from "../../components/ModalComponent/TopUpModal/TopUpModal";
import AccountStatusChangeModal from "../../components/ModalComponent/AccountStatusChangeModal";
import CloseAccountModal from "../../components/ModalComponent/CloseAccountModal";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import { useNavigate } from "react-router-dom";
const AccountDetails = ({
  accountDetails,
  isLoading,
  onAccountStatusChange,
  transactonList = [],
  allAccountsDetails,
}) => {
  const [accountActionEle, setAccountActionEle] = React.useState(null);
  const [accountDetailsSectionEle, setAccountDetailsSectionEle] =
    useState(null);
  const [downloadDocumentsEle, setDownloadDocumentsEle] = React.useState(null);
  const [internalTransferModalOpen, setInternalTransferModalOpen] =
    useState(false);
  const [topUpModalOpen, setTopUpModalOpen] = useState(false);
  const [accountStatusChangeOpenModal, setAccountStatusChangeOpenModal] =
    useState(false);
  const [closeAccountModalOpen, setCloseAccountModalOpen] = useState(false);
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [showAccountBalance, setShowAccountBalance] = useState(false);
  const [isNameEditing, setIsNameEditing] = useState(false);
  const navigate = useNavigate();

  const handleOpenAccountDetailsPopover = (event) => {
    setAccountDetailsSectionEle(event.currentTarget);
  };

  const handleCloseAccountDetailsPopover = (event) => {
    setAccountDetailsSectionEle(null);
  };

  const handleOpenAccountActions = (event) => {
    setAccountActionEle(event.currentTarget);
  };

  const handleCloseAccountActions = () => {
    setAccountActionEle(null);
  };

  const handleOpenDownloadDocuments = (event) => {
    setDownloadDocumentsEle(event.currentTarget);
  };

  const handleCloseDownloadDocuments = () => {
    setDownloadDocumentsEle(null);
  };

  const accountActionPopoverOptions = [
    // {
    //   label: "Export [placeholder]",
    //   onClick: () => {},
    // },
    {
      label: "Close Account",
      onClick: () => setCloseAccountModalOpen(true),
      style: {
        color: "red",
      },
    },
  ];
  const accountDetailsSectionPopoverOptions = [
    {
      label: "Change Status",
      onClick: () => setAccountStatusChangeOpenModal(true),
    },
  ];
  const downloadDocumentsOptions = [
    {
      label: "Your bank details",
      onClick: () => {},
      icon: <ContentPasteGoOutlinedIcon />,
    },
    {
      label: "Account ownership",
      onClick: () => {},
      icon: <ContentPasteGoOutlinedIcon />,
    },
    {
      label: "Bank statement",
      onClick: () => {},
      icon: <ContentPasteGoOutlinedIcon />,
    },
  ];

  return (
    <>
      <Box>
        <CloseAccountModal
          accountDetails={accountDetails} 
          open={closeAccountModalOpen}
          onClose={() => setCloseAccountModalOpen(false)}
          transferModalOpen={() => setInternalTransferModalOpen(true)}
        />
        <InternalTransferModal
          open={internalTransferModalOpen}
          onClose={() => setInternalTransferModalOpen(false)}
        />
        <TopUpModal
          open={topUpModalOpen}
          onClose={() => setTopUpModalOpen(false)}
        />
        {accountDetails && accountStatusChangeOpenModal && (
          <AccountStatusChangeModal
            open={accountStatusChangeOpenModal}
            onClose={() => setAccountStatusChangeOpenModal(false)}
            accountName={accountDetails.name}
            accountNumber={accountDetails.account_number_external}
            accountStatus={accountDetails.status}
            onAccountStatusChange={onAccountStatusChange}
          />
        )}
        <Box display={"flex"} flexDirection={"column"} height={"100svh"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            spacing={3}
            style={{
              backgroundColor: "#F6F5F9",
              width: "100%",
              margin: 0,
            }}
            p={3}
            gap={3}
          >
            <Typography
              variant="h1"
              fontSize="14px"
              color="#8A8887"
              style={{ fontFamily: "PPMori-Regular" }}
            >
              Dashboard / Accounts/{" "}
              <span style={{ color: "#000", fontFamily: "PPMori-Regular" }}>
                Accounts Details
              </span>
            </Typography>

            <Box display={"flex"} alignItems={"center"}>
              <IconButton onClick={() => navigate("/accounts")}>
                <ArrowBackIosNewOutlinedIcon />
              </IconButton>
              {!isNameEditing ? (
                <Typography
                  variant="h3"
                  fontSize="26px"
                  fontFamily="PPMori-Regular"
                >
                  {accountDetails?.name ? accountDetails.name : "Account name"}
                </Typography>
              ) : (
                <></>
                // <Box display={"flex"} flexDirection={"column"} gap={1}>
                //   <TextField
                //     variant="standard"
                //     value={accountDetails?.account_name}
                //     sx={{
                //       "& .css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                //         fontSize: 26,
                //       },
                //     }}
                //   />
                //   <Box display={"flex"} gap={1}>
                //     <Button
                //       variant="text"
                //       size="small"
                //       sx={{
                //         color: "black",
                //       }}
                //       onClick={() => setIsNameEditing(false)}
                //       startIcon={<DoneOutlinedIcon fontSize="small" />}
                //     >
                //       Save
                //     </Button>
                //     <Button
                //       variant="text"
                //       size="small"
                //       sx={{
                //         color: "black",
                //       }}
                //       onClick={() => setIsNameEditing(false)}
                //     >
                //       Cancel
                //     </Button>
                //   </Box>
                // </Box>
              )}
              {/* {!isNameEditing && (
                <IconButton
                  onClick={() => setIsNameEditing(true)}
                  sx={{ marginLeft: "5px" }}
                >
                  <ModeEditOutlineOutlinedIcon />
                </IconButton>
              )} */}
            </Box>
          </Box>
          <Box display={"flex"} gap={1} padding={3}>
            <Paper
              sx={{
                p: 3,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              variant="outlined"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={"flex-start"}
                mb={2}
              >
                <Box display={"flex"} gap={2}>
                  <Box>
                    <Typography variant="p">Total account balance</Typography>
                    <Box>
                      {accountDetails && (
                        <>
                          <Typography variant="h4" fontSize={30}>
                            {showAccountBalance ||
                            accountDetails.balance.toString().length < 5 ? (
                              `${accountDetails.balance} ${accountDetails.currency}`
                            ) : (
                              <span>
                                &#x2022;&#x2022;&#x2022;
                                {accountDetails.balance
                                  .toString()
                                  .slice(-3)}{" "}
                                {accountDetails.currency}
                              </span>
                            )}
                          </Typography>
                          {accountDetails.balance.toString().length > 5 && (
                            <IconButton
                              onClick={() =>
                                setShowAccountBalance((prev) => !prev)
                              }
                            >
                              {showAccountBalance ? (
                                <RemoveRedEyeOutlinedIcon fontSize="small" />
                              ) : (
                                <VisibilityOffOutlinedIcon fontSize="small" />
                              )}
                            </IconButton>
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                  <hr />
                  <Box>
                    <Typography variant="p">
                      Pending internal transfers
                    </Typography>
                    <Typography variant="h4" fontSize={30}>
                      {accountDetails
                        ? accountDetails.pending_internal_transfer + " " + accountDetails.currency
                        : 0}
                    </Typography>
                  </Box>
                </Box>
                <IconButton onClick={handleOpenAccountActions}>
                  <MoreHorizIcon />
                </IconButton>
                <PopoverList
                  open={Boolean(accountActionEle)}
                  anchorEl={accountActionEle}
                  handleClose={handleCloseAccountActions}
                  options={accountActionPopoverOptions}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </Box>
              <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
                {/* <Box display={"flex"} flexDirection={"column"}>
                  <Typography variant="p" fontSize={13} fontWeight={600}>
                    1 recurring
                  </Typography>
                  <Typography variant="p" fontSize={10}>
                    Next transfer: Feb 22
                  </Typography>
                </Box> */}
                <Box display={"flex"} gap={2}>
                  {
                    allAccountsDetails && allAccountsDetails.accounts?.length > 2 ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          borderColor: "#462A9C",
                          color: "#462A9C",
                          textTransform: "none",
                          fontFamily: "PPMori-Regular",
                          textWrap: "nowrap",
                        }}
                        onClick={() => setInternalTransferModalOpen(true)}
                        startIcon={<SyncAltIcon />}
                      >
                        Transfer between accounts
                      </Button>
                    ) : null
                  }
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#462A9C",
                      color: "#FFFFF",
                      textTransform: "none",
                      fontFamily: "PPMori-Regular",
                    }}
                    startIcon={<AccountBalanceWalletOutlinedIcon />}
                    onClick={() => setTopUpModalOpen(true)}
                    sx={{
                      '&.Mui-disabled': {
                        backgroundColor: "#462A9C",
                        color: "#FFFFFF",
                      },
                    }}
                    disabled
                  >
                    Top up
                  </Button>
                </Box>
              </Box>
            </Paper>

            <Paper
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
              }}
              variant="outlined"
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <Typography variant="p" fontWeight={600} di>
                  Account Details{" "}
                  {accountDetails && accountDetails.status === "CLOSED" && (
                    <Typography
                      component={"span"}
                      border={1}
                      borderColor={"rgba(0,0,0,0.35)"}
                      paddingX={"5px"}
                      borderRadius={"10px"}
                    >
                      Inactive
                    </Typography>
                  )}
                </Typography>
                <IconButton onClick={handleOpenAccountDetailsPopover}>
                  <MoreHorizIcon />
                </IconButton>
                <PopoverList
                  open={Boolean(accountDetailsSectionEle)}
                  anchorEl={accountDetailsSectionEle}
                  handleClose={handleCloseAccountDetailsPopover}
                  options={accountDetailsSectionPopoverOptions}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"column"} gap={1} sx={{ maxWidth: "33.33%" }}>
                  <Typography variant="p" fontSize={12} color={"grey"}>
                    Account number
                  </Typography>
                  <Box>
                    {accountDetails?.account_number_external ? (
                      <>
                        <Typography variant="p" fontSize={13}>
                          {showAccountNumber ? (
                            accountDetails.account_number_external
                          ) : (
                            <span>
                              &#x2022;&#x2022;&#x2022;
                              {accountDetails.account_number_external
                                .toString()
                                .slice(-3)}
                            </span>
                          )}
                        </Typography>
                        <IconButton
                          onClick={() => setShowAccountNumber((prev) => !prev)}
                        >
                          {showAccountNumber ? (
                            <RemoveRedEyeOutlinedIcon fontSize="small" />
                          ) : (
                            <VisibilityOffOutlinedIcon fontSize="small" />
                          )}
                        </IconButton>
                      </>
                    ) : (
                      <Typography textAlign={"center"}>-</Typography>
                    )}
                  </Box>
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={1} sx={{ maxWidth: "33.33%" }}>
                  {!accountDetails?.crypto_account ? (
                    <>
                      <Typography variant="p" fontSize={12} color={"grey"}>
                        Banking partner
                      </Typography>

                      {accountDetails?.bank_partner_name ? (
                        <Typography variant="p" fontSize={13}>
                          {accountDetails.bank_partner_name}
                        </Typography>
                      ) : (
                        <Typography textAlign={"center"}>XXXX</Typography>
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      <Typography variant="p" fontSize={12} color={"grey"}>
                        Deposit address
                      </Typography>
                      {accountDetails?.crypto_deposit_address ? (
                        <Typography variant="p" fontSize={13}>
                          {accountDetails?.crypto_deposit_address.slice(0, 4)}
                          ...{accountDetails?.crypto_deposit_address.slice(-4)}
                        </Typography>
                      ) : (
                        <Typography
                          variant="p"
                          fontSize={13}
                          textAlign={"center"}
                        >
                          -
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
                <Box display={"flex"} flexDirection={"column"} gap={1} sx={{ maxWidth: "33.33%" }}>
                  {!accountDetails?.crypto_account ? (
                    <>
                      {" "}
                      <Typography variant="p" fontSize={12} color={"grey"}>
                        Banking code
                      </Typography>
                      {accountDetails?.account_code ? (
                        <Typography variant="p" fontSize={13}>
                          {accountDetails.account_code}
                        </Typography>
                      ) : (
                        <Typography textAlign={"center"}>XXXX</Typography>
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      <Typography variant="p" fontSize={12} color={"grey"}>
                        Chain
                      </Typography>
                      {accountDetails?.chain ? (
                        <Typography variant="p" fontSize={13}>
                          {accountDetails?.chain}
                        </Typography>
                      ) : (
                        <Typography
                          variant="p"
                          fontSize={13}
                          textAlign={"center"}
                        >
                          -
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Box>
              <Box display={"flex"} flexDirection={"row-reverse"}>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    borderColor: "#462A9C",
                    color: "#462A9C",
                    textTransform: "none",
                    fontFamily: "PPMori-Regular",
                    textWrap: "nowrap",
                  }}
                  startIcon={<SaveAltOutlinedIcon />}
                  endIcon={<KeyboardArrowDownOutlinedIcon />}
                  onClick={handleOpenDownloadDocuments}
                >
                  Download Documents
                </Button> */}
                <PopoverList
                  open={Boolean(downloadDocumentsEle)}
                  anchorEl={downloadDocumentsEle}
                  handleClose={handleCloseDownloadDocuments}
                  options={downloadDocumentsOptions}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                />
              </Box>
            </Paper>
          </Box>
          <Box padding={3} flexGrow={1} overflow={"hidden"}>
            <Paper
              variant="outlined"
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                padding={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="p">Recent Transactions</Typography>
                <Button
                  variant="text"
                  style={{
                    backgroundColor: "white",
                    color: "#462A9C",
                    fontSize: "12px",
                    fontFamily: "PPMori-Regular",
                  }}
                  onClick={() => navigate('/transactions')}
                >
                  See all
                </Button>
              </Box>
              <AccountTransactionTable
                rows={transactonList}
                loading={isLoading}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AccountDetails;
