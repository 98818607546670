const sever_ENV = {
    local: {
    API_URL: "http://localhost",
  },

  dev: {
    API_URL: "{{endl-engine}}",
  },
}
module.exports = sever_ENV;
