import React, { useMemo, useState, useCallback } from "react";
import SignUp, { Success } from "../../pages/LoginAndSignUp/Signup";
import { register, saveCompanyInfo } from "../../lib/service/authApi/authApi";
import { notify } from "../../components/Notification/Notification";
import { sendOTP, verifyOTP } from "../../lib/service/otpAPi/otpApi";
import { handleErrorResponse } from "../../lib/service/AuthToken";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
const INITIAL_FORM_DATA = {
  userType: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  companyName: "",
  phone: "",
  //dob: dayjs("2001-05-26"),
  country: "",
  currency: "",
  logo: "",
  isCertified: ""
};

const INITIAL_FORM_ERRORS = {
  firstName: null,
  lastName: null,
  email: null,
  password: null,
  companyName: null,
  phone: null,
  // dob: null,
  country: null,
  currency: null,
  logo: null,
};
const SignUpContainer = () => {
  const [formData, setFormData] = useState({ ...INITIAL_FORM_DATA });
  const [formErrors, setFormErrors] = useState({
    ...INITIAL_FORM_ERRORS,
  });
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [isVerifyingOTP, setIsVerifyingOTP] = useState();
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [formCurrStep, setCurrFormStep] = useState(0);
  const [updatingCompanyInfo, setUpdatingCompanyInfo] = useState(false);
  const navigate = useNavigate();
  const handleInput = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: null,
    }));
  };
  const handleShowDetailsForm = () => {
    setShowDetailsForm(true);
  };
  const individualFormSteps = useMemo(
    () => ({
      stepsLabel: ["Create account", "Confirm email"],
      stepsHeading: ["Let's get started", "Email verification"],
    }),
    []
  );
  const businessAccountForm = useMemo(
    () => ({
      stepsLabel: ["Create account", "Company Information", "Confirm email"],

      stepsHeading: [
        "Let's get started",
        "Let's get started",
        "",
      ],
    }),
    []
  );

  const changeAccountType = (type) => {
    console.log(type);
    setFormData({ ...INITIAL_FORM_DATA, userType: type });
    setFormErrors({ ...INITIAL_FORM_ERRORS });
  };

  // Validation logic can be changed also when the API is implemented
  const isValidInput = () => {
    let isValid = true;
    const errors = { ...formErrors };
    // general for ALL - BUSINESS Account and INDIVIDUAL Account
    // At first step
    if (formCurrStep === 0) {
      if (!formData.firstName || formData.firstName === "") {
        errors.firstName = "First name is required";
        isValid = false;
      }
      if (!formData.lastName || formData.lastName === "") {
        errors.lastName = "Last name is required";
        isValid = false;
      }
      if (!formData.email || formData.email === "") {
        errors.email =
          formData.userType === "INDIVIDUAL"
            ? "Email is required"
            : "Company email is required";
        isValid = false;
      } else if (
        !formData.email.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        formData.userType = "Provide a valid email";
      }
      if (!formData.password || formData.password === "") {
        errors.password = "Password is required";
        isValid = false;
      } 
      // if (!formData.phone || formData.phone === "") {
      //   errors.phone = "Phone number is required";
      //   isValid = false;
      // }
      // if (!formData.dob || formData.dob === "") {
      //   errors.dob = "Date of birth is required";
      //   isValid = false;
      // }
      // if (!formData.country || formData.country === "") {
      //   errors.country = "Country is required";
      //   isValid = false;
      // }
      // if (!formData.currency || formData.currency === "") {
      //   errors.currency = "Currency is required";
      //   isValid = false;
      // }
      if(!formData.isCertified ){
        errors.isCertified = "Let's verify the terms and condition then proceed";
        isValid = false;
      }
    }
    // Business specific validation
    if (formData.userType === "BUSINESS") {
      // for 2nd step business account
      if (formCurrStep === 1) {
        if (!formData.companyName || formData.companyName === "") {
          errors.companyName = "Company name is required";
          isValid = false;
        }
      }
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleNext = async (e) => {
    e.preventDefault();
    if (!isValidInput()) return;
    // general step for both user Type - INDIVIDUAL / BUSINESS - Account creation
    if (formCurrStep === 0) {
      try {
        setIsCreatingAccount(true);
        const { userType, email, firstName, lastName, phone, password, dob } =
          formData;
        const res = await register({
          userType,
          email,
          firstName,
          lastName,
          phone,
          password,
          // dob: dob.format("YYYY-MM-DD"),
          country: formData.country.name,
          countryId: formData.country.id,
          currency: formData.currency.code,
          currencyId: formData.currency.id,
        });
        notify.success({
          message: "Successfully created account",
        });
        setCurrFormStep((prev) => prev + 1);
        // Will send the otp at first step -> if user is Individual
        if (formData.userType === "INDIVIDUAL") {
          await handleSendOTP();
        }
      } catch (e) {
        if(!e.data.emailVerified){
          setCurrFormStep((prev) => prev + 1);
          if (formData.userType === "INDIVIDUAL") {
            await handleSendOTP();
          }
        }
        if (e?.errors?.length > 0) {
          e.errors.map((err) =>
            notify.error({
              message: err.errMsg,
              description: err.errDesc,
            })
          );
        } else {
          notify.error({
            message: "Failed to create account.",
            description:
              "We can not proceed the request for account opening. Please try again later.",
          });
        }
      } finally {
        setIsCreatingAccount(false);
      }
    }

    if (formCurrStep === 1) {
      if (formData.userType === "BUSINESS") {
        const form = new FormData();
        if (formData.logo) form.append("logo", formData.logo);
        form.append("email", formData.email);
        form.append("companyName", formData.email);
        try {
          setUpdatingCompanyInfo(true);
          const res = await saveCompanyInfo(form);
          await handleSendOTP();
          setCurrFormStep((prev) => prev + 1);
        } catch (e) {
          if (e?.errors?.length > 0) {
            e.errors.map((err) =>
              notify.error({
                message: err.errMsg,
                description: err.errDesc,
              })
            );
          } else {
            notify.error({
              message: "Failed to create account.",
              description:
                "We can not proceed the request for account opening. Please try again later.",
            });
          }
        } finally {
          setUpdatingCompanyInfo(false);
        }
      }
    }
  };
  const handleSendOTP = async () => {
    try {
      await sendOTP({ email: formData.email, phone: null, otpMode: 'EMAIL', operationType: 'REGISTRATION' });
      notify.success({
        message: "OTP has been sent successfully",
      });
    } catch (e) {
      if (e?.errors?.length > 0) {
        e.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to send the OTP.",
          description:
            "We can not proceed the request for verification of email. Please try again later.",
        });
      }
    }
  };
  const handleVerifyOTP = async (otp) => {
    try {
      setIsVerifyingOTP(true);
      await verifyOTP({
        otp: otp,
        email: formData.email,
        phone: null,
        otpMode: 'EMAIL'
      });
      setEmailVerified(true);
    } catch (e) {
      setEmailVerified(false);

      if (e?.errors?.length > 0) {
        e.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to verify the OTP.",
          description:
            "We can not proceed the request for email verification. Please try again later",
        });
      }
    } finally {
      setIsVerifyingOTP(false);
    }
  };
  const handleLogin = () => {
    navigate("/login");
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    setCurrFormStep((prev) => prev - 1);
  };

  if (emailVerified) {
    return <Success handleLogin={handleLogin} currentFormStep={formData.userType === "INDIVIDUAL" ? 2 : 3} formSteps={
      formData.userType
        ? formData.userType === "INDIVIDUAL"
          ? individualFormSteps
          : businessAccountForm
        : []
    } showDetailsForm={showDetailsForm}/>;
  }

  return (
    <SignUp
      handleGoBack={handleGoBack}
      formData={formData}
      formErrors={formErrors}
      handleInput={handleInput}
      showDetailsForm={showDetailsForm}
      handleShowDetailsForm={handleShowDetailsForm}
      currentFormStep={formCurrStep}
      handleNext={handleNext}
      changeAccountType={changeAccountType}
      otpLength={6}
      otpExpirationTimer={2 * 60}
      handleResendOTP={handleSendOTP}
      handleVerifyOTP={handleVerifyOTP}
      isVerifyingOTP={isVerifyingOTP}
      isCreatingAccount={isCreatingAccount}
      updatingCompanyInfo={updatingCompanyInfo}
      formSteps={
        formData.userType
          ? formData.userType === "INDIVIDUAL"
            ? individualFormSteps
            : businessAccountForm
          : []
      }
    />
  );
};

export default SignUpContainer;
