export const getCombinedAddress = (address) => {
  const { addressLine1, addressLine2, city, state, country, postalCode } =
    address;
  const combinedAddress = [
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postalCode,
  ]
    .filter((value) => value) // Filter out null or undefined values
    .join(", ");
  if (combinedAddress.length === 0) {
    return "-";
  }
  return combinedAddress;
};

export const bankRoutingCodeLabel = {
  // Canada
  40: "Routing number (institution number + transit number)",
  // USA
  236: "Routing number",

  // Singapore
  200: "SWIFT/BIC code",

  // India
  103: "IFSC code",
  // EU region specific
  EU_REGION: "SWIFT/BIC code",
};

export const getRecipientFullName = (firstName, lastName) => {
  return !firstName && firstName === "" && !lastName && lastName === ""
    ? "-"
    : `${firstName} ${lastName}`;
};

export const relationshipToRecipientMap = {
  SPOUSE: "Spouse",
  EX_SPOUSE: "Ex-Spouse",
  CHILDREN: "Children",
  PARENT: "Parent",
  SIBLING: "Sibling",
  RELATIVE: "Relative",
  SELF: "Self",
  FRIEND: "Friend",
  BUSINESS_PARTNER: "Business Partner",
  CUSTOMER: "Customer",
  EMPLOYEE: "Employee",
  BRANCH_OFFICE: "Branch Office",
  SUBSIDIARY_COMPANY: "Subsidiary Company",
  HOLDING_COMPANY: "Holding Company",
  SUPPLIER: "Supplier",
  CREDITOR: "Creditor",
  DEBTOR: "Debtor",
  FRANCHISEE: "Franchisee",
  NOT_RELATED: "Not Related",
};

export const bankRoutingCodeLabelAndTooltip = {
  // Canada
  40: {
    tooltip:
      "Needs institution number (3 digit number) & transit number (5 digit number) which are unique for the destination bank. Combine them (institution number + transit number) and provide into routing number. If  004 is institution number and 00012 is transit number, put like this 00400012",
    label: "Routing number",
    placeholder: "Enter routing number",
  },

  // USA
  236: {
    label: "Routing number",
    placeholder: "Enter routing number",
    tooltip: "Routing number is 9 character long code.",
  },
  // Singapore
  200: {
    label: "SWIFT/BIC code",
    placeholder: "Enter SWIFT/BIC code",
  },
  // India
  103: {
    label: "IFSC code",
    placeholder: "Enter IFSC code",
  },
  // EU region specific
  EU_REGION: {
    label: "SWIFT/BIC code",
    placeholder: "Enter SWIFT/BIC code",
  },
};

// Note:- Commented for testing purpose
// export const isValidRecipientBankFormData = (bankFormData, bankFormErrors) => {
//   let isValid = true;
//   const errors = { ...bankFormErrors };

//   if (!bankFormData.type || bankFormData.type.trim() === "") {
//     errors.type = "Recipient type is required";
//     isValid = false;
//   }

//   if (!bankFormData.alias || bankFormData.alias.trim() === "") {
//     errors.alias = "Alias (Nickname) is required";
//   }

//   if (!bankFormData.country) {
//     errors.country = "Country is required";
//     isValid = false;
//   }

//   if (!bankFormData.currency) {
//     errors.currency = "Currency is required";
//     isValid = false;
//   }

//   if (!bankFormData.email || bankFormData.email.trim() === "") {
//     errors.email = "Email is required";
//     isValid = false;
//   }

//   if (!bankFormData.firstName || bankFormData.firstName.trim() === "") {
//     errors.firstName = "First name is required";
//     isValid = false;
//   }

//   if (!bankFormData.lastName || bankFormData.lastName.trim() === "") {
//     errors.lastName = "Last name is required";
//     isValid = false;
//   }

//   if (!bankFormData.addressLine1 || bankFormData.addressLine1.trim() === "") {
//     errors.addressLine1 = "Address Line 1 is required";
//     isValid = false;
//   }

//   if (!bankFormData.city || bankFormData.city.trim() === "") {
//     errors.city = "City is required";
//     isValid = false;
//   }

//   if (!bankFormData.state || bankFormData.state.trim() === "") {
//     errors.state = "State is required";
//     isValid = false;
//   } else if (
//     bankFormData.country.id === 236 &&
//     bankFormData.state.trim().length > 2 // USA based validation
//   ) {
//     errors.state =
//       "US state should be in the form of 2 letter abbreviations, For eg. California means CA.";
//     isValid = false;
//   }

//   if (!bankFormData.postalCode || bankFormData.postalCode.trim() === "") {
//     errors.postalCode = "Postal code is required";
//     isValid = false;
//   }

//   if (
//     !bankFormData.dateOfBirth ||
//     bankFormData.dateOfBirth.format("DD-MM-YYYY").trim() === ""
//   ) {
//     errors.dateOfBirth = "Date of birth is required";
//     isValid = false;
//   }

//   if (!bankFormData.phone || bankFormData.phone.trim() === "") {
//     errors.phone = "Phone number is required";
//     isValid = false;
//   }

//   if (
//     !bankFormData.relationshipToCustomer ||
//     bankFormData.relationshipToCustomer.trim() === ""
//   ) {
//     errors.relationshipToCustomer = "Relationship to recipient is required";
//     isValid = false;
//   }

//   if (bankFormData.country) {
//     if (!bankFormData.bankName || bankFormData.bankName.trim() === "") {
//       errors.bankName = "Bank name is required"
//     }
//     switch (bankFormData.country.id) {
//       // India
//       case 103:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (!bankFormData.ifsc || bankFormData.ifsc.trim() === "") {
//           errors.ifsc = "IFSC is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;
//       // China, Singapore, Japan, Malaysia, Panama
//       case 45:
//       case 200:
//       case 112:
//       case 134:
//       case 170:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // Canada
//       case 40:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.routingNumber ||
//           bankFormData.routingNumber.trim() === ""
//         ) {
//           errors.routingNumber = "Routing Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.accountType ||
//           bankFormData.accountType.trim() === ""
//         ) {
//           errors.accountType = "Account Type is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // US
//       case 236:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.routingNumber ||
//           bankFormData.routingNumber.trim() === ""
//         ) {
//           errors.routingNumber = "Routing Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.accountType ||
//           bankFormData.accountType.trim() === ""
//         ) {
//           errors.accountType = "Account Type is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // Bahamas
//       case 16:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (
//           !bankFormData.accountType ||
//           bankFormData.accountType.trim() === ""
//         ) {
//           errors.accountType = "Account Type is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // Great Britain
//       case 234:
//         if (
//           !bankFormData.bankAccountNumber ||
//           bankFormData.bankAccountNumber.trim() === ""
//         ) {
//           errors.bankAccountNumber = "Account Number is required";
//           isValid = false;
//         }
//         if (!bankFormData.iban || bankFormData.iban.trim() === "") {
//           errors.iban = "IBAN is required";
//           isValid = false;
//         }
//         if (!bankFormData.sortCode || bankFormData.sortCode.trim() === "") {
//           errors.sortCode = "Sort Code is required";
//           isValid = false;
//         }
//         if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//           errors.swiftBic = "Swift BIC is required";
//           isValid = false;
//         }
//         break;

//       // EU region
//       default:
//         if (bankFormData.country.region !== null && bankFormData.country.region === "EU") {
//           if (!bankFormData.iban || bankFormData.iban.trim() === "") {
//             errors.iban = "IBAN is required";
//             isValid = false;
//           }
//           if (!bankFormData.swiftBic || bankFormData.swiftBic.trim() === "") {
//             errors.swiftBic = "Swift BIC is required";
//             isValid = false;
//           }
//         }
//         break;
//     }
//   }
//   console.log(errors);
//   return { isValid, errors }
// };

export const isValidRecipientBankFormData = (bankFormData, bankFormErrors) => {
  let isValid = true;
  const errors = { ...bankFormErrors };

  const regexPatterns = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    postalCode: /^[A-Za-z0-9\s-]+$/,
    phone: /^\+?[0-9]{7,15}$/,
    ifsc: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    swiftBic: /^[A-Z0-9]{8,11}$/,
    iban: /^[A-Z0-9]{15,34}$/,
    usState: /^[A-Z]{2}$/,
    routingNumber: /^[0-9]{9}$/,
    name: /^[A-Za-z\s]+$/,  // Only letters and spaces are allowed
  };

  if (!bankFormData.type || bankFormData.type.trim() === "") {
    errors.type = "Recipient type is required";
    isValid = false;
  }

  if (!bankFormData.alias || bankFormData.alias.trim() === "") {
    errors.alias = "Alias (Nickname) is required";
    isValid = false;
  }

  if (!bankFormData.country) {
    errors.country = "Country is required";
    isValid = false;
  }

  if (!bankFormData.currency) {
    errors.currency = "Currency is required";
    isValid = false;
  }

  if (!bankFormData.email || !regexPatterns.email.test(bankFormData.email)) {
    errors.email = "Valid email is required";
    isValid = false;
  }

  if (!bankFormData.firstName || !regexPatterns.name.test(bankFormData.firstName.trim())) {
    errors.firstName = "First name can only contain letters and spaces";
    isValid = false;
  }

  if (!bankFormData.lastName || !regexPatterns.name.test(bankFormData.lastName.trim())) {
    errors.lastName = "Last name can only contain letters and spaces";
    isValid = false;
  }

  if (!bankFormData.addressLine1 || bankFormData.addressLine1.trim() === "") {
    errors.addressLine1 = "Address Line 1 is required";
    isValid = false;
  }

  if (!bankFormData.city || bankFormData.city.trim() === "") {
    errors.city = "City is required";
    isValid = false;
  }

  if (bankFormData.city && !regexPatterns.name.test(bankFormData.city.trim())) {
    errors.city = "City can only contain letters and spaces";
    isValid = false;
  }

  if (!bankFormData.state || bankFormData.state.trim() === "") {
    errors.state = "State is required";
    isValid = false;
  } else if (
    bankFormData.country &&
    bankFormData.country.id === 236 &&
    !regexPatterns.usState.test(bankFormData.state.trim()) // USA based validation
  ) {
    errors.state =
      "US state should be in the form of 2 letter abbreviations, For example, California means CA.";
    isValid = false;
  }

  if (bankFormData.state && !regexPatterns.name.test(bankFormData.state.trim())) {
    errors.state = "State can only contain letters and spaces";
    isValid = false;
  }

  if (!bankFormData.postalCode || !regexPatterns.postalCode.test(bankFormData.postalCode.trim())) {
    errors.postalCode = "Valid postal code is required";
    isValid = false;
  }

  if (
    !bankFormData.dateOfBirth ||
    bankFormData.dateOfBirth.format("DD-MM-YYYY").trim() === ""
  ) {
    errors.dateOfBirth = "Date of birth is required";
    isValid = false;
  }

  if (!bankFormData.phone || !regexPatterns.phone.test(bankFormData.phone.trim())) {
    errors.phone = "Valid phone number is required";
    isValid = false;
  }

  if (
    !bankFormData.relationshipToCustomer ||
    bankFormData.relationshipToCustomer.trim() === ""
  ) {
    errors.relationshipToCustomer = "Relationship to recipient is required";
    isValid = false;
  }

  if (bankFormData.country) {
    if (!bankFormData.bankName || !regexPatterns.name.test(bankFormData.bankName.trim())) {
      errors.bankName = "Bank name can only contain letters and spaces";
      isValid = false;
    }
    switch (bankFormData.country.id) {
      // India
      case 103:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (!bankFormData.ifsc || !regexPatterns.ifsc.test(bankFormData.ifsc.trim())) {
          errors.ifsc = "Valid IFSC is required";
          isValid = false;
        }
        if (!bankFormData.swiftBic || !regexPatterns.swiftBic.test(bankFormData.swiftBic.trim())) {
          errors.swiftBic = "Valid Swift BIC is required";
          isValid = false;
        }
        break;
      // China, Singapore, Japan, Malaysia, Panama
      case 45:
      case 200:
      case 112:
      case 134:
      case 170:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (!bankFormData.swiftBic || !regexPatterns.swiftBic.test(bankFormData.swiftBic.trim())) {
          errors.swiftBic = "Valid Swift BIC is required";
          isValid = false;
        }
        break;

      // Canada
      case 40:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (
          !bankFormData.bankRoutingNumber
        ) {
          errors.bankRoutingNumber = "Routing Number is required";
          isValid = false;
        }
        if (
          !bankFormData.accountType ||
          bankFormData.accountType.trim() === ""
        ) {
          errors.accountType = "Account Type is required";
          isValid = false;
        }
        if (!bankFormData.swiftBic || !regexPatterns.swiftBic.test(bankFormData.swiftBic.trim())) {
          errors.swiftBic = "Valid Swift BIC is required";
          isValid = false;
        }
        break;

      // US
      case 236:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (
          !bankFormData.bankRoutingNumber
        ) {
          errors.bankRoutingNumber = "Routing Number is required";
          isValid = false;
        }
        if (
          !bankFormData.accountType ||
          bankFormData.accountType.trim() === ""
        ) {
          errors.accountType = "Account Type is required";
          isValid = false;
        }
        if (!bankFormData.swiftBic || !regexPatterns.swiftBic.test(bankFormData.swiftBic.trim())) {
          errors.swiftBic = "Valid Swift BIC is required";
          isValid = false;
        }
        break;

      // Bahamas
      case 16:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (
          !bankFormData.accountType ||
          bankFormData.accountType.trim() === ""
        ) {
          errors.accountType = "Account Type is required";
          isValid = false;
        }
        if (!bankFormData.swiftBic || !regexPatterns.swiftBic.test(bankFormData.swiftBic.trim())) {
          errors.swiftBic = "Valid Swift BIC is required";
          isValid = false;
        }
        break;

      // Great Britain
      case 234:
        if (
          !bankFormData.bankAccountNumber ||
          bankFormData.bankAccountNumber.trim() === ""
        ) {
          errors.bankAccountNumber = "Account Number is required";
          isValid = false;
        }
        if (!bankFormData.iban) {
          errors.iban = "IBAN number is required";
          isValid = false;
        }
        if (!bankFormData.sortCode || bankFormData.sortCode.trim() === "") {
          errors.sortCode = "Sort Code is required";
          isValid = false;
        }
        if (!bankFormData.swiftBic || !regexPatterns.swiftBic.test(bankFormData.swiftBic.trim())) {
          errors.swiftBic = "Valid Swift BIC is required";
          isValid = false;
        }
        break;

      // EU region
      default:
        if (bankFormData.country.region !== null && bankFormData.country.region === "EU") {
          if (!bankFormData.iban) {
            errors.iban = "IBAN number is required";
            isValid = false;
          }
          if (!bankFormData.swiftBic || !regexPatterns.swiftBic.test(bankFormData.swiftBic.trim())) {
            errors.swiftBic = "Valid Swift BIC is required";
            isValid = false;
          }
        }
        break;
    }
  }
  console.log(errors);
  return { isValid, errors };
};


export function prepareBankRecipientData(recipientData) {
  let countrySpecificData = {};
  const commonData = {
    bankName: recipientData.bankName
  }
  switch (recipientData.country.id) {
    // India
    case 103:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        ifsc: recipientData.ifsc ?? null,
        swiftBic: recipientData.swiftBic ?? null,
      };
      break;

    // China, Singapore, Japan, Malaysia, Panama
    case 45:
    case 200:
    case 112:
    case 134:
    case 170:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        swiftBic: recipientData.swiftBic ?? null,
      };
      break;

    // Canada
    case 40:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        bankRoutingNumber: recipientData.bankRoutingNumber ?? null,
        accountType: recipientData.accountType ?? null,
        swiftBic: recipientData.swiftBic ?? null,
      };
      break;

    // US
    case 236:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        bankRoutingNumber: recipientData.bankRoutingNumber ?? null,
        accountType: recipientData.accountType ?? null,
        swiftBic: recipientData.swiftBic ?? null,
      };
      break;

    // Bahamas
    case 16:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        accountType: recipientData.accountType ?? null,
        swiftBic: recipientData.swiftBic ?? null,
      };
      break;

    // Great Britain
    case 234:
      countrySpecificData = {
        bankAccountNumber: recipientData.bankAccountNumber ?? null,
        iban: recipientData.iban ?? null,
        sortCode: recipientData.sortCode ?? null,
        swiftBic: recipientData.swiftBic ?? null,
      };
      break;

    // EU region
    default:
      if (recipientData.country.region !== null && recipientData.country.region === "EU") {
        countrySpecificData = {
          iban: recipientData.iban ?? null,
          swiftBic: recipientData.swiftBic ?? null,
        };
      }
      break;
  }

  return { ...countrySpecificData, ...commonData };
}
