import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  OutlinedInput,
  IconButton,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TermsOfUseModal from '../../container/TermsOfUse/TermsAndCondition';
import PrivacyModal from '../../container/PrivacyComponent/PrivacyComponent';
import Checkbox from "../Common/Checkbox";

const SignUpCreateAccountPersonalForm = ({
  formData,
  formErrors,
  handleInput,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setTermsModalOpen] = useState(false);
  // const [countryList, setCountryList] = useState([]);
  // const [currencyList, setCurrencyList] = useState([]);
  // const [currencyLoading, setCurrencyLoading] = useState(false);
  // const [countryLoading, setCountryLoading] = useState(false);
  // useEffect(() => {
  //   const getAllCountry = async () => {
  //     try {
  //       setCountryLoading(true);
  //       let data ={
  //         opertionType : 'REGISTRATION'
  //       }
  //       const res = await getCountry(data);
  //       setCountryList(res.data.countries);
  //     } catch (e) {
  //       console.log(e);
  //     } finally {
  //       setCountryLoading(false);
  //     }
  //   };

  //   const getAllCurrency = async () => {
  //     try {
  //       setCurrencyLoading(true);
  //       const res = await getCurrency({});
  //       setCurrencyList(res.data.source.currency);
  //     } catch (e) {
  //       console.log(e);
  //     } finally {
  //       setCurrencyLoading(false);
  //     }
  //   };

  //   getAllCurrency();
  //   getAllCountry();
  // }, []);

  const handleOpenPrivacyModal = () => {
    setPrivacyModalOpen(true);
  };

  const handleClosePrivacyModal = () => {
    setPrivacyModalOpen(false);
  };

  const handleOpenTermsModal = () => {
    setTermsModalOpen(true);
  };

  const handleCloseTermsModal = () => {
    setTermsModalOpen(false);
  };


  const sanitizeInput = (value) => value.replace(/\s+/g, ' ').trim();

  const handleSanitizedInput = (field, value) => {
    const sanitizedValue = sanitizeInput(value);
    handleInput(field, sanitizedValue);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      {/* <Box width={"100%"}>
        <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
          Select Nationality <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          select
          id="recipient-option"
          value={formData.country.id ? formData.country.id : "select"}
          onChange={(e) =>
            handleInput(
              "country",
              countryList.find((country) => country.id == e.target.value)
            )
          }
          name="country"
          sx={{
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "11.5px 14px",
            },
          }}
          error={!!formErrors.country}
          fullWidth
          SelectProps={{
            style: { height: "40px" },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 6,
              },
            },
          }}
        >
          <MenuItem
            sx={{ display: countryList.length == 0 ? "none" : "block" }}
            disabled
            value="select"
          >
            Select
          </MenuItem>
          {countryList.length > 0 ? (
            countryList.map((country) => (
              <MenuItem
                key={country.id}
                value={country.id}
                style={{
                  marginBottom: ".5rem",
                  marginTop: ".3rem",
                  minWidth: 250,
                }}
              >
                {country.name}
              </MenuItem>
            ))
          ) : countryLoading ? (
            <Typography
              textAlign={"center"}
              color={"black"}
              fontWeight={"bold"}
            >
              Loading...
            </Typography>
          ) : (
            <Typography
              textAlign={"center"}
              color={"black"}
              fontWeight={"bold"}
            >
              Unable to fetch
            </Typography>
          )}
        </TextField>
        {formErrors.country && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.country}
          </Typography>
        )}
      </Box>
      <Box width={"100%"}>
        <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
          Select currency <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          select
          id="currency-option"
          value={
            formData.currency.code
              ? formData.currency.code
              : "select"
          }
          onChange={(e) =>
            handleInput(
              "currency",
              currencyList.find(
                (currency) => currency.code === e.target.value
              )
            )
          }
          name="currency"
          sx={{
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "11.5px 14px",
            },
          }}
          error={!!formErrors.currency}
          fullWidth
          SelectProps={{
            style: { height: "40px" },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 6,
              },
            },
          }}
        >
          <MenuItem
            sx={{ display: currencyList.length == 0 ? "none" : "block" }}
            disabled
            value="select"
          >
            Select
          </MenuItem>
          {currencyList.length > 0 ? (
            currencyList.map((currency) => (
              <MenuItem
                key={currency.code}
                value={currency.code}
                style={{
                  marginBottom: ".5rem",
                  marginTop: ".3rem",
                  minWidth: 250,
                }}
              >
                {currency.name} ({currency.code})
              </MenuItem>
            ))
          ) : currencyLoading ? (
            <Typography
              textAlign={"center"}
              color={"black"}
              fontWeight={"bold"}
            >
              Loading...
            </Typography>
          ) : (
            <Typography
              textAlign={"center"}
              color={"black"}
              fontWeight={"bold"}
            >
              Unable to fetch
            </Typography>
          )}
        </TextField>
        {formErrors.currency && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.currency}
          </Typography>
        )}
      </Box> */}
      <Box display={"flex"} width={"100%"} gap={1}>
        <Box width={"100%"}>
          <Typography fontSize={14} color={"rgba(0,0,0,0.8)"}>
            First Name <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            error={!!formErrors.firstName}
            variant="outlined"
            placeholder="Enter your first name"
            name="firstName"
            fullWidth
            value={formData.firstName}
            onChange={(e) => handleSanitizedInput("firstName", e.target.value)}
            sx={{
              "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                padding: "11.5px 14px",
              },
            }}
          />
          {formErrors.firstName && (
            <Typography fontSize={13} color={"red"}>
              {formErrors.firstName}
            </Typography>
          )}
        </Box>
        <Box width={"100%"}>
          <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
            Last Name <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            error={!!formErrors.lastName}
            variant="outlined"
            placeholder="Enter your last name"
            name="lastName"
            fullWidth
            value={formData.lastName}
            onChange={(e) => handleSanitizedInput("lastName", e.target.value)}
            sx={{
              "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                padding: "11.5px 14px",
              },
            }}
          />
          {formErrors.lastName && (
            <Typography fontSize={13} color={"red"}>
              {formErrors.lastName}
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <Typography fontSize={14} color={"rgba(0,0,0,0.8)"}>
          {formData.userType === "INDIVIDUAL" ? "Email" : "Company email"} <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          error={!!formErrors.email}
          variant="outlined"
          placeholder={formData.userType === "INDIVIDUAL" ? "Enter your email" : "Enter your company email"}
          name="email"
          fullWidth
          value={formData.email}
          onChange={(e) => handleSanitizedInput("email", e.target.value)}
          sx={{
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "11.5px 14px",
            },
          }}
        />
        {formErrors.email && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.email}
          </Typography>
        )}
      </Box>
      {/* <Box>
        <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
          Phone number <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          error={!!formErrors.phone}
          variant="outlined"
          placeholder="Enter your phone number"
          type="tel"
          name="phone"
          fullWidth
          value={formData.phone}
          onChange={(e) => handleSanitizedInput("phone", e.target.value)}
          sx={{
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "11.5px 14px",
            },
          }}
        />
        {formErrors.phone && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.phone}
          </Typography>
        )}
      </Box> */}

      <Box>
        <Typography fontSize={14} color={"rgba(0,0,0,0.8)"}>
          Password <span style={{ color: 'red' }}>*</span>
        </Typography>
        <OutlinedInput
          error={!!formErrors.password}
          variant="outlined"
          name="password"
          placeholder="Enter your password"
          fullWidth
          type={showPassword ? "text" : "password"}
          value={formData.password}
          onChange={(e) => handleSanitizedInput("password", e.target.value)}
          sx={{
            "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "11.5px 14px",
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {formErrors.password && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.password}
          </Typography>
        )}
      </Box>
      {/* <Box width={"100%"}>
        <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
          Date of birth
        </Typography>
        <LocalDatePicker
          value={formData.dob}
          onChange={(newDOB) => handleInput("dob", newDOB)}
          error={!!formErrors.dob}
        />
        {formErrors.dob && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.dob}
          </Typography>
        )}
      </Box> */}
     <Box width={"100%"}>
        <Checkbox
          checked={formData.isCertified}
          onChange={(e) => handleInput("isCertified", e.target.checked)}
          label={
            <Typography fontSize={14} color={"rgba(0,0,0,0.8)"}>
              I agree to the{" "}
              <span
                onClick={handleOpenTermsModal}
                style={{
                  color: "#0F0F0F",
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderBottom: "1px solid #000",
                }}
              >
                Terms & Conditions
              </span>{" "}
              and{" "}
              <span
                onClick={handleOpenPrivacyModal}
                style={{
                  color: "#0F0F0F",
                  fontWeight: "bold",
                  cursor: "pointer",
                  borderBottom: "1px solid #000",
                }}
              >
                Privacy Policy
              </span>
              .
            </Typography>
          }
        />
        {formErrors.isCertified && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.isCertified}
          </Typography>
        )}
      </Box>
      <TermsOfUseModal open={isTermsModalOpen} handleClose={handleCloseTermsModal} />
      <PrivacyModal open={isPrivacyModalOpen} handleClose={handleClosePrivacyModal} />
    </Box>
  );
};

export default SignUpCreateAccountPersonalForm;
