import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const SidebarSummery = ({
  title,
  showSummery,
  stepOneSummery,
  selectedTransferFrom,
  setSelectedTransferTo,
}) => {
  return (
    <Box
      sx={{ display: "flex" }}
      width={"100%"}
      display={"flex"}
      justifyContent={"center"}
    >
      <Box width={"50%"}>
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          fontSize="22px"
          fontFamily="PPMori-Semi-Bold"
          fontWeight={500}
        >
          {title}
        </Typography>
        {showSummery && (
          <Accordion expanded={true} elevation={0} sx={{ width: "250px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" fontSize="12px" fontWeight="bold">
                Summary
              </Typography>
            </AccordionSummary>
            <Divider sx={{ marginTop: "-20px" }} />
            {stepOneSummery && (
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography color={"rgba(0,0,0,0.50)"} fontSize={12}>
                      Payment method
                    </Typography>
                    <Typography
                      variant="h5"
                      textAlign={"right"}
                      fontSize={12}
                      fontWeight={600}
                    >
                      Local bank transfer
                    </Typography>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography color={"rgba(0,0,0,0.50)"} fontSize={12}>
                      From
                    </Typography>

                    <Typography
                      variant="h5"
                      textAlign={"right"}
                      fontSize={12}
                      fontWeight={600}
                    >
                      EURO Account ***409
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            )}
          </Accordion>
        )}
      </Box>
    </Box>
  );
};

export default SidebarSummery;
