import React from 'react';
import { Box, Grid,Typography, Skeleton } from '@mui/material';

const LoadingDetails = () => {
  return (
    <Box>
    {[
      "Exchange rate",
      "Exchange Markup Fee",
      "Variable fee",
      "Total fee",
      "Total amount to send",
      "Gas Fees",
      "Tax on Source",
      "On Off Ramp Fee"
    ].map((label, index) => (
      <Grid container key={index}>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            style={{ fontSize: "14px", fontFamily: "PPMori-Regular" }}
          >
            <Skeleton variant="text" width={80} height={20} />
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Skeleton variant="text" width={80} height={20} />
        </Grid>
      </Grid>
    ))}
  </Box>
  );
};

export default LoadingDetails;
