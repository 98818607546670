import React, { useState } from "react";
import { Box, Typography, Modal, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};
const images = importAll(
  require.context("../../Images/flags", false, /\.(png)$/)
);
const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  py: 4,
  px: 6,
};

const CloseAccountModal = ({
  open,
  onClose,
  hasMoney = true,
  transferModalOpen,
  accountDetails,
}) => {
  const [showTransferMoneyStep, setShowTransferMoneyStep] = useState(false);
  const onCloseHandler = () => {
    if (hasMoney) {
      setShowTransferMoneyStep(true);
    } else {
      // Any api calls for transferring the money
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          position={"relative"}
        >
          <IconButton
            sx={{ position: "absolute", right: 0, top: -5 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5">Close account</Typography>
          {!showTransferMoneyStep ? (
            <>
              <Typography variant="body1" fontSize={14}>
                Are you sure you want to close your account? This can't be
                undone.
              </Typography>
              <Box
                border={1}
                borderColor={"rgba(0,0,0,0.3)"}
                borderRadius={2}
                display={"flex"}
                gap={1}
                padding={1}
              >
                <Box
                  sx={{
                    padding: "3px 12px",
                    border: "1px solid #c3bebe",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      images[`${accountDetails?.currency?.toLowerCase()}.png`]
                    }
                  />
                </Box>
                <Box>
                  <Typography variant="body1" fontSize={14}>
                    {`${accountDetails?.currency} ${accountDetails?.balance}`}
                  </Typography>
                  <Typography variant="body1" fontSize={12}>
                    {accountDetails?.name}
                  </Typography>
                </Box>
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"flex-end"}
                gap={5}
              >
                <Button
                  variant="text"
                  color="primary"
                  sx={{
                    color: "#000",
                    fontSize: 14,
                    textTransform: "none",
                    py: 1,
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "red",
                    color: "#ffffff",
                    fontSize: 14,
                    textTransform: "none",
                    textWrap: "nowrap",
                    py: 1,
                  }}
                  onClick={onCloseHandler}
                >
                  Close account
                </Button>
              </Box>{" "}
            </>
          ) : (
            <>
              <Typography>
                Before closing your account, please ensure few necessary steps
                are completed. You can return to this page once you have
                finished.
              </Typography>
              <ul>
                <li>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography variant="body1" fontWeight={600} fontSize={14}>
                      Empty your account balance
                    </Typography>
                    <Typography variant="body1" fontSize={12}>
                      Before closing your account, you should empty your balance
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography variant="body1" fontWeight={600} fontSize={14}>
                      Complete pending transfer
                    </Typography>
                    <Typography variant="body1" fontSize={12}>
                      Make sure to wait for pending transfer to complete before
                      closing the account
                    </Typography>
                  </Box>
                </li>
              </ul>
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"flex-end"}
                gap={5}
              >
                <Button
                  variant="text"
                  color="primary"
                  sx={{
                    color: "#462A9C",
                    fontSize: "12px",
                    textTransform: "none",
                    py: 1,
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: "#462A9C",
                    color: "#ffffff",
                    fontSize: "12px",
                    textTransform: "none",
                    py: 1,
                  }}
                  onClick={() => {
                    setShowTransferMoneyStep(false);
                    transferModalOpen(true);
                  }}
                >
                  Transfer funds to another account
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CloseAccountModal;
