import * as React from "react";
import { MenuItem, FormControl } from "@mui/material";
import { useState, useEffect } from "react";
import { ReactComponent as calender } from "../../Images/calendar.svg";
import DateCalendarValue from "../Calender/NewCalender";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import MenuList from "@mui/material/MenuList";
import Popover from "@mui/material/Popover";
import DateRangeIcon from '@mui/icons-material/DateRange';

export function PastDays({ rangeDate, setRangeDate, fromTransactionModule = false }) {
  const [pastDays, setPastDays] = useState("ALL_TIME");
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempDateRange, setTempDateRange] = useState("ALL_TIME");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(()=>{
    if(!rangeDate) {
      setPastDays("ALL_TIME")
    } else {
      let currentCase = pastDays;

      if (fromTransactionModule && pastDays === "PAST_7_DAYS") {
        currentCase = "LAST_7_DAYS";
      }
      switch (currentCase) {
        case "RESET":
          setTempDateRange("ALL_TIME");
          break;
        case "ALL_TIME":
          setTempDateRange(rangeDate);
          break;
        case "YESTERDAY":
          setTempDateRange([dayjs().subtract(1, "day"),dayjs().subtract(1, "day")]);
          break;
        case "PAST_7_DAYS":
          setTempDateRange([dayjs().subtract(7, "day"), dayjs()])
          break;
        case "LAST_7_DAYS":
          setTempDateRange([dayjs().subtract(7, "day"), dayjs()])
          break;
        case "PAST_30_DAYS":
          setTempDateRange([dayjs().subtract(30, "day"), dayjs()]);
          break;
        case "PAST_90_DAYS":
          setTempDateRange([dayjs().subtract(90, "day"), dayjs()]);
          break;
        default:
          setTempDateRange(null);
          break;
      }
    }
  },[rangeDate])

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDayRange = (value) => {
    switch (value) {
        case "RESET":
          setPastDays("ALL_TIME");
          setRangeDate("ALL_TIME");
          break;
        case "ALL_TIME":
          setPastDays("ALL_TIME");
          setRangeDate(fromTransactionModule ? value : null);
          break;
        case "YESTERDAY":
          setPastDays("YESTERDAY");
          setRangeDate(fromTransactionModule ? "YESTERDAY" : [dayjs().subtract(1, "day"),dayjs().subtract(1, "day")]);
          break;
        case "PAST_7_DAYS":
          setPastDays("PAST_7_DAYS");
          setRangeDate(fromTransactionModule ? "LAST_7_DAYS" : [dayjs().subtract(7, "day"), dayjs()]);
          break;
        case "PAST_30_DAYS":
          setPastDays("PAST_30_DAYS");
          setRangeDate([dayjs().subtract(30, "day"), dayjs()]);
          break;
        case "PAST_90_DAYS":
          setPastDays("PAST_90_DAYS");
          setRangeDate([dayjs().subtract(90, "day"), dayjs()]);
          break;
        default:
          setRangeDate(null);
          break;
      }
    handleClose();
  };

  const daySetHandler = (newValue) => {
    setPastDays(undefined);
    setRangeDate([newValue,newValue]);
    handleClose();
  };

  const getLabel = (value) => {
    switch (value) {
      case "ALL_TIME":
        return "All time";
      case "YESTERDAY":
        return "Yesterday";
      case "PAST_7_DAYS":
        return "Past 7 days";
      case "PAST_30_DAYS":
        return "Past 30 days";
      case "PAST_90_DAYS":
        return "Past 90 days";
      default:
        return value.format("DD/MM/YYYY");
    }
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="outlined"
        size="large"
        onClick={handleClick}
        style={{
          padding: "6px 15px",
          borderColor: "rgba(0,0,0,0.26)",
          color: "black",
          textTransform:"none",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <DateRangeIcon fontSize="small" sx={{ color: "#707070", marginRight: "10px" }} />
        {pastDays ? getLabel(pastDays) : rangeDate && rangeDate[0]?.format("DD/MM/YYYY")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FormControl
          sx={{ m: 1, minWidth: 150, display: "flex", gap: "20px" }}
          size="small"
        >
          <MenuList>
            <MenuItem
              onClick={() => handleDayRange("ALL_TIME")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular" }}
            >
              All time
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("YESTERDAY")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular" }}
            >
              Yesterday
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("PAST_7_DAYS")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular" }}
            >
              Past 7 days
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("PAST_30_DAYS")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular" }}
            >
              Past 30 days
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("PAST_90_DAYS")}
              style={{ width: "8rem", fontFamily: "PPMori-Regular" }}
            >
              Past 90 days
            </MenuItem>
            <MenuItem
              onClick={() => handleDayRange("RESET")}
              style={{
                width: "8rem",
                marginTop: "3.5rem",
                fontFamily: "PPMori-Regular",
              }}
            >
              Reset
            </MenuItem>
          </MenuList>
          <div className="datevalue">
            <DateCalendarValue
              rangeDate={tempDateRange}
              onDateSelect={daySetHandler}
            />
          </div>
        </FormControl>
      </Popover>
    </div>
  );
}
