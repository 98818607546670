import axios from 'axios'
import { apiUrl } from '../../configs/ApiConfig'
// import sever_ENV from '../../env'

const directService = async (options, cancelReq = false) => {
  let cancelToken

  const client = axios.create({
    baseURL: `${apiUrl}`,
    validateStatus: (status) => status >= 200 && status < 300,
  })

  if (cancelReq) {
    cancelToken = axios.CancelToken.source()
  }

  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel('Operation canceled due to new request.')
  }

  const onSuccess = (response) => Promise.resolve(response.data)

  const onError = (error) => {
    const servicerResponse =
      error?.response?.data
    console.log(servicerResponse, 'servicerResponseservicerResponse')
    if (servicerResponse && typeof servicerResponse !== 'undefined')
      return Promise.reject(servicerResponse)

    return Promise.reject(error)
  }

  try {
    let response
    const headers = { ...options.headers };

    if (cancelReq) {
      response = await client({
        ...options,
        headers: {
          ...headers,
        },
        cancelToken: cancelToken.token,
      })
    } else {
      response = await client({
        ...options,
        headers: {
          ...headers,
        },
      })
    }

    return onSuccess(response)
  } catch (error) {
    return onError(error)
  }
}



export default directService