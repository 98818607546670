import directService from "../AppService"

const sendOTP = (data) => {
    return directService(
        {
            url: `/api/v1/otp/send`,
            method: 'POST',
            data: data,
        },

    )
}

const verifyOTP = (data) => {
    return directService(
        {
            url: `/api/v1/otp/verify`,
            method: 'POST',
            header: 'Content-Type: application/json',
            data: data,
        },

    )
}



export { sendOTP, verifyOTP }
