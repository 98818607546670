import React from 'react';
import { Box, Typography } from '@mui/material';

const PrivacyNotice = () => {
  return (
    <Box p={2} sx={{ maxWidth: '800px', margin: 'auto' }}>
      <Typography paragraph>
        “We”, “us” and “our” refers to Endl, details of which are contained in paragraph 1.1 below. “personal data”, means any information relating to you as an identified or identifiable natural person (“data subject”); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an online identifier or to one or more factors specific to your physical, physiological, genetic, mental, economic, cultural or social identity. For the avoidance of doubt, personal data does not include data from which you cannot be identified (which is referred to simply as data, non-personal information/data, anonymous data, or de-identified data). “processing” means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
      </Typography>
      <Typography paragraph>
        Data protection laws says that the personal information we hold about you must be:
      </Typography>
      <Box component="ul" pl={4}>
        <li>Used lawfully, fairly and in a transparent way</li>
        <li>Collected only for valid purposes that we have clearly explained to you and not used in any way that is incompatible with those purposes.</li>
        <li>Relevant to the purposes we have told you about and limited only to those purposes.</li>
        <li>Accurate and kept up to date.</li>
        <li>Kept only as long as necessary for the purposes we have told you about.</li>
        <li>Kept securely.</li>
      </Box>
      <Typography paragraph>
        This notice is separated into the following sections for ease of reference. If you have any questions about this notice or how we collect and use personal information about you, please contact us.
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Information about Controller
      </Typography>
      <Typography paragraph>
        1.1 We, Endl, are a controller of your personal data. Our registered offices are below:
      </Typography>
      <Box pl={4}>
        <Typography paragraph>
          Canada – 100 King Street West, Suite 5600, Toronto, Ontario, Canada, L6M 2G7
        </Typography>
        <Typography paragraph>
          United States – 201 South Biscayne Blvd., 28th Floor, Miami, Florida, USA, 33031
        </Typography>
      </Box>
      <Typography paragraph>
        1.2 If you have any questions, contact us via mail at our registered office or via email at compliance@endl.com.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. Contract information and other correspondence
      </Typography>
      <Typography paragraph>
        2.1 When you enter into a contract with us (or someone does so on your behalf) there will be personal information about you relating to that contract such as your name, contact details, contract details, delivery details, and correspondence with us about the contract. We may also generate further information from the information you provide, such as a case ID number that will be assigned to you.
      </Typography>
      <Typography paragraph>
        2.2 We need certain information to carry out our contract with you and you must provide this in order to enter into a contract with us (or as required under that contract), if you do not, we may not be able to carry out our contract with you. Mandatory information fields are generally set out when you are entering into the contract, but in particular, you must provide the following information:
      </Typography>
      <Box component="ul" pl={4}>
        <li>Your name and contact details (including your email address).</li>
        <li>Your delivery address.</li>
        <li>Your payment details.</li>
        <li>Information to verify your identity and other information for us to carry out anti-money laundering checks.</li>
      </Box>
      <Typography paragraph>
        2.3 Other correspondence or interaction (for example by email, telephone, regular mail, SMS or via our website) between you and us, will include personal information (such as names and contact details) in that correspondence. This may include enquiries, reviews, follow-up comments or complaints lodged by or against you and disputes with you or your organisation.
      </Typography>
      <Typography paragraph>
        2.4 Call information. We may also collect details of phone numbers used to call our organisation and the date, time and duration of any calls. Please note that if we record your calls to or from us, we will inform you of this.
      </Typography>
      <Typography paragraph>
        2.5 We will keep and use that information to carry out our contract with you (if applicable), to comply with any legal requirements for us to maintain certain records or carry out certain verifications, and/or for our legitimate interests in dealing with a complaint or enquiry and administering your (or your organisation’s) account or order and any services we offer, as well as to review and improve our offerings, including troubleshooting, data analysis, testing, research, statistical and survey purposes.
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. Marketing
      </Typography>
      <Typography paragraph>
        3.1 We may collect your name and contact details (such as your email address, phone number or address) in order to send you information about our products and services which you might be interested in. We may collect this directly from you, or through a third party. If a third party collected your name and contact details, they will only pass those details to us for marketing purposes if you have consented to them doing so.
      </Typography>
      <Typography paragraph>
        3.2 You always have the right to “opt out” of receiving our marketing. You can exercise the right at any time by contacting us, using the details shown above. If we send you any marketing emails, we will always provide an unsubscribe option to allow you to opt out of any further marketing emails. If you “opt-out” of our marketing materials, you will be added to our suppression list to ensure we do not accidentally send you further marketing. We may still need to contact you administrative or operational purposes, but we will make sure that those communications don’t include direct marketing.
      </Typography>
      <Typography paragraph>
        3.3 If you are an existing customer or are acting in a professional capacity as part of a company or LLP, we use your contact details as necessary for our legitimate interests in marketing to you and maintaining a list of potential customers.
      </Typography>
      <Typography paragraph>
        3.4 If you are not an existing customer and are not acting in a professional capacity as part of a company or LLP, we will only contact you for marketing purposes with your consent (whether we have collected your details directly from you, or through a third party).
      </Typography>
      <Typography paragraph>
        3.5 We never share your name or contact details with third parties for marketing purposes unless we have your “opt-in” consent to share your details with a specific third party for them to send you marketing. We do use third party service providers to send out our marketing, but we only allow them to use that information on our instructions and where they have agreed to treat the information confidentially and to keep it secure.
      </Typography>
      <Typography paragraph>
        3.6 When we send marketing emails to you, we use “web beacons” to collect information about when you open the email, your IP address and browser or email client type, and other similar information. We do this as necessary for our legitimate interests reviewing and considering our direct marketing activities. We keep this information for marketing of our services.
      </Typography>
      <h4>4. Website Information</h4>
        <p>
          4.1 We may collect information about you and your use of our website via technical means such as cookies, webpage counters, and other analytics tools. This may include your IP address, login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website. We use this as necessary for our legitimate interests in administering and improving our website and its content, to ensure it operates effectively and securely, and to develop our business and inform our marketing strategy. We may also create aggregate statistical data from that information (for instance, overall numbers of website visitors) which is not personal information about you.
        </p>
        <p>
          4.2 We, or third-party advertisers, may also use this information to serve ads to you. Where those ads are targeted, this may involve using website information and information we (or our third-party advertisers) have obtained from third parties. This won’t include information such as your name or contact details. Where our ads are displayed to you using your information, your information is used as necessary for our legitimate interests in marketing to you.
        </p>
        <p>
          4.3 Our website may, from time to time, contain links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy notice of every website you visit.
        </p>

        <h4>5. Employee Information</h4>
        <p>
          5.1 If you work for one of our customers, suppliers, or business partners, the information we collect about you may include your contact information, details of your employment, and our relationship with you. This information may be collected directly from you or provided by your organization. Your organization should have informed you that your information would be provided to us and directed you to this policy. We use this as necessary for our legitimate interests in managing our relationship with your organization. If we have a business relationship with you or your organization, we may receive information about you from your organization. We reserve the right to issue separate privacy notices setting out the handling of personal information in an employment context, including where services are offered under a contract for services and/or on a consultancy basis.
        </p>

        <h4>6. Information Collected at Our Premises</h4>
        <p>
          6.1 Visitor Information: We collect information about visitors to our premises. We may record information on your visit, including the date and time, who you are visiting, your name, employer, contact details, and vehicle registration number. If you have an accident at our premises, this may include an account of your accident. If you require any special assistance or have specific dietary requirements, this information will be recorded where necessary to protect your vital interests and will be deleted when no longer required in accordance with our information retention policies.
        </p>
        <p>
          6.2 CCTV: We may operate CCTV at our premises which may record you and your activities. We display notices to make it clear what areas are subject to surveillance. We only release footage following a warrant or formal request from law enforcement, or as necessary in relation to disputes.
        </p>
        <p>
          6.3 We use this information as necessary for our legitimate interests in administering your visit, ensuring site security and visitor safety, and administering parking.
        </p>
        <p>
          6.4 Visitor information is kept for a period necessary to deal with security issues, up to 3 years. If you have an accident on our premises, our accident records are retained for a period necessary to deal with any claims, up to 6 years.
        </p>

        <h4>7. Job Applications</h4>
        <p>
          7.1 We will collect and hold information on job applicants, including information you provide to us in your application or provided to us by recruitment agencies, as well as information on you from any referees you provide. We may also collect information about your professional history which you make available on LinkedIn or other social media or networking services or which is on your employer’s website.
        </p>
        <p>
          7.2 We use this as necessary to enter into an employment contract with you, and for our legitimate interests in evaluating candidates and recording our recruitment activities, and as necessary to exercise and perform our employment law obligations and rights. Where you voluntarily provide us with special categories of data, such as information about your race, health, or sexuality, we will store this as part of your application on the basis that you have decided to disclose it for this purpose and to ensure that our record of your application is accurate so we can comply with (and demonstrate our compliance with) our obligations under employment law.
        </p>
        <p>
          7.3 You must provide certain information (such as your name, contact details, professional and educational history) for us to consider your application fully. If you have not provided all of this information, we may contact you to ask for it. Providing this information is voluntary, but if you fail to do this, we may not be able to properly consider your application. If your application is successful, we will retain information you provide, including special categories of data, for such time as an employment relationship exists between us, and thereafter in accordance with paragraph 8.1 below. If your application is unsuccessful, we will retain your personal information for such a period as you specifically consent to, and in the absence of such consent, your information will be deleted.
        </p>
        <p>
          7.4 If you are listed as a reference by an applicant, we will hold your name, contact details, professional information about you (such as your employer and job title), and details of your relationship with the applicant. We will use this information as necessary for our legitimate interests in evaluating candidates and as necessary to exercise and perform our employment law obligations and rights. Your information will be kept alongside the applicant’s information. Where the applicant is successful, we will retain this information until we no longer need to contact that worker after they have stopped working for us. Where the applicant is unsuccessful, we will retain this information for such a period as the applicant consents to under paragraph 7.3 above.
        </p>
        <p>
          7.6 If you are listed as an emergency contact by someone who works for us, we will hold your name, contact details, and details of your relationship with that worker. We will use this to contact you as necessary to carry out our obligations under employment law, to protect the vital interests of that worker, and for our legitimate interests in administering our relationship with that worker. Your information will be kept until it is updated by that worker or we no longer need to contact that worker after they have stopped working for us. For the avoidance of doubt, we do not require emergency contact information from prospective job applicants, and if such information is volunteered by an applicant, we do not store such information unless their application is successful.
        </p>

        <h4>8. Legal Claims</h4>
        <p>
          8.1 Where we consider there to be a risk that we may need to defend or bring legal claims, we may retain your personal information as necessary for our legitimate interests in ensuring that we can properly bring or defend legal claims. We may also need to share this information with our insurers or legal advisers. How long we keep this information for will depend on the nature of the claim and how long we consider there to be a risk that we will need to defend or bring a claim.
        </p>

        <h4>9. Data Collected During Anti-Money Laundering Analysis</h4>
        <p>
          9.1 Considering the fact that fulfilling the information obligation may render impossible or seriously hinder the achievement of the purpose of the data processing, we inform you that you have the right to withdraw your consent at any time. This would not affect the legality of processing based on consent before its withdrawal.
        </p>
        <p>
          9.2 As part of the anti-money laundering analysis, we collect and process personal information to verify your identity and monitor for suspicious activity. This processing is necessary to comply with legal obligations related to anti-money laundering and counter-terrorist financing regulations. Your information will be processed securely and only used for the purposes of preventing financial crime.
        </p>

        <h4>10. Changes to This Privacy Notice</h4>
        <p>
          We may update this Privacy Notice from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes.
        </p>

        <h4>11. Contact Us</h4>
        <p>
          If you have any questions about this Privacy Notice or how we handle your personal data, please contact us at [Contact Information].
        </p>
    </Box>
  );
};

export default PrivacyNotice;
