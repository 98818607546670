import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
} from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { notify } from "../../../../components/Notification/Notification";

const ManualPaymentReviewDetails = ({
  sendAmount,
  sourceCurrency,
  receiveAmount,
  receiveCurrency,
  selectedRecipient,
  chargeTotal,
  handleGoBack,
  submitMaunalTranscation,
  depositAddress,
  qrCodeImage,
  handleManualPayment
}) => {
  
  const [timeLeft, setTimeLeft] = useState(1800);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        notify.success({
          message: `Copied ${text}`,
        });
        console.log("Text copied to clipboard");
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  return (
    <Grid container p={5}>
      <Grid item xs={12} sm={3} md={4}>
        <Box display={"flex"} justifyContent={"center"}>
          <Box display={"flex"} width={"60%"} gap={2} flexDirection={"column"}>
            <Typography variant="h5" fontWeight={600}>
              Review & pay
            </Typography>
            <Typography fontSize={14}>
              Please send your crypto within 30 minutes to ensure a fixed
              exchange rate.
            </Typography>
            <Typography fontSize={14}>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {formatTime(timeLeft)}{" "}
              </span>{" "}
              remaining
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={2}>
          <Typography variant="h5" fontWeight={600}>
            Scan QR code
          </Typography>
          <Box display={"flex"} gap={2}>
            <Box
              border={1}
              borderRadius={1}
              borderColor={"rgba(0,0,0,0.4)"}
              p={1}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              width={200}
              height={200}
            >
              {qrCodeImage ? (
                <img
                  width={"100%"}
                  height={"100%"}
                  src={qrCodeImage}
                  alt="QR Code"
                />
              ) : (
                <Typography>Loading...</Typography>
              )}
            </Box>
            <Typography fontSize={14}>Scan QR code with your wallet</Typography>
          </Box>
          <Divider />
          <Typography fontSize={14} fontWeight={600}>
            Or copy details and pay with any wallet
          </Typography>
          <Box>
            <Typography fontSize={14}>Send amount</Typography>
            <Box
              border={1}
              borderRadius={1}
              borderColor={"rgba(0,0,0,0.4)"}
              p={1}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography>
                {sendAmount} {sourceCurrency}
              </Typography>
              <IconButton
                onClick={() =>
                  copyToClipboard(`${sendAmount} ${sourceCurrency}`)
                }
              >
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Typography fontSize={14}>Send to address</Typography>
            <Box
              border={1}
              borderRadius={1}
              borderColor={"rgba(0,0,0,0.4)"}
              p={1}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography>{depositAddress}</Typography>
              <IconButton onClick={() => copyToClipboard(depositAddress)}>
                <ContentCopyOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          {/* <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography color={"rgba(0,0,0,0.60)"} fontSize={14}>
              Processing time
            </Typography>
            <Typography fontSize={14}>Instant</Typography>
          </Box> */}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography color={"rgba(0,0,0,0.60)"} fontSize={14}>
              Total fee
            </Typography>
            <Typography fontSize={14}>{chargeTotal} USD</Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography color={"rgba(0,0,0,0.60)"} fontSize={14}>
              Recipient gets
            </Typography>
            <Typography fontWeight={600} fontSize={18}>
              {receiveAmount} {receiveCurrency}
            </Typography>
          </Box>
          <Divider />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              variant="body1"
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
              fontSize={14}
            >
              Recipient
            </Typography>
            <Typography
              variant="body1"
              fontFamily="PPMori-Regular"
              fontSize={14}
            >
              {selectedRecipient?.name}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
              fontSize={14}
            >
              To bank account
            </Typography>
            <Typography
              fontFamily="PPMori-Regular"
              variant="body1"
              fontSize={14}
            >
              {selectedRecipient?.bankName}...
              {selectedRecipient?.bankAccountNumber?.slice(-4)}
            </Typography>
          </Box>
          <Divider />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              fontFamily="PPMori-Regular"
              color={"rgba(0,0,0,0.60)"}
              fontSize={14}
            >
              Payment method
            </Typography>
            <Typography
              fontFamily="PPMori-Regular"
              variant="body1"
              fontSize={14}
            >
              Manual payment
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
              backgroundColor: "#462A9C",
              ":hover": {
                backgroundColor: "#462A9C",
              },
            }}
            onClick={handleManualPayment}
          >
            Finish, I've paid
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: "none",
              borderColor: "#462A9C",
              color: "#462A9C",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
              mt: 2,
            }}
            fullWidth
            onClick={handleGoBack}
          >
            Go back
          </Button>
          <Typography
            fontSize={12}
            color={"rgba(0,0,0,0.60)"}
            textAlign={"center"}
            mt={1}
          >
            By clicking Finish, I've paid, you accept the{" "}
            <span style={{ fontWeight: "bold" }}>Terms & Conditions </span> and{" "}
            <span style={{ fontWeight: "bold" }}>Privacy Policy</span>, and
            authorize <span style={{ fontWeight: "bold" }}>endl </span>to
            initiate the transaction detailed above.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ManualPaymentReviewDetails;
