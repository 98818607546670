// Create a new file named ActiveStepContext.js
import React, { createContext, useContext, useState } from "react";

const ActiveStepContext = createContext();

export const useActiveStep = () => useContext(ActiveStepContext);

export const ActiveStepProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [recipient, setRecipient] = useState("ALL");
  const [paymentType, setPaymentType] = useState("ALL");

  console.log(recipient);
 const handleRecipient = (e) => {
    setRecipient(e.target.value);
  };


 const  handlePayment = (e) => {
    setPaymentType(e.target.value);
  };


  const nextStep = () => setActiveStep((prev) => prev + 1);
  const prevStep = () => setActiveStep((prev) => prev - 1);
  const resetStep = () => setActiveStep(0);

  return (
    <ActiveStepContext.Provider
      value={{ activeStep, nextStep, prevStep, resetStep, recipient,paymentType,handleRecipient,handlePayment}}
    >
      {children}
    </ActiveStepContext.Provider>
  );
};
