import React, { useState, useEffect } from "react";
import Web3 from "web3";
import Button from "@mui/material/Button";
import { IconButton, Box, Typography } from "@mui/material";
import { useAccount, useDisconnect } from "wagmi";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PopoverList from "../../components/Popover/PopoverList";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { ConnectWalletModal, DisconnectModal } from "../../components/ModalComponent/ConnectDisconnectWalletModal";
import BigNumber from 'bignumber.js';

const ERC20_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint8" }],
    type: "function",
  },
];

const USDC_ADDRESS = "0x48e8e57A4551dbB1B74942964883A9b7eC4dDA97";
const USDT_ADDRESS = "0xD62CA09B1F49026d1657994122f13F76DC62BFab";

const ConnectWallet = ({ usdcBalance, usdtBalance, setUsdcBalance, setUsdtBalance, transactions }) => {
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const [openConnectWalletModal, setOpenConnectWalletModal] = useState(false);
  const [connectedWalletActionEle, setConnectedWalletActionEle] = useState(null);

  // Initialize web3 with a provider
  const [web3, setWeb3] = useState(null);

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    } else {
      console.error("Ethereum provider not found.");
    }
  }, []);

  useEffect(() => {
    fetchBalances();
  }, [address, isConnected, web3, usdcBalance, usdtBalance]);

  const handleOpenConnectedWalletActions = (event) => {
    setConnectedWalletActionEle(event.currentTarget);
  };

  const handleCloseConnectedWalletActions = () => {
    setConnectedWalletActionEle(null);
  };

  const ConnectedWalletActionsPopoverList = [
    {
      label: "Disconnect wallet",
      icon: <LinkOffIcon sx={{ color: "red" }} />,
      onClick: () => {
        handleCloseConnectedWalletActions(null);
        setOpenDisconnectModal(true);
      },
      style: { color: "red" },
    },
  ];

  const handleDisconnect = async () => {
    try {
      setTimeout(() => {
         disconnect();
      }, 100);
    } catch (error) {
      console.error("Error disconnecting wallet:", error);
    } finally {
      setOpenDisconnectModal(false);
    }
  };

  const fetchBalances = async () => {
    if (address && isConnected && web3) {
      console.log("method triggered before", { lastTxAmount: transactions?.lastTxAmount, usdcBalance })

      const usdcContract = new web3.eth.Contract(ERC20_ABI, USDC_ADDRESS);
      const usdtContract = new web3.eth.Contract(ERC20_ABI, USDT_ADDRESS);
  
      try {
        const usdcBalanceRaw = await usdcContract.methods.balanceOf(address).call();
        const usdtBalanceRaw = await usdtContract.methods.balanceOf(address).call();
  
        const usdcDecimals = await usdcContract.methods.decimals().call();
        const usdtDecimals = await usdtContract.methods.decimals().call();
        // Use BigNumber for calculations
        const usdcBalance = new BigNumber(usdcBalanceRaw).div(new BigNumber(10).pow(usdcDecimals));
        const usdtBalance = new BigNumber(usdtBalanceRaw).div(new BigNumber(10).pow(usdtDecimals));
 
        console.log("method triggered after",{ lastTxAmount: transactions?.lastTxAmount, usdcBalance: usdcBalance.toNumber() })
        setUsdcBalance(usdcBalance.toNumber());
        setUsdtBalance(usdtBalance.toNumber());
      } catch (error) {
        console.error("Error fetching balances:", error);
      }
    }
  };


  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end" position="relative">
      {isConnected && (
        <>
          <IconButton onClick={handleOpenConnectedWalletActions}>
            <MoreHorizIcon />
          </IconButton>
          <PopoverList
            open={Boolean(connectedWalletActionEle)}
            anchorEl={connectedWalletActionEle}
            handleClose={handleCloseConnectedWalletActions}
            options={ConnectedWalletActionsPopoverList}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
          {/* <Box mt={2} alignSelf="flex-start">
            <Typography variant="body2">USDC Balance: {usdcBalance !== null ? `${usdcBalance.toFixed(2)} USDC` : "Loading..."}</Typography>
            <Typography variant="body2">USDT Balance: {usdtBalance !== null ? `${usdtBalance.toFixed(2)} USDT` : "Loading..."}</Typography>
          </Box> */}
        </>
      )}
      {!isConnected && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenConnectWalletModal(true)}
          style={{
            backgroundColor: "#462A9C",
            color: "#FFFFF",
            textTransform: "none",
            fontFamily: "PPMori-Regular",
          }}
        >
          Connect wallet
        </Button>
      )}
      {openConnectWalletModal && (
        <ConnectWalletModal
          open={openConnectWalletModal}
          onClose={() => setOpenConnectWalletModal(false)}
        />
      )}
      {openDisconnectModal && (
        <DisconnectModal
          open={openDisconnectModal}
          handleDisconnect={handleDisconnect}
        />
      )}
    </Box>
  );
};

export default ConnectWallet;
