import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, TextField } from "@mui/material";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { notify } from "../../components/Notification/Notification";
const SignUppCompanyInformationForm = ({
  handleInput,
  formData,
  formErrors,
}) => {
  const logoInputRef = useRef();
  const [previewUrl, setPreviewUrl] = useState(null);
  useEffect(() => {
    if (formData.logo) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(formData.logo);
    }
  }, [formData.logo]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxFileSize = 2 * 1024 * 1024;
      if (file.size > maxFileSize) {
        notify.error({
          message: "File size too large",
          description: "The selected file is larger than 2MB. Please choose a smaller file.",
        });
        return;
      }
      handleInput("logo", file);
    }
  };
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Box>
        <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
          Company name
        </Typography>
        <TextField
          error={!!formErrors.companyName}
          variant="outlined"
          placeholder="Enter company name"
          name="companyName"
          fullWidth
          value={formData.companyName}
          onChange={(e) => handleInput("companyName", e.target.value)}
          sx={{
            "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
              padding: "11.5px 14px",
            },
          }}
        />
        {formErrors.companyName && (
          <Typography fontSize={13} color={"red"}>
            {formErrors.companyName}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
          Company logo (optional)
        </Typography>
        <input
          hidden
          type="file"
          ref={logoInputRef}
          onChange={handleImageChange}
        />
        <Box
          mt={1}
          bgcolor={"#f9f8f5"}
          border={1}
          borderRadius={"100%"}
          height={50}
          width={50}
          borderColor={"#e5e2e0"}
          sx={{ cursor: "pointer" }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={() => logoInputRef?.current?.click()}
          position={"relative"}
          // overflow={"hidden"}
        >
          {previewUrl ? (
            <Box
              width={50}
              height={50}
              borderRadius={"100%"}
              overflow={"hidden"}
            >
              <img
                src={previewUrl}
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
              />
            </Box>
          ) : (
            <Typography variant="body1" fontSize={12} color={"#e5e2e0"}>
              logo
            </Typography>
          )}
          <Box
            position={"absolute"}
            bgcolor={"#a6a4a3"}
            borderRadius={"100%"}
            height={20}
            width={20}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            right={-4}
            top={-4}
            zIndex={9999}
          >
            <CreateOutlinedIcon
              sx={{
                color: "white",
                fontSize: "12px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUppCompanyInformationForm;
