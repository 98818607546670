import React from "react";
import { Box, Grid } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import FormSummerySidebar from "../FormSummerySidebar";
import { sendEmail } from "../../../../lib/service/PaymentDetailApi/PaymentDetailApi";
import ReviewDetails from "./ReviewDetails";
import {
  getCurrency,
  getQuote,
  showTranscationPurpose,
  showQRcode,
  showWalletAddress,
} from "../../../../lib/service/DashboardApi/dashboardApi";
import { useAccount } from "wagmi";
import { submitDeposit } from "../../../../lib/service/depositApi/depositApi";
import { submitTransaction } from "../../../../lib/service/TranscationApi/transcationApi";
import LoadingTxn from "../../../LoaderUI/LoadingTxn";
import TransactionSuccess from "../../../Alerts/TransactionSuccess";
import TransactionFailure from "../../../Alerts/TransactionFailure";
import TransactionOTPVerifyForm from "../../../Forms/TransactionVerifyOTPForm";
import SendMoneyTransferAmountForm from "../../../Forms/SendMoneyTransferAmountFrom";
import SendPaymentMethodForm from "../../../Forms/SendMoneyPaymentMethodFrom";
import { CONTRACT_INFO } from "../../../../configs/ContractsInfo";
import { useChainId, useWriteContract } from "wagmi";
import { calculateTokensWithDecimals } from "../../../../utils/web3.utils";
import ManualPaymentReviewDetails from "./ManualPaymentReviewDetails";
import { sendOTP, verifyOTP } from "../../../../lib/service/otpAPi/otpApi";
import { notify } from "../../../Notification/Notification";

const CryptoToFiat = ({
  handleGoBack,
  handleNext,
  selectedPaymentType,
  selectedRecipient,
  step,
  addNewTransaction,
  onClose,
  errorTxn,
  successTxn,
  setErrorTxn,
  setSuccessTxn
}) => {
  const { address: walletAddress } = useAccount();
  const [sourceCurrency, setSourceCurrency] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [receiveCurrency, setReceiveCurrency] = useState("INR");
  const [transaction, setTransaction] = useState({});
  const [exchangeRate, setExchangeRate] = useState(0);
  const [receiveAmount, setReceiveAmount] = useState("");
  const [chargeTotal, setchargeTotal] = useState(0);
  const [totalFeesAmount, setTotalFeesAmount] = useState(0);
  const [destinationCurrencies, setDestinationCurrencies] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [sendAmount, setSendAmount] = useState();
  const [sourceCurrencies, setSourceCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { writeContractAsync } = useWriteContract();
  const [chargesFee, setChargesFees] = useState([]);
  const chainId = useChainId();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(walletAddress ? "CONNECTED_WALLET" : "MANUAL_PAYMENT");
  const [showReason, setReason] = useState({});
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [depositAddress, setDepositAddress] = useState("");
  const [isQrLoading, setQrLoading] = useState("");
  const [transactionPurpose, setTransactionPurpose] = useState("");
  const [invoiceFileIdSave, setinvoiceFileIdSave] = useState("");
  const [showAmountError, setAmountError] = useState("");
  const [networkList, setNetworkList] = useState([
    { id: 1, name: "ETH", value: "Ethereum" },
  ]);
  const [networkValue, setNetworkValue] = useState("Ethereum");
  const [IsQRGenerate, setIsQRGenerate] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  useEffect(() => {
    const fetchTransactionPurpose = async () => {
      try {
        const response = await showTranscationPurpose();
        setTransactionPurpose(response.data);
      } catch (error) {
        console.error("Error fetching transaction purpose:", error);
      }
    };

    fetchTransactionPurpose();
  }, []);

  const handleSelectedPaymentMethod = async (value) => {
    setSelectedPaymentMethod(value);
    setQrLoading(true);
    if (value === 'MANUAL_PAYMENT') {
      try {
        const qrCodeResponse = await showQRcode();
        if (qrCodeResponse !== undefined) {
          setIsQRGenerate(true)
          const reader = new FileReader();
          reader.onloadend = () => {
            const url = reader.result;
            setQrCodeImage(url);
            console.log(url);
          };
          reader.readAsDataURL(qrCodeResponse);

          const walletAddressResponse = await showWalletAddress();
          setDepositAddress(walletAddressResponse.data.crypto_deposit_address);
        } else {
          setIsQRGenerate(false)
        }
        setQrLoading(false)
      } catch (error) {
        setQrLoading(false)
        console.error("Error fetching payment details", error);
      }
    }
  };

  useEffect(() => {
    if (selectedPaymentMethod === "MANUAL_PAYMENT" && !walletAddress) {
      handleSelectedPaymentMethod(selectedPaymentMethod);
    }
  }, [selectedPaymentMethod]);

  const handleSubmit = useCallback(
    async () => {
      setIsLoading(true);
      try {
        const data = await writeContractAsync({
          chainId: chainId,
          address: CONTRACT_INFO[chainId][sourceCurrency]["ADDRESS"],
          functionName: "transfer",
          abi: CONTRACT_INFO[chainId][sourceCurrency]["ABI"],
          args: [
            "0x8997fd9840a5eb753e1902fF766E8b1bd8Cb61AD",
            calculateTokensWithDecimals(
              sendAmount,
              CONTRACT_INFO[chainId][sourceCurrency]["DECIMALS"]
            ),
          ],
        });
        const depositData = {
          depositType: "CRYPTO_WALLET",
          confirmationId: data,
          amountRequested: sendAmount,
          currencyRequested: sourceCurrency,
        };
        const res = await submitDeposit(depositData);
        const deposit = res.data;
        const txnData = {
          nameOrAlias: "Test Deposit 1",
          userId: deposit.userId,
          quoteId: transaction.quoteId,
          depositId: deposit.depositId,
          transferPurpose: "Repatriation of Indian investment abroad in equity capital (shares)",
          transferPurposeCode: "P0001",
          recipientId: selectedRecipient.id,
          fundsSource: "SALARY",
          endlTransactionMode: "CRYPTO_TO_FIAT",
          invoiceFileId: invoiceFileIdSave
        };
        await submitTransaction(txnData);
        addNewTransaction();
        setSuccessTxn(true);
        setIsLoading(false);
      } catch (error) {
        setErrorTxn(true);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [
      sendAmount,
      walletAddress,
      receiveAmount,
      selectedPaymentType,
      sourceCurrency,
      receiveCurrency,
      selectedRecipient,
    ]
  );

  const submitMaunalTranscation = async () => {
    try {
      setIsLoading(true);
      const confirmationId = `txn_${Math.random().toString(36).substr(2, 9)}_${Date.now()}`;

      const depositData = {
        depositType: "CRYPTO_MANUAL_WALLET",
        confirmationId: confirmationId,
        amountRequested: sendAmount,
        currencyRequested: sourceCurrency,
      };

      // Assuming you have a submitDeposit function similar to the handleSubmit function
      const res = await submitDeposit(depositData);
      const deposit = res.data;

      const txnData = {
        nameOrAlias: "Test Deposit 1",
        userId: deposit.userId,
        quoteId: transaction.quoteId,
        depositId: deposit.depositId,
        transferPurpose: "Repatriation of Indian investment abroad in equity capital (shares)",
        transferPurposeCode: "P0001",
        recipientId: selectedRecipient.id,
        fundsSource: "SALARY",
        endlTransactionMode: "CRYPTO_TO_FIAT",
        invoiceFileId: invoiceFileIdSave
      };
      await submitTransaction(txnData);
      setSuccessTxn(true);
      setIsLoading(false);
    } catch (ex) {
      console.log(ex, 'database issue');
    } finally {
      setIsLoading(false);
    }
  };

  const handleReceiveCurrencyChange = useCallback((event) => {
    setReceiveCurrency(event.target.value);
  }, []);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const fetchQuote = useCallback(
    debounce(
      async (
        selectedPaymentType,
        sourceCurrency,
        sendAmount,
        receiveCurrency
      ) => {
        setIsFetching(true);
        try {
          const response = await getQuote({
            partnerId: "1112",
            depositType: "CRYPTO_WALLET",
            accountType: "INDIVIDUAL",
            source: {
              amount: Number(sendAmount),
              currency: sourceCurrency,
            },
            destination: {
              currency: receiveCurrency,
            },
          });
          if (response?.code === 200) {
            const { source, destination, fxRate, charges, chargeTotal } =
              response.data;
  
            setTransaction(response.data);
            setExchangeRate(response.data.fxRate);
  
            setChargesFees(charges);
            setReceiveAmount(destination.amount);
            setTotalFeesAmount(destination.convertedAmountWithoutFeesIncluded);
            setchargeTotal(chargeTotal);
            setAmountError("")
          } else {
            setTransaction({})
            setExchangeRate(0);
            setReceiveAmount('');
            setChargesFees([]);
            setchargeTotal(0);
            setAmountError(response.errors[0]['errDesc'])
          }
        } catch (error) {
          console.error("Error fetching quote:", error);
          setTransaction({});
          setExchangeRate(0);
  
          setChargesFees(0);
          setReceiveAmount('');
          setTotalFeesAmount(0);
          setchargeTotal(0);
          setAmountError(error.errors[0]['errDesc'])
        } finally {
          setIsFetching(false);
        }
      },300
    ),
    []
  );

  useEffect(() => {
    if (!sendAmount || !receiveCurrency || !selectedPaymentType) return;
    fetchQuote(
      selectedPaymentType,
      sourceCurrency,
      sendAmount,
      receiveCurrency
    );
  }, [sendAmount, receiveCurrency, sourceCurrency]);

  const handleSendAmountChange = useCallback((amount) => {
    setSendAmount(amount);
    if (!amount) {
      setTransaction({})
      setExchangeRate(0);
      setReceiveAmount('');
      setChargesFees([]);
      setchargeTotal(0);
      setAmountError("")
    }
  }, []);

  const toggleInfo = useCallback(() => {
    setShowInfo((prevShowInfo) => !prevShowInfo);
  }, [showInfo, setShowInfo]);

  const fetchCurrencyList = useCallback(async () => {
    try {
      const response = await getCurrency({});
      const { destination, source } = response.data;
      const filteredSource = source.currency.filter(
        (currency) => currency.isCrypto
      );
      setSourceCurrencies(filteredSource);
      setDestinationCurrencies(destination.currency);
    } catch (error) {
      console.error("Error fetching currency list:", error);
    }
  }, [selectedPaymentType]);

  const handleSendOTP = async () => {
    handleNext()
    handleSubmit()
  };

  const handleManualPayment = async () => {
    handleNext()
    submitMaunalTranscation()
  };

  useEffect(() => {
    if (!selectedPaymentType || selectedPaymentType === "") return;
    fetchCurrencyList();
  }, [selectedPaymentType]);

  const handleCurrencyChange = useCallback((event) => {
    setSourceCurrency(event.target.value);
  }, []);

  const handleNetworkChange = useCallback((event) => {
    setNetworkValue(event.target.value);
  }, []);

  const retryTransaction = useCallback(() => {
    setErrorTxn(false);
    handleSubmit();
  }, [handleSubmit]);

  const handleSelectChange = (event) => {
    setReason(event.target.value);
  };
  return (
    <>
      {step === 1 && (
        <Grid container py={5}>
          <Grid item xs={12} sm={3} md={4}>
            <FormSummerySidebar
              title={"Payment Method"}
              showSummery
              showStepOneSummery
              selectedPaymentType={selectedPaymentType}
              selectedRecipient={selectedRecipient}
              networkValue={networkValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SendPaymentMethodForm
              sourceCurrency={sourceCurrency}
              walletAddress={walletAddress}
              handleCurrencyChange={handleCurrencyChange}
              handleGoBack={handleGoBack}
              handleNext={handleNext}
              toggleInfo={toggleInfo}
              sourceCurrencies={sourceCurrencies}
              onPaymentMethodChange={handleSelectedPaymentMethod}
              selectedPaymentMethod={selectedPaymentMethod}
              networkList={networkList}
              handleNetworkChange={handleNetworkChange}
              networkValue={networkValue}
              IsQRGenerate={IsQRGenerate}
            />
          </Grid>
        </Grid>
      )}
      {step === 2 && (
        <Box
          padding={5}
          display={"flex"}
          style={{ maxHeight: "100vh", overflow: "auto" }}
        >
          <FormSummerySidebar
            title={"Transfer amount"}
            showSummery
            showStepOneSummery
            selectedPaymentType={selectedPaymentType}
            selectedPaymentMethod={selectedPaymentMethod}
            selectedRecipient={selectedRecipient}
            showStepTwoSummery
            sourceCurrency={sourceCurrency}
            walletAddress={walletAddress}
            width={"30%"}
            networkValue={networkValue}
          />
          <Box flexGrow={1}>
            <SendMoneyTransferAmountForm
              sourceCurrency={sourceCurrency}
              destinationCurrencies={destinationCurrencies}
              receiveCurrency={receiveCurrency}
              receiveAmount={receiveAmount}
              totalFeesAmount={totalFeesAmount}
              chargesFee={chargesFee}
              exchangeRate={exchangeRate}
              sendAmount={sendAmount}
              handleReceiveCurrencyChange={handleReceiveCurrencyChange}
              handleSendAmountChange={handleSendAmountChange}
              isFetching={isFetching}
              handleGoBack={handleGoBack}
              handleNext={handleNext}
              chargeTotal={chargeTotal}
              showReason={showReason}
              handleSelectChange={handleSelectChange}
              transactionPurpose={transactionPurpose}
              setinvoiceFileIdSave={setinvoiceFileIdSave}
              showAmountError={showAmountError}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
            />
          </Box>
        </Box>
      )}
      {step === 3 && (
        <div style={{ maxHeight: "100vh", overflow: "auto" }}>
          {selectedPaymentMethod === "CONNECTED_WALLET" ? (
            <ReviewDetails
              sendAmount={sendAmount}
              chargesFee={chargesFee}
              walletAddress={walletAddress}
              sourceCurrency={sourceCurrency}
              handleGoBack={handleGoBack}
              handleSubmit={handleSubmit}
              isFetching={isFetching}
              receiveAmount={receiveAmount}
              receiveCurrency={receiveCurrency}
              selectedPaymentType={selectedPaymentType}
              selectedRecipient={selectedRecipient}
              chargeTotal={chargeTotal}
              handleSendOTP={handleSendOTP}
            />
          ) : (
            <ManualPaymentReviewDetails
              receiveCurrency={receiveCurrency}
              handleGoBack={handleGoBack}
              sendAmount={sendAmount}
              sourceCurrency={sourceCurrency}
              chargeTotal={chargeTotal}
              selectedRecipient={selectedRecipient}
              receiveAmount={receiveAmount}
              onClose={onClose}
              submitMaunalTranscation={submitMaunalTranscation}
              qrCodeImage={qrCodeImage}
              depositAddress={depositAddress}
              handleManualPayment={handleManualPayment}
            />
          )}
        </div>
      )}
      {step === 4 &&
        (isLoading ? (
          <Box flexGrow={1}>
            <LoadingTxn message={"Waiting for crypto..."} />
          </Box>
        ) : successTxn ? (
          <Box flexGrow={1}>
            <TransactionSuccess
              title={"Transaction successful"}
              message={
                "Your cryptocurrency has been received and is now being converted to fiat currency for the recipient. We'll notify you once the transfer is done"
              }
              actionButtonText={"Go to Dashboard"}
              buttonAction={() => window.location.reload()}
            />
          </Box>
        ) : errorTxn ? (
          <Box flexGrow={1}>
            <TransactionFailure
              goBackAction={onClose}
              retryAction={retryTransaction}
              retryActionText={"Retry Transaction"}
              goBackActionText={"Back to Dashboard"}
            />
          </Box>
        ) : null)}
    </>
  );
};

export default CryptoToFiat;
