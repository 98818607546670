import React from "react";
import {
    Box,
    FormControl,
    Select,
    Typography,
    MenuItem,
    TextField,
    Tooltip,
    IconButton,
  } from "@mui/material";
  import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
export const CanadaBankDetails = ({ formData, handleChange, formErrors }) => {
  return (
    <>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Account number
        </Typography>
        <TextField
          placeholder="Enter account number"
          onChange={(e) => handleChange("bankAccountNumber", e.target.value)}
          value={formData.bankAccountNumber}
          name="bankAccountNumber"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankAccountNumber}
        />
        {formErrors.bankAccountNumber && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankAccountNumber}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Routing number
          <Tooltip
            fontFamily="PPMori-Regular"
            title={
              "Needs institution number (3 digit number) & transit number (5 digit number) which are unique for the destination bank. Combine them (institution number + transit number) and provide into routing number. If  004 is institution number and 00012 is transit number, put like this 00400012"
            }
            arrow
            placement="right"
          >
            <IconButton sx={{ marginLeft: "4px" }}>
              <InfoOutlinedIcon
                sx={{
                  width: "15px",
                  height: "15px",
                }}
              />
            </IconButton>
          </Tooltip>
        </Typography>
        <TextField
          placeholder="Enter routing number"
          onChange={(e) => handleChange("bankRoutingNumber", e.target.value)}
          value={formData.bankRoutingNumber}
          name="bankRoutingNumber"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankRoutingNumber}
        />
        {formErrors.bankRoutingNumber && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankRoutingNumber}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Swift BIC code
        </Typography>
        <TextField
          placeholder="Enter Swift BIC code"
          onChange={(e) => handleChange("swiftBic", e.target.value)}
          value={formData.swiftBic}
          name="swiftBic"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.swiftBic}
        />
        {formErrors.swiftBic && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.swiftBic}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Account type
        </Typography>
        <FormControl fullWidth error={formErrors.accountType}>
          <Select
            id="country-select"
            value={formData.accountType ? formData.accountType : "select"}
            onChange={(e) => handleChange("accountType", e.target.value)}
            name="accountType"
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 350,
                },
              },
            }}
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
          >
            <MenuItem disabled value="select">
              Select
            </MenuItem>
            {[
              { value: "CHECKING", label: "Checking" },
              { value: "SAVING", label: "Saving" },
            ].map((accType) => (
              <MenuItem
                key={accType.value}
                value={accType.value}
                style={{
                  marginBottom: ".5rem",
                  marginTop: ".3rem",
                  minWidth: 250,
                }}
              >
                {accType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {formErrors.accountType && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.accountType}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Bank name
        </Typography>
        <TextField
          placeholder="Enter bank name"
          onChange={(e) => handleChange("bankName", e.target.value)}
          value={formData.bankName}
          name="bankName"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankName}
        />
        {formErrors.bankName && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankName}
          </Typography>
        )}
      </Box>
    </>
  );
};
