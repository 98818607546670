import React from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Skeleton,
} from "@mui/material";

const CardLoader = () => {
  return (
    <Paper
      variant="outlined"
      sx={{
        height: "350px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "16px",
        gap: "16px",
      }}
    >
      <Typography variant="h6" sx={{ fontSize: "14px" }}>
        Currency calculator
      </Typography>

      {/* Loader for "You send" section */}
      <Box container spacing={0} alignItems="center" gap={1}>
        <Typography variant="body1" sx={{ fontSize: "12px", fontFamily: "PPMori-Regular" }}>
          You send
        </Typography>
        <Box display="flex" gap={1} container spacing={0} alignItems="center">
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={150} height={20} />
        </Box>
      </Box>

      {/* Loader for "Recipient gets" section */}
      <Box container spacing={0} alignItems="center">
        <Typography variant="body1" sx={{ fontSize: "12px", fontFamily: "PPMori-Regular" }}>
          Recipient gets
        </Typography>
        <Box display="flex" gap={1} container spacing={0} alignItems="center">
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={150} height={20} />
        </Box>
      </Box>

      {/* Loader for exchange rate, fees, and total amounts */}
      <Box>
        {[
          "Exchange rate",
          "Fixed fee",
          "Variable fee",
          "Total fee",
          "Total amount to send",
        ].map((label, index) => (
          <Grid container key={index}>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                style={{ fontSize: "14px", fontFamily: "PPMori-Regular" }}
              >
                <Skeleton variant="text" width={80} height={20} />
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <Skeleton variant="text" width={80} height={20} />
            </Grid>
          </Grid>
        ))}
      </Box>
    </Paper>
  );
};

export default CardLoader;
