import directService from "../AppService"
import { checkAuthToken } from "../AuthToken"

function getAuthToken() {
    return localStorage.getItem('authToken');
}

const getAllRecipient =(data) =>{
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `/api/v1/recipient/all`,
                    method: 'Post',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }

    } catch (error) {
        console.log(error)
    }
}
const sendEmail =(data) =>{
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `/api/v1/email/send-otp`,
                    method: 'Post',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
        
    } catch (error) {
        console.log(error)
    }
}
const verifyEmail =(data) =>{
    try {
        const checkToken = checkAuthToken()
        if (checkToken) {
            const token = getAuthToken();
            if (!token) {
                console.error('No auth token found. Please login.');
                return;
            }
            return directService(
                {
                    url: `/api/v1/email/verify-otp`,
                    method: 'Post',
                    data: data,
                    headers: { Authorization: `Bearer ${token}` },
                },

            )
        }
        
    } catch (error) {
        console.log(error)
    }
}

export {getAllRecipient,sendEmail,verifyEmail}