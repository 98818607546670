import React from "react";
import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import PersonOutlineOutlinedIcon from "../../Images/building-bank.svg";
import AccountBalanceOutlinedIcon from "../../Images/user.svg";

const SignUpAccountTypeForm = ({ selectedAccountType, handleInput }) => {
  function StyledRadio(props) {
    return (
      <Radio
        color="primary"
        sx={{
          "&.Mui-checked": {
            color: "#462A9C", // Change color when checked
          },
        }}
        {...props}
      />
    );
  }

  return (
    <FormControl style={{ marginBottom: "1rem" }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="userType"
        onChange={(e) => handleInput(e.target.value)}
        value={selectedAccountType}
      >
        {/* Individual Option */}
        <Box
          sx={{
            border: "1px solid #CFCCC9",
            background: "#F6F5F9",
            mb: 2,
            width: "100%",
            borderRadius: "8px",
            padding: 2,
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => handleInput("INDIVIDUAL")}
        >
          {/* Left Section: Radio, Label, and Description */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "75%", // Adjust as needed for spacing
            }}
          >
            <FormControlLabel
              value="INDIVIDUAL"
              name="userType"
              control={<StyledRadio />}
              label="Individual"
              sx={{ mb: 0 }}
              onClick={(e) => e.stopPropagation()}
            />
            <Typography
              fontSize={14}
              color="#575452"
              sx={{
                ml: 4, // Align the text with the label
                lineHeight: "1.2", // Adjust line height for better text spacing
              }}
            >
              For individuals who wish to send money, create multi-currency
              accounts, make personal transfers, and more.
            </Typography>
          </Box>

          {/* Right Section: Icon */}
          <Box
            sx={{
              border: "1px solid #CFCCC9",
              borderRadius: "5px",
              bgcolor: "white",
              height: 40,
              width: 64,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={AccountBalanceOutlinedIcon}
              alt="Individual"
              style={{
                maxHeight: "100%", // Ensure the image fits within the box
                maxWidth: "100%", // Ensure the image fits within the box
              }}
            />
          </Box>
        </Box>

        {/* Business Option */}
        <Box
          sx={{
            border: "1px solid #CFCCC9",
            background: "#F6F5F9",
            mb: 2,
            width: "100%",
            borderRadius: "8px",
            padding: 2,
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => handleInput("BUSINESS")}
        >
          {/* Left Section: Radio, Label, and Description */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "75%", // Adjust as needed for spacing
            }}
          >
            <FormControlLabel
              value="BUSINESS"
              name="userType"
              control={<StyledRadio />}
              label="Business"
              sx={{ mb: 0 }}
              onClick={(e) => e.stopPropagation()}
            />
            <Typography
              fontSize={14}
              color="#575452"
              sx={{
                ml: 4, // Align the text with the label
                lineHeight: "1.2", // Adjust line height for better text spacing
              }}
            >
              For businesses that want to invite team members, make group, bulk,
              and invoice payments, and more.
            </Typography>
          </Box>

          {/* Right Section: Icon */}
          <Box
            sx={{
              border: "1px solid #CFCCC9",
              borderRadius: "5px",
              bgcolor: "white",
              height: 40,
              width: 64,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={PersonOutlineOutlinedIcon}
              alt="Business"
              style={{
                maxHeight: "100%", // Ensure the image fits within the box
                maxWidth: "100%", // Ensure the image fits within the box
              }}
            />
          </Box>
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

export default SignUpAccountTypeForm;
