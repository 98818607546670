import React, { useState } from "react";
import { Box, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StepperUI from "../../Common/StepperUI/StepperUI";
import SidebarSummery from "./SidebarSummery";
import Summery from "./Summery";
import TransactionOTPVerifyForm from "../../Forms/TransactionVerifyOTPForm";
import LoadingTxn from "../../LoaderUI/LoadingTxn";
import TransactionSuccess from "../../Alerts/TransactionSuccess";
import TransactionFailure from "../../Alerts/TransactionFailure";
import TopUpPaymentMethodForm from "../../Forms/TopUpPaymentMethodForm";
import TransferAmountForm from "../../Forms/TransferAmountForm";
import endlMainLogo from "../../../Images/brandings/endlMainLogo.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
};

const logoStyle = {
  position: 'absolute',
  top: '20px',
  left: '20px',
  width: '100px',
  height: 'auto',
};

const TopUpModal = ({ open, onClose }) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [step, setStep] = useState(0);
  const [isTransferring, setIsTransFerring] = useState(false);
  const [successTxn, setSuccessTxn] = useState(false);
  const [errorTxn, setErrorTxn] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const steps = ["Payment method", "Transfer Amount", "Review and Pay"];
  const [accounts, setAccounts] = useState([
    {
      id: 1,
      code: "EURO",
      name: "Euro",
      balance: 2100,
    },
    {
      id: 2,
      code: "EURO",
      name: "Euro",
      balance: 2100,
    },
  ]);
  const handleNext = () => {
    if (step != 3) {
      setStep((prev) => prev + 1);
    }
  };
  const handleGoBack = () => {
    if (step == 0) return;
    setStep((prev) => prev - 1);
  };
  const handleVerifyAndPay = () => {
    setIsTransFerring(true);
    setTimeout(() => {
      setIsTransFerring(false);
      setSuccessTxn(true);
    }, 3000);
  };
  const handleSelectedPaymentMethod = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style }}>
        <Box
          padding={2}
          borderBottom={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          borderColor={"rgba(0,0,0,0.3)"}
          position={"relative"}
          width="100%"
        >
          <img src={endlMainLogo} alt="Logo" className="endlLogo" style={logoStyle}/>
          <Box sx={{ width: "40%" }}>
            <StepperUI step={step} steps={steps} />
          </Box>

          <IconButton
            onClick={onClose}
            aria-label="close-open-account-modal"
            sx={{ position: "absolute", right: "12px" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          flexGrow={1}
          overflow={"scroll"}
        >
          {step === 0 && (
            <Box padding={5} display={"flex"}>
              <SidebarSummery title={"Payment method"} />
              <Box width={"100%"}>
                <TopUpPaymentMethodForm
                  handleNext={handleNext}
                  handleSelectedPaymentMethodOptionChange={
                    handleSelectedPaymentMethod
                  }
                  selectedPaymentMethod={selectedPaymentMethod}
                />
              </Box>
              <Box width={"100%"} />
            </Box>
          )}
          {step === 1 && (
            <Box padding={5} display={"flex"} style={{maxHeight: '100vh', overflow: 'hidden'}}>
              <SidebarSummery
                title={"Transfer Amount"}
                showSummery
                stepOneSummery
              />
              <Box width={"100%"}> 
                <TransferAmountForm
                  handleNext={handleNext}
                  handleGoBack={handleGoBack}
                />
              </Box>
              <Box width={"100%"} />
            </Box>
          )}
          {step === 2 && (
            <Box padding={5} display={"flex"}>
              <SidebarSummery title={"Transfer Amount"} />
              <Box width={"100%"}>
                <Summery handleGoBack={handleGoBack} handleNext={handleNext} />
              </Box>
              <Box width={"100%"} />
            </Box>
          )}
          {step == 3 &&
            (isTransferring ? (
              <Box flexGrow={1}>
                <LoadingTxn message={"Transferring amount"} />
              </Box>
            ) : successTxn ? (
              <Box flexGrow={1}>
                <TransactionSuccess
                  message={"Top-up successful"}
                  actionButtonText={"Message will be added later...."}
                  buttonAction={() => {}}
                />
              </Box>
            ) : errorTxn ? (
              <Box flexGrow={1}>
                <TransactionFailure
                  goBackAction={() => {}}
                  retryAction={() => {}}
                  retryActionText={"Retry top up"}
                  goBackActionText={"Go back to Account Details"}
                />
              </Box>
            ) : (
              <Box padding={5} display={"flex"}>
                <SidebarSummery title={"Review & verify"} />
                <TransactionOTPVerifyForm
                  handleSubmit={handleVerifyAndPay}
                  handleBack={handleGoBack}
                  handleResendOTP={() => {}}
                  otp={otp}
                  handleOtpChange={() => {}}
                />
                <Box width={"100%"}></Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default TopUpModal;
