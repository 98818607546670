// import sever_ENV from "../../../env"
import directService from "../AppService";
import { checkAuthToken } from "../AuthToken";

function getAuthToken() {
  return localStorage.getItem("authToken");
}

const submitDeposit = (data) => {
  try {
    const checkToken = checkAuthToken();
    if (checkToken) {
      const token = getAuthToken();
      if (!token) {
        console.error("No auth token found. Please login.");
        return;
      }
      return directService({
        url: `api/v1/deposit/submit`,
        method: "PUT",
        data: data,
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export { submitDeposit };
