import React from "react";
import { Box , Typography} from "@mui/material";
import OTP from "../OTP/OTP";
const SignUpOTPVerificationForm = ({
  otpLength,
  otpExpirationTimer,
  handleResendOTP,
  handleVerifyOTP,
  formData,
  isVerifyingOTP
}) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Typography variant="h5" fontWeight={600} textAlign={'center'}>
        Verify your email
      </Typography>
      <Typography color={"black"} fontSize={15} textAlign={'center'}>
        We have sent you a 6-character one-time-password to {formData.email}. Please
        enter the code below to continue.
      </Typography>
      <OTP
        otpLength={otpLength}
        onResendOTP={handleResendOTP}
        onVerifyOTP={handleVerifyOTP}
        isVerifyingOTP={isVerifyingOTP}
        buttonTitle={"Verify"}
        otpExpirationTimer={otpExpirationTimer}
      />
    </Box>
  );
};

export default SignUpOTPVerificationForm;
