import { Typography, Box, Divider, Button } from "@mui/material";
import React from "react";

const Summery = ({ handleGoBack, handleNext }) => {
  return (
    <Box width="100%" display={"flex"} gap={4} flexDirection={"column"}>
      <Typography textAlign={"left"} variant="h5">
        Summery
      </Typography>

      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
            Transfer amount
          </Typography>
          <Typography variant="h5" textAlign={"right"} fontWeight={600}>
            100.00 SGD
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
            Processing time
          </Typography>
          <Typography
            color={"rgba(0,0,0,0.70)"}
            textAlign={"right"}
            fontSize={14}
          >
            Instant
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
            Fixed fee
          </Typography>
          <Typography
            color={"rgba(0,0,0,0.70)"}
            textAlign={"right"}
            fontSize={14}
          >
            0 SGD
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
            Total fees
          </Typography>
          <Typography
            color={"rgba(0,0,0,0.70)"}
            textAlign={"right"}
            fontSize={14}
          >
            0 SGD
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
            You'll get
          </Typography>
          <Typography variant="h5" textAlign={"right"} fontWeight={600}>
            2282.82 TRY
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
            Payment method
          </Typography>
          <Typography
            variant="h5"
            textAlign={"right"}
            fontSize={14}
            fontWeight={600}
          >
            Local bank transfer
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
            From
          </Typography>

          <Typography
            variant="h5"
            textAlign={"right"}
            fontSize={14}
            fontWeight={600}
          >
            EURO Account ***409
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
            To
          </Typography>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Typography
              variant="h5"
              textAlign={"right"}
              fontSize={14}
              fontWeight={600}
            >
              Singapore dollar
            </Typography>
            <Typography
              variant="h5"
              textAlign={"right"}
              fontSize={14}
              fontWeight={600}
              color={"rgba(0,0,0,0.5)"}
            >
              100 SGD
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box spacing={2} wrap="noWrap" display={"flex"} gap={1} width={"100%"}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            textTransform: "none",
            borderColor: "#462A9C",
            color: "#462A9C",
            fontSize: "12px",
            fontFamily: "PPMori-Regular",
            width: "100%",
            py: 1,
          }}
          fullWidth
          onClick={handleGoBack}
        >
          Go back
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "none",
            backgroundColor: "#462A9C",
            color: "#ffffff",
            fontSize: "12px",
            fontFamily: "PPMori-Regular",
            width: "100%",
            py: 1,
          }}
          onClick={handleNext}
        >
          {" "}
          Verify email
        </Button>
      </Box>
    </Box>
  );
};

export default Summery;
