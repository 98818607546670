import React from "react";
import { Typography, Button, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const TransactionSuccess = ({ message, actionButtonText, buttonAction, title }) => {
  return (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      height={"100%"}
    >
      <CheckCircleOutlineIcon
        style={{
          fontSize: "50px",
          color: "#09B138",
        }}
      />
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography
        variant="body1"
        textAlign={"center"}
        fontSize={15}
        gutterBottom
      >
        {message}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        style={{
          backgroundColor: "#462A9C",
          color: "white",
          alignItems: "center",
        }}
        onClick={buttonAction}
      >
        {actionButtonText}
      </Button>
    </Box>
  );
};

export default TransactionSuccess;
