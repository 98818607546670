import React, { useState, useEffect } from "react";
import AccountDetails from "../../pages/Accounts/AccountDetails";
import { fetchAccountTransactionDetails, getAccountDetails, getAllAccountsList } from "../../lib/service/accountsApi/accountsApi";
import { useParams } from "react-router-dom";

const AccountDetailsContainer = () => {
  const [accountDetails, setAccountDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [transactonList, setTransactonList] = useState([]);
  const [allAccountsDetails, setAllAccountsDetails] = useState(null);
  const { accountId } = useParams();
  useEffect(() => {
    if(accountId) {
      getAccountDetailsById(accountId);
      fetchTransactionDetails(accountId);
      getAllAccountsDetails();
    }
  }, []);

  const onAccountStatusChange = (status) => {
    setAccountDetails(prev=>({
      ...prev,
      status:status
    }))
  }

  const getAccountDetailsById = async () => {
    setIsLoading(true);
    try {
      const res = await getAccountDetails(accountId);
      setAccountDetails(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTransactionDetails = async(id) => {
    setIsLoading(true);
    try {
      const res = await fetchAccountTransactionDetails(id);
      setTransactonList(res.data.transactions);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getAllAccountsDetails = async () => {
    setIsLoading(true);
    try {
      const res = await getAllAccountsList();
      setAllAccountsDetails(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AccountDetails accountDetails={accountDetails} isLoading={isLoading} onAccountStatusChang={onAccountStatusChange} transactonList={transactonList} allAccountsDetails={allAccountsDetails}/>
  );
};

export default AccountDetailsContainer;
