import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
dayjs.extend(isBetweenPlugin);

export const isDateInRange = (date, startDate, endDate) => {
    return (
        (dayjs(date).isAfter(startDate) && dayjs(date).isBefore(endDate)) ||
        dayjs(startDate).isSame(dayjs(endDate), "day")
    );
};

export const isSameDay = (day1, day2) => {
    return dayjs(day1).isSame(dayjs(day2), "day");
};

export const getDateRangeString = (rangeDate) => {
    return rangeDate
        .map(date => date.format('DDMMYYYY'))
        .join('&')
}