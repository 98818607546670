import React from "react";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
const LoadingTxn = ({ title, message }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      gap={1}
      height={"100%"}
    >
      <CircularProgress
        size={40}
        thickness={6}
        sx={{ my: 5, color: "black" }}
      />
      <Typography
        variant="body2"
        gutterBottom
        style={{ fontFamily: "PPMori-Regular", fontSize: "20px" }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        style={{ fontFamily: "PPMori-Regular", fontSize: "12px" }}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingTxn;
