import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import { formatDate } from '../../lib/service/AuthToken';
import Notification from '../../components/Notification/Notification'; 
import 'jspdf-autotable';
import './BasicPopover.css';

export default function BasicPopover({setAnchorEl,anchorEl,handleOpen, rowData}) {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const navigate = useNavigate();
  
   const handleClose = () => {
    setAnchorEl(null);
  };

  const repeatTranscation = () => {
    navigate('/transferAmount');
  }

  const exportPdf = () => {
    const doc = new jsPDF();
    const columns = [
      { header: 'Recipient Name', dataKey: 'recipientName' },
      { header: 'Transaction ID', dataKey: 'txnId' },
      { header: 'Type', dataKey: 'type' },
      { header: 'Currency', dataKey: 'currency' },
      { header: 'Amount', dataKey: 'amount' },
      { header: 'Deposit Method', dataKey: 'paymentMethod' },
      { header: 'Date Created', dataKey: 'dateCreated' },
      { header: 'Status', dataKey: 'status' }
    ];
  
    const rows = [
      {
        recipientName: rowData.recipientData.firstName + " " + rowData.recipientData.lastName,
        txnId: rowData.txnId,
        type: rowData.sentOrReceived,
        currency: rowData.sourceCurrency,
        amount: rowData.sourceAmount,
        paymentMethod: rowData.endlTransactionMode,
        dateCreated: formatDate(rowData.updatedOn),
        status: rowData.status
      }
    ];
  
    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: rows.map(row => Object.values(row)),
      startY: 20,
      styles: {
        fontSize: 9,
        cellPadding: 1,
      },
      tableWidth: 'wrap',
      columnStyles: {
        0: { cellWidth: 23 },
        1: { cellWidth: 23 },
        2: { cellWidth: 23 },
        3: { cellWidth: 23 },
        4: { cellWidth: 23 },
        5: { cellWidth: 23 },
        6: { cellWidth: 23 },
        7: { cellWidth: 23 },
      },
      headStyles: {
        halign: 'center',
      },
      bodyStyles: {
        halign: 'center',
      },
    });
  
    const fileName = "transaction_history.pdf";
    doc.save(fileName);
    setNotificationOpen(true);
    setNotificationMessage(`File "${fileName}" created successfully`);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const exportCsv = () => {
    const csvContent = [
      ['Recipient Name', 'Transaction ID', 'Type', 'Currency', 'Amount', 'Deposit Method', 'Date Created', 'Status'],
      [rowData.recipientData.firstName + " "+rowData.recipientData.lastName, rowData.txnId, rowData.sentOrReceived, rowData.sourceCurrency, rowData.sourceAmount, rowData.endlTransactionMode, formatDate(rowData.updatedOn), rowData.status]
    ].map(row => row.join(','));

    const csvString = csvContent.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'transaction.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setNotificationOpen(true);
    setNotificationMessage(`File created successfully`);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='popoverHead'
      >
        {/* <Typography className="popup" onClick={repeatTranscation}>
          Repeat transaction
        </Typography> */}
        <Typography className="popup" onClick={exportPdf}>
          Export PDF
        </Typography>
        <Typography className="popup" onClick={exportCsv}>
          Export CSV
        </Typography>
      </Popover>
      <Notification open={notificationOpen} onClose={handleNotificationClose} message={notificationMessage}/>
    </div>
  );
}
