import React from "react";
import OTP from "../../components/OTP/OTP";
import { Box, Button } from "@mui/material";
const TwoFALogin = ({
  handleVerifyAndLogin,
  handleResend,
  isVerifyingOTP,
  handleBack,
}) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Box width={"40%"} display={"flex"} gap={"1"} flexDirection={"column"}>
        <OTP
          otpLength={6}
          otpExpirationTimer={2 * 60}
          onResendOTP={handleResend}
          onVerifyOTP={handleVerifyAndLogin}
          isVerifyingOTP={isVerifyingOTP}
        />
        <Button
          fullWidth
          sx={{
            border: 1,
            borderColor: "#462A9C",
            bgcolor: "white",
            color: "#462A9C",
            textTransform: "none",
            height: 40,
            "&:hover": {
              bgcolor: "white",
            },
          }}
          onClick={handleBack}
        >
          Go back
        </Button>
      </Box>
    </Box>
  );
};

export default TwoFALogin;
