import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, IconButton, Stack } from "@mui/material";

const Modal = ({
  isOpen = false,
  onClose = () => {},
  title = "",
  sx = {},
  rootStyle = {},
  children,
  ...props
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          ...rootStyle,
        },
      }}
      {...props}
    >
      <Stack sx={sx}>
        <Stack flexDirection={"row"} alignItems={"center"}>
          <Stack flex={1}>{title}</Stack>
          <Stack>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
        {children}
      </Stack>
    </Dialog>
  );
};

export default Modal;
