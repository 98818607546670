import React from "react";
import Modal from "../Common/Modal/Modal";
import { Button, Stack, Typography } from "@mui/material";

const LogoutModal = ({ isOpen, onCLose, onLogout }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCLose}
      title={
        <Typography variant="h6" color="#0F0F0F">
          Log out
        </Typography>
      }
      sx={{ padding: "10px 15px 10px 40px", width: "500px" }}
      rootStyle={{ top: "-200px" }}
    >
      <Typography
        variant="subtitle2"
        color="#0F0F0F"
        sx={{ paddingTop: "6px" }}
      >
        Are you sure you want to log out?
      </Typography>
      <Stack
        sx={{ paddingTop: "50px", paddingBottom: "10px" }}
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
      >
        <Button
          sx={{
            textTransform: "none",
            fontFamily: "PPMori-Regular",
            padding: "5px 15px",
            color: "#27009C",
          }}
          onClick={onCLose}
          variant="text"
        >
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: "#462A9C",
            textTransform: "none",
            fontFamily: "PPMori-Regular",
            "&:hover": {
              backgroundColor: "#462A9C",
            },
            padding: "5px 15px",
            color: "#FFFFFF",
          }}
          onClick={onLogout}
        >
          Log out
        </Button>
      </Stack>
    </Modal>
  );
};

export default LogoutModal;
