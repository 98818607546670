import React, { useState, useEffect } from "react";
import Accounts from "../../pages/Accounts/Accounts";
import { getAllAccountsList } from "../../lib/service/accountsApi/accountsApi";
const AccountContainer = () => {
  const [allAccountsDetails, setAllAccountsDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getAllAccountsDetails = async () => {
      setIsLoading(true);
      try {
        const res = await getAllAccountsList();
        setAllAccountsDetails(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getAllAccountsDetails();
  }, []);
  const onAccountOpen = async () => {
    setIsLoading(true);
    try {
      const res = await getAllAccountsList();
      setAllAccountsDetails(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Accounts
      accountsDetails={allAccountsDetails}
      isLoading={isLoading}
      onAccountOpen={onAccountOpen}
    />
  );
};

export default AccountContainer;
