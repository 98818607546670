import React, { useState, createContext } from "react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider, http } from 'wagmi'
import { mainnet, polygon, arbitrum, optimism, sepolia } from "wagmi/chains";
import "@rainbow-me/rainbowkit/styles.css";
import {
  RainbowKitProvider,
  getDefaultConfig
} from "@rainbow-me/rainbowkit";


export const config = getDefaultConfig({
  appName: 'RainbowKit demo',
  projectId: 'YOUR_PROJECT_ID',
  chains: [mainnet, polygon, arbitrum, optimism,sepolia],
  transports: {
    [mainnet.id]: http(),
    [polygon.id]: http(),
    [sepolia.id]:http()
  },
})

export const WalletContext = createContext();
const queryClient = new QueryClient()
export const WalletProvider = ({ children }) => {
  const [balance, setBalance] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const clearWallet = () => { }
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <WalletContext.Provider value={{ balance, setBalance, walletAddress, setWalletAddress, clearWallet }}>
            {children}
          </WalletContext.Provider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
