import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ErrorBoundary from './layouts/ErrorBoundary/ErrorBoundary';
import NotFoundPage from './pages/Notfound/NotFoundPage';
import Dashboard from './pages/Dashboard/Dashboard';
import TransactionsHistroy from "./container/TranscationHistoryContainer/TranscationHistoryContainer";
import Recipients from "./pages/Recipients/Recipients";
import { ActiveStepProvider } from "./container/ActiveStepContext";
import PrivateRoute from "./layouts/privateRoute";
import { WalletProvider } from './context/WalletContext';
import AnalyticsContainer from './container/Analytics/analyticsContainer';
import ProfileNavbar from './container/Myprofile/myprofileContainer';
import './fonts/PPMori.css';
import BaseLayout from './layouts/BaseLayout/BaseLayout';
import Accounts from './container/Account/AccountsContainer';
import AccountDetails from './container/Account/AccountDetailsContainer';
import Login from './container/LoginAndSignupContainer/LoginContainer';
import SignUp from './container/LoginAndSignupContainer/SignUpContainer';
import ForgetPassword from './container/LoginAndSignupContainer/ForgetPasswordContainer';
import TwoFALogin from './container/LoginAndSignupContainer/TwoFALoginContainer';
import { Toaster } from 'react-hot-toast';
import { ProfileProvider } from './context/ProfileContext';
import PrivacyModal from './container/PrivacyComponent/PrivacyComponent';
import TermsOfUseModal from './container/TermsOfUse/TermsAndCondition';
import HelpCenterContainer from './container/HelpCenter/HelpCenterContainer';
import ContactSupportForm from './container/HelpCenter/SupportContainer';
function App() {
  const isLoggedIn = !!localStorage.getItem('authToken');
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setTermsModalOpen] = useState(false);

  const handleOpenPrivacyModal = () => {
    setPrivacyModalOpen(true);
  };

  const handleClosePrivacyModal = () => {
    setPrivacyModalOpen(false);
  };

  const handleOpenTermsModal = () => {
    setTermsModalOpen(true);
  };

  const handleCloseTermsModal = () => {
    setTermsModalOpen(false);
  };

  return (
    <BrowserRouter>
      <CssBaseline />
      <Toaster position="top-right" containerStyle={{ zIndex: 9999999 }} />
      <Box>
          <ProfileProvider>
            <ActiveStepProvider>
              <WalletProvider>
                  <ErrorBoundary>
                    <Routes>
                      <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login />} />
                      <Route path="/signup" element={isLoggedIn ? <Navigate to="/dashboard" /> : <SignUp />} />
                      <Route path='/2fa-login' element={isLoggedIn ? <Navigate to="/dashboard" /> : <TwoFALogin />} />
                      <Route path="/forget-password" element={isLoggedIn ? <Navigate to="/dashboard" /> : <ForgetPassword />} />
                      <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
                      <Route element={<PrivateRoute />}>
                        <Route element={<BaseLayout />}>
                          <Route path="/dashboard" element={<Dashboard />} />
                          {/* <Route path='/analytics' element={<AnalyticsContainer />} /> */}
                          <Route path='/myprofile' element={<ProfileNavbar />} />
                          <Route path="/transactions" element={<TransactionsHistroy />} />
                          <Route path="/recipients" element={<Recipients />} />
                          <Route path="/accounts" element={<Accounts />} />
                          <Route path="/account-details/:accountId" element={<AccountDetails />} />
                          <Route path="/help-center" element={<HelpCenterContainer />}/>
                          <Route path="/contact-support" element={<ContactSupportForm />} />
                          <Route path="/termsofuse" element={
                            <button onClick={handleOpenTermsModal}>
                              View Terms of Use
                            </button>
                          } />
                          <Route path="/privacy" element={
                            <button onClick={handleOpenPrivacyModal}>
                              View Privacy Notice
                            </button>
                          } />
                        </Route>
                      </Route>
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                    <PrivacyModal open={isPrivacyModalOpen} handleClose={handleClosePrivacyModal} />
                    <TermsOfUseModal open={isTermsModalOpen} handleClose={handleCloseTermsModal} />
                  </ErrorBoundary>
              </WalletProvider>
            </ActiveStepProvider>
          </ProfileProvider>
      </Box>
    </BrowserRouter>
  );
}

export default App;
