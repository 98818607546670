import React, { useMemo, useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Box,
  FormControl,
  MenuItem,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import "./Recipients.css";
import CollapsibleTable from "../../components/TableComponent/RecipientsTable";
import SearchIcon from "@mui/icons-material/Search";
import { PastDays } from "../../components/BasicDateRangePicker/BasicDateRangePicker";

const paymanetDataOptions = [
  {
    label: "To Bank Account",
    value: "BANK_ACCOUNT",
    disable: false,
  },
  {
    label: "To Crypto Wallet",
    value: "CRYPTO_WALLET",
    disable: true,
  }
]

const recipientDataOptions = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Business",
    value: "BUSINESS",
  },
  {
    label: "Individual",
    value: "INDIVIDUAL",
  }
]

const AllRecipientTab = ({
  paymentType,
  setPaymentType,
  recipientType,
  setRecipientType,
  recipientsData,
  handleUpdateRecipientModal,
  handleDeleteRecipientModal,
  isLoading,
  rangeDate,
  setRangeDate,
  currencyListData,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  totalItems,
  onClearFilter,
  onSearchChange,
  searchValue,
  showClearAllButton,
}) => {
console.log(recipientType,"recipientType=======")
  return (
    <>
      <Box>
        <Grid
          container
          direction="row"
          mt="2"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={3}>
            <TextField
              placeholder="Search by keywords..."
              variant="outlined"
              size="small"
              onChange={(e) => onSearchChange(e)}
              value={searchValue || ""}
              sx={{
                width: "80%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "8px",
                      fontFamily: "PPMori-Regular",
                    }}
                    position="start"
                  >
                    <SearchIcon style={{ width: "20px", height: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={9}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* Note commented for future reference */}
              {/* <Grid item>
                <PastDays rangeDate={rangeDate} setRangeDate={setRangeDate} />
              </Grid> */}
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 190 }} size="small">
                  <TextField
                    select
                    labelId="recipient-type-select-label"
                    id="recipient-type-select"
                    value={recipientType[0]}
                    onChange={(e) => setRecipientType([e.target.value])}
                    SelectProps={{
                      renderValue: (selected) => `Recipients type: ${recipientDataOptions.find(fd => fd.value === selected)?.label}`,
                      style: {
                        height: "40px",
                      },
                    }}
                  >
                    {
                      recipientDataOptions.map(item => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))
                    }
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 175 }} size="small">
                  <TextField
                    select
                    labelId="payment-type-select-label"
                    id="payment-type-select"
                    value={paymentType[0]}
                    onChange={(e) => setPaymentType([e.target.value])}
                    SelectProps={{
                      renderValue: (selected) => `Payment type: ${paymanetDataOptions.find(fd => fd.value === selected)?.label}`,
                      style: {
                        height: "40px",
                      },
                    }}
                  >
                    {
                      paymanetDataOptions.map(item => (
                        <MenuItem disabled={item.disable} value={item.value}>{item.label}</MenuItem>
                      ))
                    }
                  </TextField>
                </FormControl>
              </Grid>
              {showClearAllButton()? (
                <Grid item>
                  <Typography
                    variant="outlined"
                    size="small"
                    onClick={onClearFilter}
                    sx={{
                      height: "40px",
                      m: 1,
                      cursor: 'pointer',
                      color: '#462A9C',
                      fontSize:'16px',
                      fontWeight: 'bold'
                    }}
                  >
                    Clear All
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        flexGrow={1}
        overflow={"hidden"}
        border={1}
        borderColor={"rgba(0,0,0,0.1)"}
        borderRadius={1}
      >
        <CollapsibleTable
          recipientsData={
            recipientsData.length 
              ? recipientsData.filter(fd => 
                  fd.firstName?.toLowerCase().includes(searchValue) ||
                  fd.lastName?.toLowerCase().includes(searchValue) ||
                  fd.id.toLowerCase().includes(searchValue)
                ) 
              : recipientsData
          }
          onUpdateRecipientClick={handleUpdateRecipientModal}
          onDeleteRecipientClick={handleDeleteRecipientModal}
          isLoading={isLoading}
          currencyListData={currencyListData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          totalItems={totalItems}
        />
      </Box>
    </>
  );
};

export default AllRecipientTab;
