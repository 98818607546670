import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";

const BaseLayout = () => {
  return (
    <Box display={"flex"} width={"100%"} height={"100%"}>
      <Navbar />
      <Box
        sx={{
          flex: "1 1 0%;",
          width: "100%",
          overflowY:"scroll",
          height:"100svh"
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default BaseLayout;
