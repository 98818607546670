import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText } from '@mui/material';
import { generateKYCLink, submitKYC } from '../../lib/service/DashboardApi/dashboardApi';
import { notify } from '../Notification/Notification';

const VerificationModal = ({ open, onClose, fetchProfileData, userType }) => {
    const [buttonText, setButtonText] = useState('Start KYC');
    const [buttonAction, setButtonAction] = useState('generate');
    const [loading, setLoading] = useState(false);

    const handleGenerateKYC = async () => {
        setLoading(true);
        try {
            const response = await generateKYCLink();
            if (response && response.code) {
                window.open(response.data.link, '_blank');
                setButtonText('Submit KYC');
                setButtonAction('submit');
            } else {
                notify.error({
                    message: "Failed to generate KYC link.",
                    description: "Please try again later.",
                });
            }
        } catch (e) {
            if (e?.errors?.length > 0) {
                e.errors.map((err) =>
                    notify.error({
                        message: err.errMsg,
                        description: err.errDesc,
                    })
                );
            } else {
                notify.error({
                    message: "Failed to verify the KYC.",
                    description: "We can not proceed now, try again later",
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitKYC = async () => {
        setLoading(true);
        try {
            const response = await submitKYC();
            if (response && response.status === "Success") {
                notify.success({
                    message: "KYC submitted successfully.",
                    description: "Your KYC verification is now in process.",
                });
                onClose(); 
                window.location.reload();
                fetchProfileData();
                window.location.reload();
            } else {
                notify.error({
                    message: "Failed to submit KYC.",
                    description: "Please try again later.",
                });
            }
        } catch (e) {
            if (e?.errors?.length > 0) {
                e.errors.map((err) =>
                    notify.error({
                        message: err.errMsg,
                        description: err.errDesc,
                    })
                );
            } else {
                notify.error({
                    message: "Failed to submit the KYC.",
                    description: "We can not proceed now, try again later",
                });
            }
        } finally {
            setLoading(false);
        }
    };

    const handleButtonClick = () => {
        if (buttonAction === 'generate') {
            handleGenerateKYC();
        } else {
            handleSubmitKYC();
        }
    };

    const renderIndividualRequirements = () => (
        <List>
            <ListItem>
                <ListItemText
                    secondary={
                        <ul>
                            <li>Be ready with your personal details</li>
                            <li>Prepare a government-issued ID (e.g., Passport, PAN, Driving License, etc.).</li>
                            <li>If you are a US resident, a valid 9-digit SSN is required; otherwise, leave it blank.</li>
                            <li>Upload documents in JPG format only.</li>
                        </ul>
                    }
                />
            </ListItem>
        </List>
    );

    const renderBusinessRequirements = () => (
        <List>
            <ListItem>
                <ListItemText
                    primary=""
                    secondary={
                        <ul>
                            <li>Be ready with your personal and company documents</li>
                            <li>Prepare a government-issued ID (e.g., Passport, PAN, Driving License, etc.).</li>
                            <li>Company documents such as Incorporation Certificate, Articles of Association, and Registration Number.</li>
                            <li>If you are a US resident, a valid 9-digit SSN is required; otherwise, leave it blank.</li>
                            <li>Upload documents in JPG format only.</li>
                        </ul>
                    }
                />
            </ListItem>
        </List>
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Complete account verification</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    To ensure the security of your account, we partner with Layer2 for identity verification.
                </Typography>
                {userType === 'INDIVIDUAL' ? renderIndividualRequirements() : renderBusinessRequirements()}
                <Typography variant="body1" gutterBottom>
                    How does this work?
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Layer2 Member Terms will apply. You'll be prompted by Layer2 to share ID data with endl for security purposes.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} style={{ color: '#462A9C' }}>
                    Not now
                </Button>
                <Button
                    onClick={handleButtonClick}
                    style={{ color: "#FFF", backgroundColor: "#462A9C" }}
                    variant="contained"
                    disabled={loading}
                >
                    {loading ? '...Loading' : buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VerificationModal;
