import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
const FormSummerySidebar = ({
  title,
  showSummery,
  showStepOneSummery,
  showStepTwoSummery,
  walletAddress,
  sourceCurrency,
  width,
  selectedPaymentType,
  selectedRecipient,
  selectedPaymentMethod,
  networkValue
}) => {
  return (
    <Box
      sx={{ display: "flex" }}
      width={width || "100%"}
      display={"flex"}
      justifyContent={"center"}
    >
      <Box width={"50%"}>
        <Typography
          variant="h5"
          component="h1"
          gutterBottom
          fontSize="22px"
          fontFamily="PPMori-Semi-Bold"
          fontWeight={500}
        >
          {title}
        </Typography>
        {showSummery && (
          <Accordion expanded={true} elevation={0} sx={{ width: "250px" }}>
            <AccordionSummary>
              <Typography
                variant="h6"
                fontSize="12px"
                fontWeight="bold"
                fontFamily="PPMori-Regular"
                marginLeft="-15px"
              >
                Summary
              </Typography>
            </AccordionSummary>
            <Divider sx={{ marginTop: "-20px" }} />
            <AccordionDetails>
              {showStepOneSummery && (
                <Box display={"flex"} gap={1} flexDirection={"column"}>
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography
                      fontSize={12}
                      fontFamily="PPMori-Regular"
                      marginLeft="-15px"
                      marginBottom="5px"
                    >
                      Recipient
                    </Typography>
                    <Typography fontSize={12} fontFamily="PPMori-Regular">
                      {selectedRecipient?.name}
                    </Typography>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      fontSize={12}
                      fontFamily="PPMori-Regular"
                      marginLeft="-15px"
                      marginBottom="5px"
                    >
                      {selectedPaymentType === "cryptoToFiat"
                        ? "Bank account"
                        : "Wallet address"}
                    </Typography>
                    {selectedPaymentType === "cryptoToFiat" && (
                      <Stack>
                        <Typography
                          variant="body1"
                          fontSize={12}
                          color="rgba(0,0,0,0.70)"
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                        >
                          {selectedRecipient.bankName}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize={12}
                          color="rgba(0,0,0,0.70)"
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                        >
                          <MoreHorizIcon fontSize="small" />
                          {selectedRecipient.bankAccountNumber?.slice(-4)}
                        </Typography>
                      </Stack>
                    )} 
                    {/* For others add later when new features arrives */}
                  </Box>
                </Box>
              )}
              {showStepTwoSummery && (
                <>
                  <Divider sx={{ my: 2 }} />
                  {selectedPaymentType === "cryptoToFiat" &&
                    selectedPaymentMethod === "CONNECTED_WALLET" && (
                      <ConnectWalletSummery
                        walletAddress={walletAddress}
                        sourceCurrency={sourceCurrency}
                        networkValue={networkValue}
                      />
                    )}
                  {selectedPaymentType === "cryptoToFiat" &&
                    selectedPaymentMethod === "MANUAL_PAYMENT" && (
                      <ManualPaymentSummery sourceCurrency={sourceCurrency} networkValue={networkValue}/>
                    )}
                </>
              )}
            </AccordionDetails>
            <Divider />
          </Accordion>
        )}
      </Box>
    </Box>
  );
};

const ConnectWalletSummery = ({ walletAddress, sourceCurrency, networkValue }) => {
  return(
    <Box display={"flex"} gap={1} flexDirection={"column"}>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginLeft="-15px"
        marginBottom="5px"
      >
        Payment method
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Connected wallet
      </Typography>
    </Box>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginLeft="-15px"
        marginBottom="5px"
      >
        Wallet Id
      </Typography>
      <Typography fontSize="10px" fontFamily="PPMori-Regular">
        MetaMask({walletAddress.slice(0, 6)}...
        {walletAddress.slice(-4)})
      </Typography>
    </Box>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginLeft="-15px"
        marginBottom="5px"
      >
        Blockchain network
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        {networkValue}
      </Typography>
    </Box>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginLeft="-15px"
        marginBottom="5px"
      >
        Source currency
      </Typography>
      <Typography fontSize="10px" fontFamily="PPMori-Regular">
        {sourceCurrency}
      </Typography>
    </Box>
  </Box>
)};

const ManualPaymentSummery = ({ sourceCurrency, networkValue }) => (
  <Box display={"flex"} gap={1} flexDirection={"column"}>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginLeft="-15px"
        marginBottom="5px"
      >
        Payment method
      </Typography>
      <Typography fontSize={12} fontFamily="PPMori-Regular">
        Manual payment
      </Typography>
    </Box>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginLeft="-15px"
        marginBottom="5px"
      >
        Blockchain network
      </Typography>
      <Typography fontSize="12px" fontFamily="PPMori-Regular">
        {networkValue}
      </Typography>
    </Box>
    <Box display={"flex"} justifyContent={"space-between"}>
      <Typography
        fontSize={12}
        fontFamily="PPMori-Regular"
        marginLeft="-15px"
        marginBottom="5px"
      >
        Source currency
      </Typography>
      <Typography fontSize="10px" fontFamily="PPMori-Regular">
        {sourceCurrency}
      </Typography>
    </Box>
  </Box>
);

export default FormSummerySidebar;
