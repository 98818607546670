import { FormControlLabel, Checkbox as MCheckbox } from "@mui/material";
import React from "react";

const Checkbox = ({ label = "", checked, onChange, name = "", disabled = false , restProps}) => {
  return (
    <FormControlLabel
      control={
        <MCheckbox
          style={{ color: disabled ? "DDDAD7" : "#000" }}
          checked={checked}
          onChange={onChange}
          name={name}
          disabled={disabled}
          {...restProps}
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
