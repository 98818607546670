import React, { useState } from "react";
import { deleteRecipient } from "../../lib/service/RecipientsApi/RecipientsApi";
import { Modal, Box, IconButton, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { notify } from "../Notification/Notification";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  px: 5,
  py: 3,
  display: "flex",
  flexDirection: "column",
  gap: 3,
  borderRadius: 5,
  width: "600px",
};
const RecipientDeleteModal = ({
  open,
  onClose,
  deleteRecipientData,
  onDelete,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteHandler = async () => {
    if(!deleteRecipientData){
      notify.error({
        message: "Failed to delete recipient",
        description:
          "Unable to delete recipient now. Please try again later",
      });
    }
    setIsLoading(true);
    try {
      await deleteRecipient(deleteRecipientData.id);
      onDelete(deleteRecipientData.id);
      notify.success({
        message:'Recipient has been deleted successfully'
      })
      onClose();
    } catch (error) {
      console.error("Error during deleting recipient:", error);
      if (error?.errors?.length > 0) {
        error.errors.map((err) =>
          notify.error({
            message: err.errMsg,
            description: err.errDesc,
          })
        );
      } else {
        notify.error({
          message: "Failed to delete recipient",
          description:
            "Unable to delete recipient now. Please try again later",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style }}>
        <Box position={"relative"} width="100%">
          <Typography variant="h5">Delete recipient</Typography>
          <IconButton
            onClick={onClose}
            disabled={isLoading}
            aria-label="close-open-account-modal"
            sx={{ position: "absolute", right: "0px", top: -8 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Typography variant="body1">
          Are you sure you want to delete this recipient ? This can't be undone
        </Typography>
        <Box display={"flex"} justifyContent={"flex-end"} gap={3}>
          <Button
            variant="text"
            color="primary"
            sx={{
              color: "#FA3228",
              fontSize: "12px",
              textTransform: "none",
              py: 1,
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#FA3228",
              color: "#ffffff",
              fontSize: "12px",
              textTransform: "none",
              py: 1,
            }}
            onClick={deleteHandler}
            disabled={isLoading}
          >
            {!isLoading ? "Delete recipient" : "Deleting..."}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default RecipientDeleteModal;
