import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Tooltip,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as MetaMask } from "../../Images/MetaMask.svg";
import { ReactComponent as QrCode } from "../../Images/Qrcode.svg";
import { KeyboardArrowDown } from "@mui/icons-material";
import ConnectWallet from "../../container/DashboardContainer/ConnectWallet";
import ETH from "../../Images/eth.svg";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};
const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));
const SendPaymentMethodForm = ({
  sourceCurrency,
  walletAddress,
  handleCurrencyChange,
  handleGoBack,
  handleNext,
  sourceCurrencies,
  selectedPaymentMethod,
  onPaymentMethodChange,
  networkList,
  handleNetworkChange,
  networkValue,
  IsQRGenerate
}) => {
  return (
    <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={2}>
      <Typography
        fontSize="16px"
        fontWeight={550}
        marginBottom={2}
        fontFamily="PPMori-Semi-Bold"
        marginLeft="-50px"
      >
        Select Payment Method
      </Typography>
      <Paper
        variant="outlined"
        style={{
          backgroundColor: "#FBFAFC",
          color: "black",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          borderRadius: "8px",
          padding: "28px",
          border: '1.5px solid #96949C'
        }}
      >
        <Typography
          fontSize="14px"
          marginBottom="10px"
          fontFamily="PPMori-Regular"
        >
          Cryptocurrency
        </Typography>

        <RadioGroup
          value={selectedPaymentMethod}
          onChange={(e) => onPaymentMethodChange(e.target.value)}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <FormControlLabel
            value={"CONNECTED_WALLET"}
            control={<Radio style={{ display: "none" }} />}
            disabled={!walletAddress}
            componentsProps={{
              typography: {
                sx: {
                  width: '100%',
                },
              },
            }}
            label={
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                width={"100%"}
                padding={2}
                border={1}
                borderColor={
                  selectedPaymentMethod === "CONNECTED_WALLET"
                    ? "#462A9C"
                    : "rgba(0, 0, 0, 0.12)"
                }
                borderRadius={2}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "PPMori-Regular",
                    gap: 2,
                  }}
                >
                  <Box>
                    <MetaMask />
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      fontSize={12}
                      style={{
                        textTransform: "none",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                      {walletAddress
                        ? "Connected Wallet"
                        : "No wallet connected"}
                    </Typography>
                    {walletAddress && (
                      <Typography
                        fontSize={10}
                        style={{
                          textTransform: "none",
                          fontFamily: "PPMori-Regular",
                        }}
                      >
                        MetaMask{" "}
                        {`(${walletAddress.slice(0, 6)}...${walletAddress.slice(
                          -4
                        )})`}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <ConnectWallet />
              </Box>
            }
            sx={{ margin: 0 }}
          />
          <FormControlLabel
            value={"MANUAL_PAYMENT"}
            control={<Radio style={{ display: "none" }} />}
            componentsProps={{
              typography: {
                sx: {
                  width: '100%', // Set the width to 100% for the span element
                },
              },
            }}
            label={
              <Box
                fullWidth
                display={"flex"}
                gap={1}
                sx={{
                  width: "100%",
                }}
                padding={2}
                border={1}
                borderColor={
                  selectedPaymentMethod === "MANUAL_PAYMENT"
                    ? "#462A9C"
                    : "rgba(0, 0, 0, 0.12) "
                }
                borderRadius={2}
              >
                <QrCode />
                <Typography
                  fontSize={12}
                  style={{
                    marginLeft: "10px",
                    fontFamily: "PPMori-Regular",
                  }}
                  variant="body1"
                >
                  Manual QR Payment
                </Typography>
              </Box>
            }
            sx={{ margin: 0 }}
          />
        </RadioGroup>
      </Paper>
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography style={{ fontSize: "12px" }}>
            Network{" "}
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            style={{
              width: "100%",
              fontSize: "12px",
            }}
          >
            {!networkValue && <InputLabel shrink={false}>Select network</InputLabel>}
            <Select
              id="source-currency"
              value={networkValue}
              onChange={handleNetworkChange}
              sx={{
                width: "100%",
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                }
              }}
              IconComponent={KeyboardArrowDown}
              endAdornment={
                <Tooltip
                  fontFamily="PPMori-Regular"
                  title="This is the currency that will be deducted from your crypto-wallet and converted to transfer to the recipient's receiving currency. "
                  arrow
                  placement="right"
                >
                  <IconButton sx={{marginRight:"10px"}}>
                    <InfoOutlinedIcon
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              }
              style={{ fontSize: "12px" }}
            >
              {networkList?.map((currency) => (
                <MenuItem
                  key={currency.name}
                  value={currency.value}
                >
                  {
                    currency.value === "Ethereum" ? (
                      <img src={ETH} width={20} height={20} alt={"ETH"} style={{ marginRight: "10px" }}/>
                    ) : null
                  }
                  {currency.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography style={{ fontSize: "12px" }}>
          Source Currency{" "}
        </Typography>
        <FormControl
          fullWidth
          variant="outlined"
          style={{
            width: "100%",
            fontSize: "12px",
          }}
        >
          {!sourceCurrency && <InputLabel shrink={false}>Select source currency</InputLabel>}
          <Select
            id="source-currency"
            value={sourceCurrency}
            onChange={handleCurrencyChange}
            sx={{
              width: "100%",
            }}
            IconComponent={KeyboardArrowDown}
            endAdornment={
              <Tooltip
                fontFamily="PPMori-Regular"
                title="This is the currency that will be deducted from your crypto-wallet and converted to transfer to the recipient's receiving currency. "
                arrow
                placement="right"
              >
                <IconButton sx={{marginRight:"10px"}}>
                  <InfoOutlinedIcon
                    style={{
                      width: "15px",
                      height: "15px",
                    }}
                  />
                </IconButton>
              </Tooltip>
            }
            style={{ fontSize: "12px" }}
          >
            {sourceCurrencies?.map((currency) => {
              const countryCode = currency.code ? currency.code.toLowerCase() : 'default';
              const imagePath = images[`${countryCode}.png`];
              console.log(currency.code , 'images[`${sourceCurrency.toLowerCase()}.png`]images[`${sourceCurrency.toLowerCase()}.png`]')
              return(
              <MenuItem
                key={currency.code}
                value={currency.code}
              >
                <img
                    src={imagePath} // Fallback image path if no image is found
                    alt={currency.code}
                    style={{
                      width: "15px",
                      marginRight: "10px",
                      fontFamily: "PPMori-Regular",
                    }}
                        />
                {currency.code}
              </MenuItem>
            )})}
          </Select>
        </FormControl>
      </Box>
      <Box spacing={2} wrap="noWrap" display={"flex"} gap={1} width={"100%"}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            textTransform: "none",
            borderColor: "#462A9C",
            color: "#462A9C",
            fontSize: "12px",
            fontFamily: "PPMori-Regular",
            width: "100%",
            py: 1,
          }}
          fullWidth
          onClick={handleGoBack}
        >
          Go back
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "none",
            backgroundColor: "#462A9C",
            color: "#ffffff",
            fontSize: "12px",
            fontFamily: "PPMori-Regular",
            width: "100%",
            py: 1,
          }}
          onClick={handleNext}
          disabled={!selectedPaymentMethod || !sourceCurrency}
        >
          {" "}
          Next
        </Button>
        {/* {IsQRGenerate !== "" && !IsQRGenerate &&<Typography>Kyc is not verified and as well this account doesn't have wallet address</Typography>} */}
      </Box>
    </Box>
  );
};

export default SendPaymentMethodForm;
