import React, { useState, useMemo } from "react";
import HistoryTable from "../../components/TableComponent/HistoryTable";
import {
  Grid,
  TextField,
  InputAdornment,
  Box,
  FormControl,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { PastDays } from "../../components/BasicDateRangePicker/BasicDateRangePicker";

const RecipientHistoryTab = ({
  recipientHistories,
  statusType,
  setStatusType,
  isLoading,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredHistory = useMemo(() => {
    if (searchTerm && searchTerm !== "") {
      // This logic will not work, we have to filter the data based on the API data..so once we get the API response we will decide the search feature
      // const filtered = recipientHistories.filter(
      //   (history) =>
      //     history.firstName &&
      //    history.firstName.toLowerCase().includes(searchTerm.toLowerCase())
      // );
      // return filtered;
    } else {
      return !isLoading ? [...recipientHistories] : [];
    }
  }, [searchTerm,recipientHistories]);
  const handleSearch = (e) => {
    // After implementing the API we will implement the logic
    return;
    // setSearchTerm(e.target.value);
  };
  return (
    <>
      <Box sx={{ margin: "10px 25px" }}>
        <Grid
          container
          direction="row"
          mt="2"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={3}>
            <TextField
              placeholder="Search by keywords"
              variant="outlined"
              size="small"
              onChange={handleSearch}
              value={searchTerm || ""}
              style={{ fontFamily: "PPMori-Regular" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "8px",
                      fontFamily: "PPMori-Regular",
                    }}
                    position="start"
                  >
                    <SearchIcon style={{ width: "20px", height: "20px" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={9}>
            <Grid
              container
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <PastDays />
              </Grid>
              <Grid item>
                <FormControl sx={{ m: 1, minWidth: 130 }} size="small">
                  <TextField
                    select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={statusType}
                    onChange={(e) => setStatusType(e.target.value)}
                    SelectProps={{
                      style: { height: "40px" },
                    }}
                  >
                    <MenuItem value="ALL">Status: All</MenuItem>
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="PENDING">Pending</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ margin: "0 25px" }}>
        <HistoryTable rows={filteredHistory} loading={isLoading} />
      </Box>
    </>
  );
};

export default RecipientHistoryTab;
