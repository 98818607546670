import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import RenderSkeletonRow from "../LoaderUI/TableLoader";
import EmptyTableComponent from "./EmptyTableComponent";
import "./TableComponent.css";
import { Link, useNavigate } from "react-router-dom";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const TABLE_HEADING_DATA = [
  "Account",
  "Balance",
  // "Currency",
  "Account number",
  // "Recurring payments",
];
const TABLE_DATA = {
  title: "Open account",
  recipient: "No accounts yet",
  description:
    "Open your first endl account",
};

const AllAccountsTable = ({ rows, loading }) => {
  return loading ? (
    <RenderSkeletonRow rowHeadingdata={TABLE_HEADING_DATA} />
  ) : !rows || rows.length === 0 ? (
    <EmptyTableComponent
      tabledata={TABLE_DATA}
      rowHeadingdata={TABLE_HEADING_DATA}
    />
  ) : (
    <FilledTableComponent rows={rows} />
  );
};

const FilledTableComponent = ({ rows }) => {
  const navigate = useNavigate();
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: "100%", overflowX: "auto", borderRadius: 0, boxShadow: 'none' }}
      
    >
      <Table>
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
          }}
        >
          <TableRow>
            {TABLE_HEADING_DATA.map((heading, index) => (
              <TableCell
                sx={{
                  borderRight:
                    index !== TABLE_HEADING_DATA.length - 1 && "1px solid #ccc",
                  backgroundColor: "#F6F5F9",
                  fontWeight: "bold",
                  fontFamily: "PPMori-Regular",
                  fontSize: "14px",
                }}
                key={`${index}-${heading}`}
              >
                {heading}
              </TableCell>
            ))}
            <TableCell
              sx={{
                fontWeight: "bold",
                backgroundColor: "#F6F5F9",
                fontFamily: "PPMori-Regular",
                fontSize: "14px",
              }}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((account) => (
            <TableRow
              sx={{
                cursor: "pointer",
              }}
              onClick={() => navigate(`/account-details/${account.account_number_external}`)} // I think we need ccount id for navigation
            >
              <TableCell className="tablecell">
                {account.name && account.name !== "" ? account.name : "-"}
              </TableCell>
              <TableCell className="tablecell">{account.balance + " " + account.currency}</TableCell>
              {/* <TableCell className="tablecell">{account.currency}</TableCell> */}
              <TableCell className="tablecell">
                {account.account_number_external.toString().length > 9
                  ? `...
                ${account.account_number_external.toString().slice(-4)}`
                  : account.account_number_external}
              </TableCell>
              <TableCell className="tablecell">
                <ArrowForwardIosOutlinedIcon
                  fontSize="small"
                  color="rgba(0,0,0,0.5)"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllAccountsTable;
